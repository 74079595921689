import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ICompetencyReportingState, CompetencyReportingStore } from './competency-reporting.store';

@Injectable({ providedIn: 'root' })
export class CompetencyReportingQuery extends QueryEntity<ICompetencyReportingState> {

  constructor(protected store: CompetencyReportingStore) {
    super(store);
  }

}
