import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '~/shared/models/configuration.model';
import { TokenQuery } from './../../../store/token/token.query';

const helper = new JwtHelperService();

@Injectable()
export abstract class IntouchApiBaseService<T> {
  protected endpoint = '';
  public headers: HttpHeaders;
  public token: string;

  constructor(
    protected httpClient: HttpClient,
    protected config: Configuration,
    protected tokenQuery: TokenQuery
  ) {
    this.tokenQuery.intouchToken$.subscribe((token) => {
      this.token = token;
      this.headers = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.intouchApiKey);
    });
  }

  protected encode(value: string): string {
    return encodeURIComponent(value);
  }

  protected getSingle(url: string): Observable<T> {
    return this.internalGetSingle(url);
  }

  protected getSingleCustom(url: string): Observable<T> {
    return this.internalGetSingleCustom(url);
  }

  protected postSingle(url: string, body: any): Observable<T> {
    return this.internalPostSingle(url, body);
  }

  protected postSingleCustom(url: string, body: any): Observable<T> {
    return this.internalPostSingleCustom(url, body);
  }

  private internalGetSingle(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.getDefaultHeaders() });
  }

  private internalGetSingleCustom(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headers });
  }

  private internalPostSingle(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(url, body, { headers: this.getDefaultHeaders() });
  }

  private internalPostSingleCustom(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(url, body, { headers: this.headers });
  }

  private getDefaultHeaders(): any {
    return {
      'x-apikey': this.config.intouchApiKey
    }
  }
}
