export interface ISelectProficiencyLevel {
  value: string, weight: number, keyEnum: number
}

export const proficiencyLevels: Array<ISelectProficiencyLevel> = [
  { value: 'Basic', weight: 1, keyEnum: 81 },
  { value: 'Intermediate', weight: 2, keyEnum: 82 },
  { value: 'Advanced', weight: 3, keyEnum: 83 },
  { value: 'Expert', weight: 4, keyEnum: 84 },
  { value: 'None', weight: 0, keyEnum: 80 },
]
