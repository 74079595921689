<section>

  <header>
    <h2 mat-dialog-title>{{ competencyElementName }}</h2>
    <figure (click)="close()">
      <img src="assets/icons/close-dialog.svg" alt="close">
    </figure>
  </header>

  <mat-divider></mat-divider>

  <mat-dialog-content>

    <div class="panel-body" >
      <pcp-element-training [competencyDetail]="competencyDetail" *ngIf="!isLoading"
      [pathwayCompleted]="pathwayCompleted" [pathwayType]="pathwayType">
      </pcp-element-training>
    </div>

  </mat-dialog-content>
</section>
