<div class="container-home" *ngIf="hasElements">

  <section class="home">

    <div class="container-left">

      <div class="container-user">
        <pcp-user-profile></pcp-user-profile>

        <pcp-compliance [isHome]="true" [waitingComplianceTargetsResponse]="waitingComplianceTargetsResponse">
        </pcp-compliance>
      </div>

      <pcp-chart>
      </pcp-chart>

      <div class="container-promotion">
        <pcp-promotion [isHome]="true"></pcp-promotion>
      </div>


    </div>

    <pcp-my-elements></pcp-my-elements>

    <pcp-competency-detail></pcp-competency-detail>

  </section>

  <div class="app-version">
    <small>App Version {{version}}</small>
  </div>

</div>
