import { AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'pcp-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements AfterViewInit {
  @Input() public items: { id: number; name: string; }[];
  @Input() public enableSearch = false;
  @Input() public searchPlaceholder: string;
  @Output() public searchChanged = new EventEmitter<string>();
  @ViewChild('inputSearch', { static: true }) public inputSearch: ElementRef;
  @ContentChild(TemplateRef, { read: TemplateRef }) public templateRef;

  public ngAfterViewInit(): void {
    if (this.enableSearch) {
      fromEvent(this.inputSearch.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(300),
          distinctUntilChanged(),
          tap(() =>
            this.searchChanged.emit(this.inputSearch.nativeElement.value)
          )
        ).subscribe();
    }
  }
}
