import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcp-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {

  @Input()
  public showArrow: false;
}
