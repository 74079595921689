export class ProficiencyLevel {
  public static NoneID = 80;
  public static BasicID = 81;
  public static IntermediateID = 82;
  public static AdvancedID = 83;
  public static ExpertID = 84;

  public id: number;
  public name: string;
  public iconPath: string;

  constructor(data?: Partial<ProficiencyLevel>) {
    Object.assign(this, data);
  }

  public setByName(pcpProficiencyLevel: string) {
    this.name = pcpProficiencyLevel;
    if (pcpProficiencyLevel === 'Basic') {
      this.id = ProficiencyLevel.BasicID;
    } else if (pcpProficiencyLevel === 'Intermediate') {
      this.id = ProficiencyLevel.IntermediateID;
    } else if (pcpProficiencyLevel === 'Advanced') {
      this.id = ProficiencyLevel.AdvancedID;
    } else if (pcpProficiencyLevel === 'Expert') {
      this.id = ProficiencyLevel.ExpertID;
    }
    else if (pcpProficiencyLevel === 'None') {
      this.id = ProficiencyLevel.NoneID;
    }
    else {
      this.id = 0;
    }
  }

  public setNameById(pcpProficiencyLevel: number) {
    switch(pcpProficiencyLevel){
      case ProficiencyLevel.BasicID:
        this.name = 'Basic';
        break;
      case ProficiencyLevel.IntermediateID:
        this.name = 'Intermediate';
        break;
      case ProficiencyLevel.AdvancedID:
        this.name = 'Advanced';
        break;
      case ProficiencyLevel.ExpertID:
        this.name = 'Expert';
        break;
      default:
        this.name = '';
        break;
    }
  }

  public getNextLevel() {
    if (this.id === 0) {
      return 'Basic';
    } else if (this.id === ProficiencyLevel.BasicID) {
      return 'Intermediate';
    } else if (this.id === ProficiencyLevel.IntermediateID) {
      return 'Advanced';
    } else if (this.id === ProficiencyLevel.AdvancedID) {
      return 'Expert'
    } else if (this.id === ProficiencyLevel.ExpertID) {
      return 'Expert';
    }

    else if (this.id === ProficiencyLevel.NoneID) {
      return '';
    }

    else {
      return 'Basic';
    }
  }

  get isNullOrNone(): boolean {
    return (this.name === null || this.name === undefined || this.name === 'null' || this.name === 'None');
  }
}
