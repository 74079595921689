import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TokenQuery } from 'src/app/store/token/token.query';
import { AuthService } from '~/core/services/auth.service';
import { Configuration } from '../models/configuration.model';
import { PcpHistory } from '../models/pcp-history.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';

@Injectable({ providedIn: 'root' })
export class PcpHistoryService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private authService: AuthService
  ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/pcp-history';
  }

  public getPcpHistory(competencyId?: any): Observable<PcpHistory[]> {
    const ginNumber = this.authService.getGinNumber();

    const subject = new Subject<PcpHistory[]>();

    this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?ginNumber=${ginNumber}&competencyElementId=${competencyId}`)
      .pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        }),
        tap((result: any) => {
          if (result) {
            subject.next(result.items);
          } else {
            subject.next(null);
            subject.complete();
          }
        })).subscribe();

    return subject;
  }
}
