<div class="content" *ngIf="isIos()" [@showHide]="visible ? 'show': 'hide'">
  <div class="message">
    <div class="icon">
      <img src="/assets/icons/logo2.svg" alt="Logo icon" />
    </div>

    <div class="text">
      <h3>Install this app on your iPhone</h3>
      <small>{{domain}}</small>
    </div>

    <div class="close">
      <img src="/assets/icons/close.svg" (click)="close()" alt="Close icon" />
    </div>
  </div>

  <div class="how-to-install">
    <span>Tap <img src="/assets/icons/ios-share.svg" alt="iOS share icon" /> and then tap “Add to Home Screen”</span>
  </div>
</div>
