import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment-mini-ts';
import { Message } from '../../../../shared/constants/message';
import { CompetencyElement, PromotionCompetencyElement } from '../../../../shared/models/competency-element.model';
import { ElementExpiration } from '../../../../shared/models/element-expiration.model';
import { ProficiencyLevel } from '../../../../shared/models/proficiency-level.model';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { isMobileSize, MIN_WIDTH_DIALOG_LG, MIN_WIDTH_DIALOG_MOBILE, SIZE_MIN_LG_SCREEN } from '../../../../shared/utils/navigator';
import { PcpRequirements } from '../../../../shared/models/pcp-requirements.model';
import { Competency } from '../../../../shared/models/competency-repoting.model';
import { ElementDetailBase } from '~/shared/models/element-detail-base';

@Component({
  selector: 'pcp-expiration-details',
  templateUrl: './expiration-details.component.html',
  styleUrls: ['./expiration-details.component.scss']
})
export class ExpirationDetailsComponent implements OnChanges {
  private awardedOnDate: Date;
  private currentProficiencyLevel: string;
  private competencyUnit: string;
  public daysRemaining: number | Date;

  public panelOpenState = isMobileSize() ? false : true;
  @Input() public competencyElement: PromotionCompetencyElement;
  @Input() public pcpRequirements: PcpRequirements[];
  public message: string;
  public expirations: Array<ElementExpiration> = [];

  constructor(
    public dialog: MatDialog,
  ) { }

  private getDaysRemaining(expiration: any): void {
    if (expiration) {
      expiration.filter((obj) => {
        return obj.expiresOn != null || obj.expiresOn != undefined
      }).map((obj) => { return obj });

      expiration.forEach((element) => {
        if (element.expiresOn) {
          const _date = new Date(element.expiresOn)
          const time = (_date.getTime() - new Date().getTime());
          const days = Math.round(time / (1000 * 60 * 60 * 24));
          this.pcpRequirements.forEach((requirement) => {
            if (requirement.requirementId === element.requirementId)
              requirement.expiresOn = days.toString();
          });
        }
      });
    }
  }

  private verifyExpirations(competencyElement: PromotionCompetencyElement) {
    if (!competencyElement) {
      return;
    }
    this.message = null;

    //if (competencyElement.proficiencyLevel === ProficiencyLevel.BasicID) {
    if (competencyElement.proficiencyLevel.toLowerCase() === 'basic') {
      this.message = Message.USER_WITHOUT_EXPERIENCE;
    }
      //  else if (competencyElement.expirations.length === 0) {
    //   this.message = Message.USER_WITHOUT_COMPETENCY_DATA_FOR_AN_ELEMENT;
    // }
    else {
      //this.expirations = competencyElement.expirations;
    }
  }

  public ngOnChanges(): void {
    this.panelOpenState = isMobileSize() ? false : true;
    this.getDaysRemaining(this.pcpRequirements);
  }

  public openDialog(data: any): void {

    const dataDialog = {
      competencyElementName:data.pathwayRequirement,
      competencyElementId:data.ceId,
      typeData:'expiration',
      pathway:data.pathway,
      pathwayType:data.pathwayType,
      pl:data.attemptedProficiency,
      fromDate: moment(this.awardedOnDate).format('YYYY-MM-DD'),
      origin: 'level-information',
      ...data
    }
    const sizeDialog: MatDialogConfig = {
      minWidth: MIN_WIDTH_DIALOG_MOBILE,
      data: dataDialog
    };

    if (window.innerWidth <= SIZE_MIN_LG_SCREEN)
      sizeDialog.minWidth = MIN_WIDTH_DIALOG_LG;

    this.dialog.open(DialogDetailComponent, sizeDialog);
  }

  public detail(elementDetail: ElementDetailBase): void {

    this.openDialog(elementDetail);

  }

}
