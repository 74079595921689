import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { CompetencyUnit } from '~/shared/models/competency-unit.model';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { CompetencyUnitQuery } from '~/store/competency-unit/competency-unit.query';

@Component({
  templateUrl: './competency-units.component.html',
  styleUrls: ['./competency-units.component.scss']
})
export class CompetencyUnitsComponent extends PageComponentBase implements OnInit {

  private proficiencyLevelId = 0;
  private terms: Array<string> = [];
  public competencyUnits: Array<CompetencyUnit>;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private competencyUnitQuery: CompetencyUnitQuery,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    if (this.route.snapshot.params['proficiency-level']) {
      this.proficiencyLevelId = parseInt(this.route.snapshot.params['proficiency-level'], null);
    }

    this.loadData();
  }

  public onClick(competencyUnit: CompetencyUnit): void {
    if (this.route.snapshot.params['proficiency-level']) {
      const proficiencyLevel = this.route.snapshot.params['proficiency-level'];

      this.router.navigate(
        ['competency-units', proficiencyLevel, competencyUnit.name, 'elements'], { state: { title: competencyUnit.name } });
    } else {
      this.router.navigate(['competency-units', competencyUnit.name, 'elements'], { state: { title: competencyUnit.name } });
    }
  }

  public loadData(): void {
    if (this.proficiencyLevelId === 0) {
      this.competencyUnitQuery.findByName(this.terms)
        .subscribe((competencyUnits) => this.competencyUnits = competencyUnits);
    } else {
      this.competencyUnitQuery.findByProficiencyLevelIdAndName(this.proficiencyLevelId, this.terms)
        .subscribe((competencyUnits) => this.competencyUnits = competencyUnits);
    }
  }

  public refreshRequested() {
    this.finishLoading();
  }

  public onSearchChanged(term: string): void {
    term = term.trim();
    this.terms = term.length > 0 ? term.split(' ') : [];
    this.loadData();
  }
}
