export class User {
  public ginNumber: number;
  public name: string;
  public jobCode: string;
  public productLine: string;
  public geoMarket: string;
  public country: string;
  public avatar: string;
  public hasPromotion: boolean;

  constructor(data?: Partial<User>) {
    Object.assign(this, data);
  }
}
