<div *ngIf="targets" class="your-targets-wrapper" [class.page]="!isHome"
  [ngStyle]="{'flex-direction': isHome ? 'column' : 'row' }">

  <ng-container>
    <div *ngIf="!isHome" class="container-user-targets">
      <your-targets-header [isHome]="isHome"></your-targets-header>

      <div class="container-chart">
        <compliance-targets-average-progress-bar></compliance-targets-average-progress-bar>

        <div class="targets-progress" *ngIf="targets.disciplines.length != 0">
          <div *ngFor="let element of targets.disciplines">
              <div class="progress-bar-container targets-average-progress-bar" (click)="selectElement(element)">
                <div class="progress-bar-label-container">
                    <span class="">{{element.title}}</span>
                    <span class="progress-bar-label-percentage ">{{element.completedPercent}}</span>
                </div>
                <mat-progress-bar class="progress-bar" mode="determinate" [ngClass]="getClassProgressByValue(element)" [value]="completedPercent(element.completedPercent)">
                </mat-progress-bar>
              </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!isHome" class="container">
    <div class="container-wrapper">
        <app-targets-element-list [isHome]="isHome" [elements]="targets.disciplines" [elementSelected]="elementSelected">
        </app-targets-element-list>

        <pcp-no-data-found *ngIf="targets.disciplines.length == 0"></pcp-no-data-found>
    </div>
  </div>

  <pcp-competency-detail *ngIf="!isHome"></pcp-competency-detail>

</div>

<pcp-loading-dots [class.d-none]="!isLoading"></pcp-loading-dots>