import { ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from '~/core/services/loader.service';

@Component({
  selector: 'pcp-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  public visible = false;

  constructor(
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.loaderService.$loaderStatus
      .subscribe((status) => {
        this.visible = status.visible;
        this.changeDetector.detectChanges();
      });
  }
}
