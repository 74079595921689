import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Promotion } from '~/shared/models/promotion.model';
import { User } from '~/shared/models/user.model';
import { PromotionQuery } from '~/store/promotion/promotion.query';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'pcp-promotion-user-header',
  templateUrl: './promotion-user-header.component.html',
  styleUrls: ['./promotion-user-header.component.scss']
})
export class PromotionUserHeaderComponent implements OnInit {

  @Input() public isHome = false;
  public user$: Observable<User>;

  public promotion$: Observable<Promotion>;
  private promotion: Promotion;

  constructor(
    private userQuery: UserQuery,
    private promotionQuery: PromotionQuery,
    private router: Router
  ) { }

  public ngOnInit() {
    this.user$ = this.userQuery.user$;
    this.promotion$ = this.promotionQuery.promotion$;

    this.promotion$.subscribe(promo => {
      if (promo) {
        this.promotion = promo;
      }
    })
  }

  public navigateToHome() {
    this.router.navigate(['home']);
  }

  getCurrentPosition(): string {
    let stepName = '';
    if (this.promotion && this.promotion.stepName) {
      stepName = this.promotion.stepName;
      if (stepName.includes('-')) {
        return stepName.split('-')[0];
      }
    }

    return stepName;
  }

  getNextPosition(): string {
    let stepName = '';
    if (this.promotion && this.promotion.stepName) {
      stepName = this.promotion.stepName;
      if (stepName.includes('-')) {
        return stepName.split('-')[1];
      }
    }

    return stepName;
  }
}
