import { Component, OnInit } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionQuery } from '~/store/promotion/promotion.query';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';
import { CompetencyElement, PromotionCompetencyElement } from '~/shared/models/competency-element.model';

@Component({
  templateUrl: './promotion-requirements-ces.component.html',
  styleUrls: ['./promotion-requirements-ces.component.scss']
})
export class PromotionRequirementsCEsComponent extends PageComponentBase implements OnInit {

  public promotionRequirement: PromotionRequirement;

  public selectedTabIndex = 1;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private route: ActivatedRoute,
    private router: Router,
    private promotionQuery: PromotionQuery
  ) {
    super(pullToRefreshService);
  }

  public viewCE(ce: CompetencyElement): void {
    this.router.navigate(['competency-units/DDA/elements', ce.name.trim(), 'detail'],
      { state: { title: ce.name.trim() } });
  }

  public ngOnInit(): void {
    this.route.queryParamMap.subscribe((param) => {
      const groupName = param.get('group');
      const requirementName = param.get('requirement');
      this.promotionQuery.findRequirementByGroupAndName(groupName, requirementName).subscribe((requirement) => {
        this.promotionRequirement = requirement;
        if (this.availableElements.length === 0) {
          this.selectedTabIndex = 2;
        }
        this.finishLoading();
      });
    });
  }

  public refreshRequested() {
    this.finishLoading();
  }

  public onTabClicked(tabIndex: number): void {
    if (this.availableElements.length > 0) {
      this.selectedTabIndex = tabIndex;
    } else {
      this.selectedTabIndex = 2;
    }
  }

  get completeElements(): PromotionCompetencyElement[] {
    if (this.promotionRequirement) {
      return this.promotionRequirement.competencyElements
        .filter((ce) => ce.proficiencyLevel >= this.promotionRequirement.requiredLevel);
    }
    return [];
  }

  get availableElements(): PromotionCompetencyElement[] {
    if (this.promotionRequirement) {
      return this.promotionRequirement.competencyElements
        .filter((ce) => ce.proficiencyLevel < this.promotionRequirement.requiredLevel);
    }
    return [];
  }
}
