import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';

@Component({
  selector: 'pcp-pull-to-refresh',
  templateUrl: './pull-to-refresh.component.html',
  styleUrls: ['./pull-to-refresh.component.scss'],
  animations: [
    trigger('showHide', [
      state('hide', style({ opacity: 0, height: '0px' })),
      state('show', style({ opacity: 1, height: '*' })),
      transition('hide => show', animate('200ms')),
      transition('show => hide', animate('0s'))
    ])
  ]
})
export class PullToRefreshComponent implements OnInit {

  private maxHeightPercentViewPort = .20;
  private minimumHeightPercentToShowLoaderViewPort = .05;
  private height = 0;
  private initialCoordinates: { x: number, y: number } = null;
  private lastCoordinates: { x: number, y: number } = null;
  private preparePullToRefresh = false;
  private firstMove = false;

  @ViewChild('pullToRefresh') public container: ElementRef;
  @Input() public disabled = false;
  public showLoader = false;
  public refreshWasRequested = false;

  public constructor(
    private pullToRefreshService: PullToRefreshService
  ) {
    pullToRefreshService.$finishLoading.subscribe(() => this.reset());
  }

  private getTouchesCoordinatesFrom(event: any): { x: number, y: number } {
    return {
      x: event.targetTouches[0].screenX,
      y: event.targetTouches[0].screenY
    }
  }

  private render(height: number): void {
    const maxAllowedHeight = document.documentElement.clientHeight * this.maxHeightPercentViewPort;
    let heightToRender = 0;

    if (height >= maxAllowedHeight) {
      heightToRender = maxAllowedHeight;
      this.refreshWasRequested = true;
    } else {
      heightToRender = height;
    }

    const minimumHeightToShowLoader = document.documentElement.clientHeight * this.minimumHeightPercentToShowLoaderViewPort;

    if (height >= minimumHeightToShowLoader) {
      this.showLoader = true;
    }

    if (this.container && this.container.nativeElement) {
      this.container.nativeElement.style.height = `${heightToRender}px`;
    }
  }

  private bindEvents(): void {
    fromEvent(document, 'touchstart')
      .subscribe((event: any) => {
        if (this.disabled) {
          return;
        }

        if (event.preparePullToRefresh) {
          this.initialCoordinates = this.getTouchesCoordinatesFrom(event);
          this.preparePullToRefresh = true;
          this.firstMove = true;
        }
      });

    fromEvent(document, 'touchmove')
      .pipe(
        map((event) => this.getTouchesCoordinatesFrom(event)),
        distinctUntilChanged((prev, curr) => prev.y === curr.y))
      .subscribe((currentCoordinates) => {
        if (this.disabled || !this.preparePullToRefresh) {
          return;
        }

        if (this.firstMove) {
          const cancelPullToRefresh = (currentCoordinates.y - this.initialCoordinates.y) < 0;

          if (cancelPullToRefresh) {
            this.reset();
            return;
          }

          this.firstMove = false;
        }

        if (this.lastCoordinates) {
          const deltaY = currentCoordinates.y - this.lastCoordinates.y;
          this.height += deltaY;

          this.render(this.height);

        } else {
          const deltaY = currentCoordinates.y - this.initialCoordinates.y;
          this.height = deltaY;

          this.render(this.height);
        }

        this.lastCoordinates = currentCoordinates
      });

    fromEvent(document, 'touchend')
      .subscribe(() => {
        if (this.disabled) {
          return;
        }

        if (this.refreshWasRequested) {
          this.pullToRefreshService.refreshRequested();
        } else {
          this.reset();
        }
      });
  }

  private reset() {
    this.showLoader = false;
    this.height = 0;
    this.lastCoordinates = null;
    this.initialCoordinates = null;
    this.firstMove = false;
    this.preparePullToRefresh = false;
    this.refreshWasRequested = false;
    this.render(this.height);
  }

  public ngOnInit(): void {
    this.bindEvents();
  }
}
