import { TokenQuery } from './../../store/token/token.query';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '~/shared/models/configuration.model';
import { Observable } from 'rxjs/internal/Observable';
import { IntouchBusinessLineDto } from './dtos/intouch-businessline.dto';

import { IntouchApiBaseService } from './base/intouch-api-base.service';

@Injectable({ providedIn: 'root' })
export class IntouchBusinesslineService extends IntouchApiBaseService<IntouchBusinessLineDto> {

    constructor(
        httpClient: HttpClient,
        config: Configuration,
        tokenQuery: TokenQuery,
    ) {
        super(httpClient, config, tokenQuery);
    }

    public getIntouchBusinessLines(ldap: string): Observable<IntouchBusinessLineDto> {
        return this.getSingleCustom(`${this.config.intouchApiUrl}/segments?sUserLdapAlias=${ldap}`);
    }

}
