import { PromotionGroupDto } from '../services/dtos/promotion-group-dto';
import { PromotionRequirement } from './promotion-requirement.model';

// export class PromotionGroup {
//     public promotionGroup: string;
//     public promoGroupPassed: boolean;
//     public requirements: Array<PromotionRequirement>;

//     constructor(data: PromotionGroupDto) {
//         this.promotionGroup = data.promotionGroup;
//         this.promoGroupPassed = data.promoGroupPassed;
//         this.requirements = data.requirements.map((rqDto) => new PromotionRequirement(rqDto));
//     }

//     get status(): string {
//         return this.promoGroupPassed ? 'Complete' : 'In progress';
//     }
// }

export class PromotionGroup {
  promotionGroupId: number;
  isGroupCompleted: boolean;
  name: string;
  groupingType: string;
  groupResult: string;
  requirements: Array<PromotionRequirement>;

  constructor(data?: Partial<PromotionGroup>) {
    Object.assign(this, data);
  }
}
