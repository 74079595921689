import { Component } from '@angular/core';

@Component({
  selector: 'pcp-loading-dots',
  template: `
    <div class="snippet" data-title=".dot-elastic">
      <div class="stage">
        <div class="dot-elastic"></div>
      </div>
    </div>
  `,
  styleUrls: ['./loading-dots.component.scss']
})
export class LoadingDotsComponent {

  constructor() {
    //
  }

}
