import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import * as sortBy from 'lodash/sortBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompetencyElement } from '~/shared/models/competency-element.model';
import { CompetencyElementStore, ICompetencyElementState } from './competency-element.store';

@Injectable({ providedIn: 'root' })
export class CompetencyElementQuery extends QueryEntity<ICompetencyElementState> {

  public competencyElements$ = this.select((state) => state.competencyElements);

  constructor(protected store: CompetencyElementStore) {
    super(store);
  }

  private filterByName(competencyElement: CompetencyElement, nameParts: Array<string>): boolean {
    if (nameParts.length === 0) {
      return true;
    }

    const elementName = competencyElement.name.toLowerCase();
    return nameParts.filter((part) => elementName.toLowerCase().indexOf(part.toLowerCase()) >= 0).length > 0;
  }

  public findByCompetencyUnitAndName(competencyUnitName: string, nameParts: Array<string>): Observable<Array<CompetencyElement>> {
    return this.selectAll({
      filterBy: (entity) =>
        entity.competencyUnitName === competencyUnitName
        && this.filterByName(entity, nameParts)
    }).pipe(
      map((elements) => elements.map((e) => new CompetencyElement(e))),
      map((elements) => sortBy(elements, ['name'])
    ));
  }

  public findByProficiencyLevelIdAndCompetencyUnitAndName(proficiencyLevelId: number, competencyUnitName: string, nameParts: Array<string>)
    : Observable<Array<CompetencyElement>> {

    return this.selectAll({
      filterBy: (entity) =>
        entity.proficiencyLevel.id === proficiencyLevelId
        && entity.competencyUnitName === competencyUnitName
        && this.filterByName(entity, nameParts)
    }).pipe(
      map((elements) => elements.map((e) => new CompetencyElement(e))),
      map((elements) => sortBy(elements, ['name'])
    ));
  }

  public getByName(competencyElementName: string): Observable<CompetencyElement> {
    return this.selectEntity(competencyElementName);
  }
}
