import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcp-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {

  @Input() public title = 'PCP';
}
