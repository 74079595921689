import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { PromotionGroup } from '~/shared/models/promotion-group.model';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';
import { getDefaultClassProgress } from '~/shared/utils/class-progress';

@Component({
  selector: 'pcp-promotion-group',
  templateUrl: './promotion-group.component.html',
  styleUrls: ['./promotion-group.component.scss']
})
export class PromotionGroupComponent implements OnInit {

  @Input()
  public isHome = false;

  @Input()
  public isPromotion = false;

  @Input()
  public group: PromotionGroup;

  @Output()
  public groupClick = new EventEmitter<PromotionGroup>();
  public percentageValue = 0;

  public isRequirementTypePoints(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.points.valueOf();
  }
  public isRequirementTypeComplete(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.complete.valueOf();
  }
  public isRequirementTypeSpecific(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.specific.valueOf();
  }

  ngOnInit() {
    if (!this.group) {
      return;
    }

    if (this.isCompleted()) {
      const oneHundredPercent = 100;
      this.percentageValue = oneHundredPercent;
      return;
    }

    const percentages = new Array<number>();
    if (this.group.requirements?.length > 0) {
      this.group.requirements.forEach(req => {
        if (this.isRequirementTypeComplete(req)) {
          percentages.push((req.achievedCeCount * 100)/req.requiredCount);
        } else if (this.isRequirementTypePoints(req)){
          percentages.push(this.pointAchievedPercentage(req));
        } else if (this.isRequirementTypeSpecific(req)) {
          percentages.push((req.achievedCeCount * 100)/req.cecount);
        }
      });

      if (this.group.groupingType.toLowerCase() === 'or') {
        this.percentageValue = Math.round(Math.max(...percentages));
      } else {
        percentages.forEach(x => this.percentageValue += x);
        this.percentageValue = Math.round(this.percentageValue/percentages.length);
      }
    }
  }

  public viewGroup(): void {
    this.groupClick.emit(this.group);
  }

  public getDefaultClassProgressComponent() {
    return getDefaultClassProgress(this.percentageValue);
  }

  isCompleted() {
    return this.group?.groupResult.toLowerCase() !== 'fail';
  }

  labelGroupProgress() {
    return  this.isCompleted() ? 'Completed' : 'In Progress';
  }

  pointAchievedPercentage(requirement: PromotionRequirement): number {
    const pointsAchieved = requirement?.pointsAchieved ?? 0;

    const percentage = (pointsAchieved * 100) / requirement.requiredPoints;
    return percentage;
  }
}
