import { Component, Input, OnInit } from '@angular/core';
import { proficiencyLevels } from '~/shared/constants/select-levels';
import { PcpHistory } from '~/shared/models/pcp-history.model';

const growth: string = '/assets/icons/chip-grow.svg'
const decay: string = '/assets/icons/chip-decay.svg'

@Component({
  selector: 'pcp-history-timeline',
  templateUrl: './history-timeline.component.html',
  styleUrls: ['./history-timeline.component.scss']
})
export class HistoryTimelineComponent implements OnInit {
  @Input() history: PcpHistory[];

  public proficiencyLevels = proficiencyLevels;

  constructor() { }

  ngOnInit(): void {
  }

  selectLevel(id: number): string {
    return this.proficiencyLevels.filter(p => p.keyEnum == id)[0]?.value
  }

  selectGrowth(value: string): string {
    return value.toLowerCase() === 'growth' ? growth
      : value.toLowerCase() === 'decay' ? decay : "";
  }
}
