import { QueryEntity } from '@datorama/akita';
import { IPromotionState, PromotionStore } from './promotion.store';
import { Injectable } from '@angular/core';
import { PromotionGroup } from '~/shared/models/promotion-group.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';

@Injectable({ providedIn: 'root' })
export class PromotionQuery extends QueryEntity<IPromotionState> {
  public promotion$ = this.select((state) => state.promotion);

  constructor(protected store: PromotionStore) {
    super(store);
  }

  public findGroupByName(groupName: string): Observable<PromotionGroup> {
    return this.promotion$.pipe(map((promo) => {
      return promo.promotionGroups.find((pg) => pg.name.toLowerCase().trim() === groupName.toLowerCase().trim());
    }));
  }

  public findRequirementByGroupAndName(groupName: string, requirementName: string): Observable<PromotionRequirement> {
    return this.promotion$.pipe(map((promo) => {
      const group = promo.promotionGroups.find((pg) => pg.name.toLowerCase().trim() === groupName.toLowerCase().trim());
      if (group && group.requirements) {
        return group.requirements.find((rq) => rq.requirement.toLowerCase().trim() === requirementName.toLowerCase().trim());
      }
      return null;
    }));
  }
}
