import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '~/core/services/auth.service';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { LocalStorageKeys } from '~/shared/constants/local-storage-keys';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PageComponentBase {

  constructor(
    pullToRefreshService: PullToRefreshService,
    private authService: AuthService,
    private router: Router
  ) {
    super(pullToRefreshService);
  }

  public login(): void {
    if (!navigator.onLine) {
      this.router.navigateByUrl('/offline');
      return;
    }
    this.authService.login();
  }
}
