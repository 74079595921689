import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsOnlineGuard } from './core/guards/is-online.guard';
import { IsAuthenticatedGuard } from './core/guards/is-authenticated.guard';
import { OfflineComponent } from './modules/offline/pages/offline/offline.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: 'id_token', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: 'access_token', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [IsOnlineGuard],
    data:
    {
      title: 'Login'
    },
    loadChildren: () => import('./modules/auth/auth.module').then((mod) => mod.AuthModule)
  },
  {
    path: 'home',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Home'
    },
    loadChildren: () => import('./modules/home/home.module').then((mod) => mod.HomeModule)
  },
  {
    path: 'feedback',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Feedback'
    },
    loadChildren: () => import('./modules/feedback/feedback.module').then((mod) => mod.FeedbackModule)
  },
  {
    path: 'offline',
    data:
    {
      title: 'Offline',
      showFooterMenu: false,
      disablePullToRefresh: true,
      hideOfflineNotification: true
    },
    component: OfflineComponent
  },
  {
    path: 'competency-units',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Competency Units'
    },
    loadChildren: () => import('./modules/competency-units/competency-units.module').then((mod) => mod.CompetencyUnitsModule)
  },
  {
    path: 'competency-units/:proficiency-level',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    },
    loadChildren: () => import('./modules/competency-units/competency-units.module').then((mod) => mod.CompetencyUnitsModule)
  },
  {
    path: 'promotion',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Fixed Step Promotion'
    },
    loadChildren: () => import('./modules/promotion/promotion.module').then((mod) => mod.PromotionModule)
  },
  {
    path: 'compliance',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Compliances'
    },
    loadChildren: () => import('./modules/compliance/compliance.module').then((mod) => mod.ComplianceModule)
  },
  {
    path: 'targets',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Targets'
    },
    loadChildren: () => import('./modules/target/target.module').then((mod) => mod.TargetModule)
  },
  {
    path: 'your-targets',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      title: 'Your Targets'
    },
    loadChildren: () => import('./modules/your-targets/your-targets.module').then((mod) => mod.YourTargetsModule)
  },
  {
    path: 'no-data-found',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      showFooterMenu: false,
      showHeader: false,
      disablePullToRefresh: true
    },
    loadChildren: () => import('./modules/no-data-found/no-data-found.module').then((mod) => mod.NoDataFoundModule)
  },
  {
    canActivate: [IsOnlineGuard],
    data:
    {
      title: 'Error Occurred',
      showFooterMenu: false,
      disablePullToRefresh: true
    },
    path: 'error', loadChildren: () => import('./modules/error/error.module').then((mod) => mod.ErrorModule)
  },
  {
    path: 'under-maintenance',
    data:
    {
      title: 'Under Maintenence',
      showFooterMenu: false,
      disablePullToRefresh: true
    },
    loadChildren: () => import('./modules/maintenance/maintenance.module').then((mod) => mod.MaintenanceModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
