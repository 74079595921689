<div class="container-element-detail">
  <header>
    <h4 *ngIf="!competencyElement">Element Details</h4>
    <h4 *ngIf="competencyElement">
      {{competencyElement.competencyElement || competencyElement.name}}
    </h4>
    <mat-icon *ngIf="competencyElement" title="Pcp History Timeline"
      (click)="dialogPcpHistory()" aria-hidden="false" aria-label="Timeline history">
      view_timeline
    </mat-icon>
  </header>

  <span *ngIf="!competencyElement" class="empty">Select a element to view the details</span>

  <pcp-my-elements-detail *ngIf="competencyElement"
    [competencyElement]="!isPromotionCompetencyElement ? competencyElement : undefined"
    [promotionCompetencyElement]="isPromotionCompetencyElement ? competencyElement : undefined"
    [inputPlTarget]="isPromotionCompetencyElement ? dataTypePCE.plTarget : undefined"
    [isRequirementTypeSpecific]="isPromotionCompetencyElement ? isRequirementTypeSpecific(dataTypePCE.requirementType) : undefined">
  </pcp-my-elements-detail>

</div>
