const percentageStarting = 30;
const percentageComplete = 100;

export function getClassProgress(value: number): string {
  if (value < percentageStarting)
    return 'starting-progress'
  else if (value >= percentageStarting && value < percentageComplete)
    return 'in-progress'
  else
    return 'complete-progress'
}

export function getDefaultClassProgress(value: number): string {
  if (value < percentageComplete)
  return 'in-progress'
  else
  return 'complete-progress'
}
