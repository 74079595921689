import { Component, Input, OnInit } from '@angular/core';
import { PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';

@Component({
  selector: 'pcp-card-item-list-points',
  templateUrl: './card-item-list-points.component.html',
  styleUrls: ['./card-item-list-points.component.scss']
})
export class CardItemListPointsComponent implements OnInit {
  @Input() public requirement: PromotionRequirement;
  @Input() public competencyElement: PromotionCompetencyElement;

  constructor() {
    //
  }

  public ngOnInit(): void {

  }

}
