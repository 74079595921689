import { environment } from '@env';
import { Location } from '@angular/common';
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AuthService } from '~/core/services/auth.service';
import { User } from '~/shared/models/user.model';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'pcp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @ContentChild(TemplateRef, { read: TemplateRef })
  public templateRef: any;

  @Input()
  public pageTitle: string;

  @Input()
  public showBackButton: true;

  @Input()
  public showHomeButton: true;

  @Input()
  public allComplete: false;

  public user: User;
  envType: string;

  constructor(
    private location: Location,
    public authService: AuthService,
    private userQuery: UserQuery,
  ) {
    if (!environment.production) {
      this.envType = 'dev';
    }

    userQuery.select('user')
      .subscribe((data) => {
        this.user = data
      })
  }

  public goBack(): void {
    this.location.back();
  }

  get isPromotionPage(): boolean {
    return this.location.path().endsWith('promotion');
  }

  public handleImageError(user): void {
    if (user) {
      user.image = null;
    }
  }

  public logout() {
    this.authService.logout()
  }
}
