<div class="wrapper-target">
  <div class="wrapper-section">
    <section class="content">
      <div class="container-image">
        <!-- <img *ngIf="!user.avatar" src="assets/images/user-avatar-placeholder.png" alt="user image">
        <img *ngIf="user?.avatar" [src]="user.avatar" alt="user image"> -->
        <img src="/assets/images/iron-manager.png" alt="user image">
      </div>
      <h1 class="user-name">Tony Spark</h1>
      <span class="job-code">Iron Manager</span>
    </section>
  </div>

  <!-- <div class="target-div">
    <pcp-target-status class="target-status"></pcp-target-status>
  </div> -->
</div>
