import { Component, Input, OnInit } from '@angular/core';
import { getDefaultClassProgress } from '~/shared/utils/class-progress';

@Component({
  selector: 'pcp-requirement-progress',
  templateUrl: './requirement-progress.component.html',
  styleUrls: ['./requirement-progress.component.scss']
})
export class ActionProgressComponent implements OnInit {

  @Input() public total: number;
  @Input() public current: number;
  public currentPercent: number;

  get isLgSize(): boolean {
    const width = window.innerWidth;
    return width < 1024 || width > 1360;
  }

  get sizeSpace(): number {
    return this.isLgSize ? -10 : -5;
  }

  get sizeStroke(): number {
    return this.isLgSize ? 9 : 6;
  }

  get sizeRadius(): number {
    return this.isLgSize ? 37 : 24;
  }

  get sizeFont(): string {
    return this.isLgSize ? '18px' : '14px';
  }

  public ngOnInit(): void {
    this.currentPercent = (this.current * 100) / this.total;
    if (this.currentPercent === 0) this.currentPercent = 3;
  }

  get classProgressByValue(): string {
    return getDefaultClassProgress(this.currentPercent)
  }
}
