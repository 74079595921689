<div class="compliance-average-progress-bar-wrapper">
   <div class="progress-bar-container targets-average-progress-bar">
      <div class="progress-bar-label-container">
         <span class="">Targets</span>
         <span class="progress-bar-label-percentage ">{{completedPercentValue | number: '1.0-0'}}%</span>
      </div>
      <mat-progress-bar class="progress-bar"
         [value]=completedPercentValue>
      </mat-progress-bar>
   </div>
</div>
