import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { PcpHistory } from '~/shared/models/pcp-history.model';
import { PcpHistoryService } from '~/shared/services/pcp-history.service';
import { ElementDetailService } from '../../../../core/services/element-detail.service';
import { DialogPcpHistoryComponent } from '../dialog-pcp-history/dialog-pcp-history.component';

@Component({
  selector: 'pcp-competency-detail',
  templateUrl: './competency-detail.component.html',
  styleUrls: ['./competency-detail.component.scss']
})
export class CompetencyDetailComponent implements OnInit {

  public competencyElement: any;
  public history: PcpHistory[];
  public isPromotionCompetencyElement = false;
  public dataTypePCE: any;

  constructor(
    private elementDetailService: ElementDetailService,
    public dialog: MatDialog,
    private historyService: PcpHistoryService,
  ) {
    //
  }

  public ngOnInit(): void {
    this.elementDetailService.onChange()
      .subscribe((data) => {
        if (data) {
          this.dataTypePCE = this.elementDetailService.getDataTypePCE;
          if (this.dataTypePCE !== undefined) {
            this.isPromotionCompetencyElement = true;
          } else {
            this.isPromotionCompetencyElement = false;
          }

          this.competencyElement = data.element;
        } else {
          this.competencyElement = null;
        }

        if (this.competencyElement) {
          this.historyService.getPcpHistory(this.competencyElement.id).subscribe(result => {
            this.history = result
          });
        }
      })
  }

  dialogPcpHistory() {
    this.dialog.open(DialogPcpHistoryComponent, {
      data: {
        ceId: this.isPromotionCompetencyElement === true ? this.competencyElement.ceId : this.competencyElement.id
      },
      minWidth: '45em',
      maxHeight: '40em'
    });
  }

  public isRequirementTypeSpecific(requirement: string) {
    return requirement == RequirementType.specific.valueOf();
  }

}
