import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class DataTypePCE {
  requirementType: string;
  plTarget: string;
}
@Injectable({
  providedIn: 'root'
})
export class ElementDetailService {

  private subject = new Subject<any>();
  private dataTypePCE: DataTypePCE;

  constructor() {
    //
  }

  public setValue(element: any) {
    this.subject.next({ element });
  }

  public onChange(): Observable<any> {
    return this.subject.asObservable();
  }

  public clear() {
    this.subject.next();
  }

  get getDataTypePCE() {
    return this.dataTypePCE;
  }

  set setDataTypePCE(dataTypePCE: DataTypePCE) {
    this.dataTypePCE = dataTypePCE;
  }

  public clearDataTypePCE() {
    this.dataTypePCE = undefined;
  }
}
