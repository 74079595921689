import { MyTrendsService } from './../../../../shared/services/my-trends.service';
import { CompetencyElement } from '~/shared/models/competency-element.model';
import { CompetencyUnitQuery } from './../../../../store/competency-unit/competency-unit.query';
import { Component, OnInit } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
// import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { Configuration } from '~/shared/models/configuration.model';
import { TargetV2Query } from '~/store/targetV2/targetV2.query';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends PageComponentBase implements OnInit {

  // public proficiencyLevels: Array<{ proficiencyLevel: ProficiencyLevel, count: number }>;
  public competencyUnits = new Array<CompetencyElement>();
  public hasElements = false;
  public version: string;
  public competencyElementName: string;
  somePromotion = true;
  waitingComplianceTargetsResponse = true;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private competencyUnitQuery: CompetencyUnitQuery,
    configuration: Configuration,
    elementDetailService: ElementDetailService,
    private trendsService: MyTrendsService,
    private targetV2Query: TargetV2Query,
  ) {

    super(pullToRefreshService);

    this.version = configuration.version;

    this.hasElements = true;

    // this.proficiencyLevelQuery.proficiencyLevels$.subscribe(
    //   (proficiencyLevels) => {
    //     this.proficiencyLevels = proficiencyLevels;
    //     this.hasElements = proficiencyLevels.filter((p) => p.count > 0).length > 0;
    //   });

    this.competencyUnitQuery.competencyUnits$.subscribe(
      (competencyUnit) => {
        if (!competencyUnit) {
          return
        }
        competencyUnit.forEach((x) => {
          x.competencyElements.filter((z) => z.expirationDate).forEach((y) =>
            this.competencyUnits.push(y)
          )
        })
        this.competencyUnits = this.competencyUnits.sort((a, b) => a.expirationDate.getTime() - b.expirationDate.getTime())
        this.competencyUnits = this.competencyUnits.filter((x) =>
          x.daysRemaining < 180
        )
      });

    elementDetailService.onChange()
      .subscribe((data) => {
        if (data) this.competencyElementName = data.name;
      })
  }

  ngOnInit(): void {
    this.targetV2Query.select().subscribe(response => {
      if (response?.targetV2) {
        this.waitingComplianceTargetsResponse = false;
      }
    });
  }

  // public onClick(proficiencyLevel: ProficiencyLevel, count: number): void {
  //   if (count === 0) {
  //     return;
  //   }

  //   this.router.navigate(
  //     ['..', 'competency-units', proficiencyLevel.id],
  //     {
  //       state: { title: `${proficiencyLevel.name} Units` }
  //     });
  // }

  public refreshRequested() {
    this.finishLoading();
  }
}
