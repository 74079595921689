<section *ngIf="showTarget">
  <h1 *ngIf="!back">Your Target Status</h1>
  <div *ngIf="back" class="target-wrap">
    <img src="assets/icons/arrow.svg" (click)="navigateToHome()" />
    <h1>Your Target Status</h1>
  </div>

  <div class="progress-title">
    <p>PCP OBJECTIVE STATUS</p>
    <p>{{target$?.objectiveStatus}}%</p>
  </div>
  <mat-progress-bar
    class="progress-bar"
    mode="determinate"
    [value]="target$?.objectiveStatus"
    [ngClass]="getClassProgressByValue(target$?.objectiveStatus)">
  </mat-progress-bar>

  <div class="progress-title">
    <p>CERTIFICATION COMPILANCE</p>
    <p class="percentage">{{target$?.certificationCompilance}}%</p>
  </div>
  <mat-progress-bar
    class="progress-bar"
    mode="determinate"
    [value]="target$?.certificationCompilance"
    [ngClass]="getClassProgressByValue(target$?.certificationCompilance)">
  </mat-progress-bar>

  <pcp-view-more *ngIf="viewMore" (clicked)="navigateToTargets()"></pcp-view-more>
</section>
