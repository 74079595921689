<mat-accordion class="accordion-default" *ngIf="!isHome" multi>

    <div *ngFor="let element of loadedElements; let i = index" class="container-item">

      <mat-expansion-panel [hideToggle]="element.competencyElements.length == 0" class="panel"
      [expanded]="elementSelected?.title === element.title">

        <mat-expansion-panel-header class="panel-header">

          <mat-panel-title class="content-target-title">
            <p class="title">{{ element.title }}</p>
            <div class="progress-bar-wrapper">
                <span> {{element.completedPercent}} %</span>
                <mat-progress-bar mode="determinate" [value]="element.completedPercent" class="progress-bar" [ngClass]="getClassProgressByValue(element)"> 
                </mat-progress-bar>
            </div>
          </mat-panel-title>

        </mat-expansion-panel-header>

        <div class="border-detail"></div>

        <div class="data-wrapper" *ngIf="element.competencyElements.length == 0">
          <p>No data</p>
        </div>

        <div *ngFor="let cE of element.competencyElements" class="container-detail rowSelectable">

          <pcp-card-element-list
            [competencyElement]="cE"
            [requirement]="element"
            (click)="showElementsDetail(cE, element)">
          </pcp-card-element-list>

        </div>

      </mat-expansion-panel>

    </div>
  </mat-accordion>


