import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/operators';
import { UserStore } from '~/store/user/user.store';
import { UserQuery } from '~/store/user/user.query';
import { Configuration } from '../models/configuration.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserGraphService {

  private endpoint = '';
  private user: any;
  public user$: Observable<User>;

  constructor(
    private httpClient: HttpClient,
    private config: Configuration,
    private userStore: UserStore,
    private userQuery: UserQuery
  ) {
    this.endpoint = `${this.config.graphApi}/users`;
  }

  private updateUserPhotoOnStore(avatar: string): void {
    let user;
    user = Object.assign({}, this.user, { avatar });
    this.userStore.update({ user });
  }

  public getUser(gin: number, isDev: boolean) {
    const select = 'extension_8b4e685f580748b59864d62c2e7fcfad_msDS_cloudExtensionAttribute12,displayName,extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber,jobTitle';
    const url = `${this.config.graphApi}/me?$select=${select}`;

    if (isDev) {
      this.httpClient.get(url).pipe(
        tap((result: any) => {
          if (result) {
            this.user = new User({
              ginNumber: gin,
              name: result.displayName,
              jobCode: result.jobTitle
            });
            let user;
            const avatar = this.userQuery.getValue().user?.avatar;
            if (avatar) {
              user = Object.assign({}, this.user, { avatar });
            } else {
              user = this.user;
            }
            this.userStore.update({ user });
          }
        })
      ).subscribe();
    } else {
      this.httpClient.get(url).pipe(
        tap((result: any) => {
          if (result) {
            this.user = new User({
              ginNumber: result.extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber,
              name: result.displayName,
              jobCode: result.jobTitle
            });
            let user;
            const avatar = this.userQuery.getValue().user?.avatar;
            if (avatar) {
              user = Object.assign({}, this.user, { avatar });
            } else {
              user = this.user;
            }
            this.userStore.update({ user });
          }
        })
      ).subscribe();
    }
  }

  public getGinNumber(email: string): Observable<number> {

    const filter = `userPrincipalName eq '${email}'`;
    const projection = 'extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber';

    const url = `${this.endpoint}?$filter=${filter} &$Select=${projection}`;

    return this.httpClient.get(url)
      .pipe(map((result: any) => parseInt(result.value[0] ? result.value[0][projection] : null, null)));
  }

  public getPhoto(email: string): Observable<string> {

    const subject = new Subject<string>();
    this.httpClient.get(`${this.endpoint}/${email}/photo/$value`, { responseType: 'blob' })
      .pipe(
        tap((result: any) => {
          if (result) {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.updateUserPhotoOnStore(reader.result as string)
              subject.next(reader.result as string);
              subject.complete();
            }
            reader.readAsDataURL(result);
          } else {
            subject.next(null);
            subject.complete();
          }
        })).subscribe(() => {
          //
        }, (error) => {
          //
        });

    return subject;
  }
}
