import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenQuery } from '../../store/token/token.query';
import { Configuration } from '../models/configuration.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';

@Injectable({ providedIn: 'root' })
export class PcpRequirementsService extends CompetencyApiBaseService<any> {

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery
    ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/pcp-requirements';
  }

  public getRequirements(gin: number, competencyElementId: number): Observable<any> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?GinNumber=${gin}&competencyelementid=${competencyElementId}`)
  }

  public getBasicRequirements(gin: number, competencyElementId: number): Observable<any> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint + '-basic'}?GinNumber=${gin}&competencyelementid=${competencyElementId}`)
  }
}
