<section class="full-width">

  <div class="container">

    <div [class.d-none]="!showFilters" class="container-flex-end container-select">

      <div class="container-select-order">
        <mat-select placeholder="Order by" (selectionChange)="orderSelected($event)">
          <mat-option *ngFor="let option of options" value="{{option}}">{{option}}</mat-option>
        </mat-select>
      </div>

      <div class="filter-input">
        <pcp-select-filter [filteredDataSource]="filteredDataSource" [filters]="filters" [labelKeys]="labelKeys"
          [labels]="labels" (keyValueSelect)="keyValueSelected($event);"></pcp-select-filter>
      </div>
    </div>

    <div class="container-search">
      <div *ngIf="showFilterSearch" class="form-input-search" [class.filtered]="search.value">
        <input type="text" placeholder="Search" [formControl]="search">
        <mat-icon class="icon-search" matSuffix>search</mat-icon>
        <mat-icon (click)="onClickClearSearch()" class="icon-clear" matSuffix>close</mat-icon>
      </div>

      <ng-container>
        <svg (click)="showFilters = !showFilters" width="38" height="38" xmlns="http://www.w3.org/2000/svg" fill="none">
          <g>
            <path id="svg_1" fill="#5E6670"
              d="m17.7012,19.601l9.7988,0l0,-1.275l-9.7988,0c-0.1438,-0.6307 -0.4974,-1.1938 -1.003,-1.5973c-0.5055,-0.4035 -1.1331,-0.6234 -1.7799,-0.6238c-0.6466,0.0007 -1.2739,0.2208 -1.7793,0.6242c-0.5053,0.4035 -0.8588,0.9664 -1.0027,1.5969l-1.6363,0l0,1.275l1.6363,0c0.1438,0.6305 0.4973,1.1937 1.0026,1.5974c0.5053,0.4038 1.1326,0.6242 1.7794,0.6253c1.36,0 2.4922,-0.952 2.7829,-2.2227l0,0zm-4.3673,-0.6375c0,-0.873 0.7106,-1.5836 1.5844,-1.5836c0.8738,0 1.5844,0.7106 1.5844,1.5836c0,0.8755 -0.7106,1.5852 -1.5844,1.5852c-0.4202,-0.0005 -0.8231,-0.1676 -1.1201,-0.4649c-0.2971,-0.2972 -0.4641,-0.7001 -0.4643,-1.1203l0,0zm9.713,-2.7438c1.36,0 2.4913,-0.952 2.782,-2.2236l1.6711,0l0,-1.2751l-1.6711,0c-0.1438,-0.6304 -0.4973,-1.1935 -1.0026,-1.59696c-0.5054,-0.40347 -1.1328,-0.62348 -1.7794,-0.62404c-0.6468,0.00052 -1.2743,0.2205 -1.7798,0.62396c-0.5056,0.40344 -0.8592,0.96654 -1.0031,1.59704l-9.764,0l0,1.2751l9.764,0c0.1437,0.6308 0.4972,1.1943 1.0027,1.5982c0.5055,0.4039 1.1331,0.6244 1.7802,0.6254l0,0zm-1.5853,-2.8611c0.0164,-0.4094 0.1906,-0.7965 0.486,-1.0803c0.2954,-0.2838 0.6892,-0.4423 1.0988,-0.4423c0.4097,0 0.8034,0.1585 1.0989,0.4423c0.2954,0.2838 0.4695,0.6709 0.486,1.0803c0,0.8755 -0.7106,1.5861 -1.5844,1.5861c-0.4206,-0.0003 -0.8238,-0.1675 -1.121,-0.4649c-0.2973,-0.2974 -0.4643,-0.7007 -0.4643,-1.1212l0,0zm-2.1692,14.1414c1.36,0 2.4913,-0.952 2.7821,-2.2227l5.4255,0l0,-1.275l-5.4255,0c-0.1437,-0.6307 -0.4971,-1.1939 -1.0025,-1.5976c-0.5054,-0.4036 -1.1328,-0.6237 -1.7796,-0.6243c-0.6469,0.0005 -1.2745,0.2206 -1.78,0.6242c-0.5055,0.4037 -0.8591,0.9669 -1.0029,1.5977l-6.0095,0l0,1.275l6.0095,0c0.1439,0.6307 0.4975,1.194 1.003,1.5977c0.5055,0.4037 1.133,0.6241 1.7799,0.625l0,0zm-1.5852,-2.8602c0,-0.8738 0.7114,-1.5844 1.5852,-1.5844c0.873,0 1.5844,0.7106 1.5844,1.5844c0,0.8755 -0.7114,1.5852 -1.5844,1.5852c-0.4203,-0.0004 -0.8232,-0.1676 -1.1204,-0.4648c-0.2972,-0.2972 -0.4644,-0.7002 -0.4648,-1.1204l0,0z" />
            <path id="svg_2" fill="#5E6670"
              d="m19,35.5c-9.1127,0 -16.5,-7.3873 -16.5,-16.5l-2,0c0,10.2173 8.28273,18.5 18.5,18.5l0,-2zm16.5,-16.5c0,9.1127 -7.3873,16.5 -16.5,16.5l0,2c10.2173,0 18.5,-8.2827 18.5,-18.5l-2,0zm-16.5,-16.5c9.1127,0 16.5,7.3873 16.5,16.5l2,0c0,-10.21727 -8.2827,-18.5 -18.5,-18.5l0,2zm0,-2c-10.21727,0 -18.5,8.28273 -18.5,18.5l2,0c0,-9.1127 7.3873,-16.5 16.5,-16.5l0,-2z" />
          </g>
        </svg>

      </ng-container>
    </div>
  </div>

  <div class="container-flex-end">
    <span *ngFor="let filter of filters">
      <pcp-selected-filter [labels]="labels" [filter]="filter" (removeClicked)="removeSelectedFilter($event);">
      </pcp-selected-filter>
    </span>
  </div>
</section>
