import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filterLabels } from 'src/app/configs/filter-config';
import { MatSelectChange } from '@angular/material/select';
import { isMobileSize } from '~/shared/utils/navigator';
import { CompetencyReportingService } from '~/shared/services/competency-reporting.service'
import { UserQuery } from '../../../store/user/user.query';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'pcp-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.scss']
})
export class DynamicFilterComponent implements OnInit, OnChanges {

  public showFilters = isMobileSize() ? false : true;
  @Input() public showFilterSearch = true;
  @Input() public filtersDefault: { key, value }[] = [];

  public search = new FormControl();
  public searchSubscription: Subscription;

  @Output()
  public filtered = new EventEmitter<any>();
  @Input()
  public dataSource: Array<any> = [];

  public labels = filterLabels;
  public labelKeys = filterLabels.map((i) => Object.keys(i)[0]);
  public filteredDataSource: Array<any> = [];
  public dataSourceFilter: Array<any> = [];
  public filters: { key, value }[] = [];
  public options = ['Expiring Soon', 'Alphabetically (A-Z)', 'Alphabetically (Z-A)', 'Proficiency Level (higher to lower)', 'Proficiency Level (lower to higher)'];
  public ginNumber: number;
  constructor(
    private competencyReportingService: CompetencyReportingService,
    private userQuery: UserQuery
  ) {
  }

  public ngOnInit() {
    this.userQuery.select('user')
      .subscribe((data) => {
        if (data?.ginNumber) this.ginNumber = data.ginNumber;
        this.filteredDataSource = [...this.dataSource];
      });
    this.searchSubscription = this.search.valueChanges.pipe(
      debounceTime(1200),
      distinctUntilChanged()
    ).subscribe(() => {
      const fromSearch = true;
      this.applyFilters(fromSearch);
    });
  }

  public ngOnChanges() {
    if (this.filteredDataSource.length === 0) this.filteredDataSource = [...this.dataSource];
    if (this.filtersDefault.length > 0) {
      this.filtersDefault.forEach((i) => {
        const indexFilter = this.filters.findIndex((f) => f.key === i.key)
        if (indexFilter >= 0)
          this.filters.splice(indexFilter, 1);
        this.keyValueSelected(i)
      })
    }
  }

  public removeSelectedFilter({ key, value }): void {
    const index = this.filters.indexOf(this.filters.find((f) => f.key === key && f.value === value));
    this.filters.splice(index, 1);
    this.filteredDataSource = [];
    this.applyFilters();
  }

  public keyValueSelected({ key, value }): void {
    this.filters.push({ key, value });
    this.applyFilters();
  }

  public async applyFilters(fromSearch?: boolean) {
    if (fromSearch) {
      this.filters = [];
      if (this.search.value != '') {
        this.competencyReportingService.getFilterReporting(this.ginNumber, this.search?.value?.toLowerCase()).subscribe((data) => {
          this.dataSourceFilter = this.dataSource.filter((i) => {
            return this.matchFilter(i)
              && (!this.search.value
                || Object.values(i).toString().toLowerCase().includes(this.search.value.toLowerCase()));
          });
          if (data?.elements) {
            this.filteredDataSource = [...this.dataSourceFilter, ...data.elements];
            this.dataSourceFilter = this.filteredDataSource;
          } else this.filteredDataSource = [];

          this.filtered.emit(this.filteredDataSource);
        });
      } else {
        this.filtered.emit(this.dataSource);
      }
    } else {
      if (this.filteredDataSource.length > 0) {
        this.filteredDataSource = this.dataSource.filter((i) => {
          return this.matchFilter(i)
            && (!this.search.value
              || Object.values(i).toString().toLowerCase().includes(this.search.value.toLowerCase()));
        });
      } else {

        if(this.search.value == '') this.filteredDataSource = this.dataSource
        else this.filteredDataSource = this.dataSourceFilter.length > 0 ? this.dataSourceFilter : this.dataSource;

      }
      this.filtered.emit(this.filteredDataSource);
    }
  }

  public matchFilter(item): boolean {
    if (this.filters.length > 0) {
      return this.filters.every((f) => {
        if (Array.isArray(item[f.key])) {
          return Array.from(item[f.key]).includes(f.value)
        }
        return item[f.key] === f.value
      })
    }
    return true;
  }

  public onClickClearSearch() {
    this.search.setValue('');
  }

  public orderAlphabetically(data) {
    data.sort((a, b) => {
      const nameA = a?.competencyElement.toUpperCase();
      const nameB = b?.competencyElement.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
    )
    return data;
  }

  public orderAntiAlphabetically(data) {
    data.sort((a, b) => {
      const nameA = a.competencyElement.toUpperCase();
      const nameB = b.competencyElement.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    }
    )
    return data;
  }

  public orderProficiencyLevel(data) {
    data.sort((a, b) => {
      const idA = a.pl;
      const idB = b.pl;
      if (idA < idB) {
        return 1;
      }
      if (idA > idB) {
        return -1;
      }
      return 0;
    })
    return data;
  }

  public orderAntiProficiencyLevel(data) {
    data.sort((a, b) => {
      const idA = a.pl;
      const idB = b.pl;
      if (idA > idB) {
        return 1;
      }
      if (idA < idB) {
        return -1;
      }
      return 0;
    })
    return data;
  }

  public orderDaysRemaining(data) {

    data.sort((a, b) => {
      const daysRemainingA = a?.expirationDate;
      const daysRemainingB = b?.expirationDate;

      if (daysRemainingA == undefined && daysRemainingB != undefined) return 1

      if (daysRemainingB == undefined && daysRemainingA != undefined) return -1

      if (daysRemainingA > daysRemainingB) {
        return 1;
      }
      if (daysRemainingA < daysRemainingB) {
        return -1;
      }
      return 0;
    })
    return data;
  }

  public orderSelected(event: MatSelectChange): void {
    if (this.filteredDataSource.length == 0) this.filteredDataSource = [...this.dataSource]

    switch (event.value) {
      case 'Alphabetically (A-Z)':
        this.filtered.emit(this.orderAlphabetically(this.filteredDataSource));
        break;
      case 'Alphabetically (Z-A)':
        this.filtered.emit(this.orderAntiAlphabetically(this.filteredDataSource));
        break;
      case 'Proficiency Level (higher to lower)':
        this.filtered.emit(this.orderProficiencyLevel(this.filteredDataSource));
        break;
      case 'Proficiency Level (lower to higher)':
        this.filtered.emit(this.orderAntiProficiencyLevel(this.filteredDataSource));
        break;
      case 'Expiring Soon':
        this.filtered.emit(this.orderDaysRemaining(this.filteredDataSource));
        break;
    }
  }

}
