import { Query } from '@datorama/akita';
import { IUserState, UserStore } from './user.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<IUserState> {
  public user$ = this.select((state) => state.user);

  constructor(protected store: UserStore) {
    super(store);
  }
}
