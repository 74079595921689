import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsOnlineGuard } from '~/core/guards/is-online.guard';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsOnlineGuard],
    component: LoginComponent,
    data:
    {
      showHeader: false,
      showFooterMenu: false,
      disablePullToRefresh: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
