import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { Trend } from '~/shared/models/trend.model';
import { TrendQuery } from '~/store/trend/trend.query';
import { User } from '~/shared/models/user.model';
import { UserQuery } from '~/store/user/user.query';

@Component({
	selector: 'compliance-user-trend-header',
	templateUrl: './compliance-user-trend-header.component.html',
	styleUrls: ['./compliance-user-trend-header.component.scss']
})
export class ComplianceUserTrendHeaderComponent implements OnInit {

	@Input() public isHome = false;
	public trend$: Trend;
	public user$: User;

	constructor(
		private trendQuery: TrendQuery,
		private userQuery: UserQuery,
		private router: Router
	) { }

	public ngOnInit(): void {
		this.trendQuery.select().subscribe((state) => {
			this.trend$ = state.trend;
		});

		this.userQuery.select().subscribe((state) => {
			this.user$ = state.user;
		});
	}

	public setIsHome(value: boolean) {
		this.isHome = value;
	}

	public navigateToHome() {
		this.isHome = true;
		this.router.navigate(['home']);
	}
}