import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CompetencyElement } from '~/shared/models/competency-element.model';

export interface ICompetencyElementState extends EntityState<CompetencyElement> {
}

const initialState = {
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'competencyElement', idKey: 'name' })
export class CompetencyElementStore extends EntityStore<ICompetencyElementState> {
  constructor() {
    super(initialState);
  }
}
