import { UserQuery } from '~/store/user/user.query';
import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeedbackComponent } from '~/modules/feedback/pages/feedback/feedback.component';
import { MIN_WIDTH_DIALOG_LG, MIN_WIDTH_DIALOG_MOBILE, SIZE_MIN_LG_SCREEN } from '~/shared/utils/navigator';

@Component({
  selector: 'pcp-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrls: ['./warning-card.component.scss']
})
export class WarningCardComponent {

  @Input() public competencyElementName: string;
  @Input() public competencyElementId: number;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private user: UserQuery
  ) { }

  public onClick() {
    const route = ['feedback'];
    const params = {
      queryParams: {
        competencyelementname: this.competencyElementName,
        origin: 'warning-card'
      }
    };

    this.router.navigate(route, params)
  }

  public onClickButton() {
    this.user.select().subscribe(value => {
      if (!value) {
        return;
      }

      const sizeDialog: MatDialogConfig = {
        minWidth: MIN_WIDTH_DIALOG_MOBILE,
        data: {
          competencyelementname: this.competencyElementName,
          gin: value.user.ginNumber,
          ceId: this.competencyElementId
        }
      };

      if (window.innerWidth <= SIZE_MIN_LG_SCREEN) {
        sizeDialog.minWidth = MIN_WIDTH_DIALOG_LG;
      }

      this.dialog.open(FeedbackComponent, sizeDialog)
    });
  }

}
