import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedModule } from '~/shared/shared.module';
import { CompetencyUnitsRoutingModule } from './competency-units-routing.module';
import { ExpirationDetailsComponent } from './components/expiration-details/expiration-details.component';
import { InfoItemComponent } from './components/info-item/info-item.component';
import { LevelInformationComponent } from './components/level-information/level-information.component';
import { ActionProgressComponent } from './components/requirement-progress/requirement-progress.component';
import { CompetencyElementsComponent } from './pages/competency-elements/competency-elements.component';
import { CompetencyUnitsComponent } from './pages/competency-units/competency-units.component';
import { ElementAssessmentComponent } from './components/element-assessment/element-assessment.component';
import { ElementDetailComponent } from './components/element-detail/element-detail.component';
import { ElementExperienceComponent } from './components/element-experience/element-experience.component';
import { ElementTrainingComponent } from './components/element-training/element-training.component';
import { WarningCardComponent } from '../../shared/components/warning-card/warning-card.component';
import { MyElementsComponent } from './pages/my-elements/my-elements.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { CompetencyDetailComponent } from './components/competency-detail/competency-detail.component';
import { DialogDetailComponent } from './components/dialog-detail/dialog-detail.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DialogPcpHistoryComponent } from './components/dialog-pcp-history/dialog-pcp-history.component';
const MODULE_COMPONENTS = [
  CompetencyUnitsComponent,
  CompetencyElementsComponent,
  ElementDetailComponent,
  LevelInformationComponent,
  ExpirationDetailsComponent,
  ActionProgressComponent,
  ElementTrainingComponent,
  ElementExperienceComponent,
  ElementAssessmentComponent,
  InfoItemComponent,
  WarningCardComponent,
  MyElementsComponent,
  CompetencyDetailComponent,
  DialogDetailComponent,
];

const MODULE_MODULES = [
  CommonModule,
  MatDividerModule,
  MatExpansionModule,
  SharedModule,
  CompetencyUnitsRoutingModule,
  NgCircleProgressModule.forRoot(),
  InfiniteScrollModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS,
    DialogPcpHistoryComponent
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class CompetencyUnitsModule { }
