import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoaderInterceptor } from '../interceptors/http-loader.interceptor';
import { JsonDateInterceptor } from '../interceptors/json-date.interceptor';
import { MsalInterceptor } from '@azure/msal-angular';

export const interceptorsProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JsonDateInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpLoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }
];
