import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserQuery } from '~/store/user/user.query';
import { ElementAssessment } from '~/shared/models/element-assessment.model';
import { CompetencyDetailsService } from '~/shared/services/competency-details.service';
@Component({
  selector: 'pcp-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.scss']
})
export class DialogDetailComponent implements OnInit {

  private ginNumber: number;
  private elementId: number;
  private pathway: string;
  private pl: string;
  public competencyElementName: string;
  public competencyDetail: any;
  public items: Array<ElementAssessment>;
  public pathwayType: string;
  public isLoading = true;
  public pathwayCompleted = '';

  constructor(public dialogRef: MatDialogRef<DialogDetailComponent>,
    private userQuery: UserQuery,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private competencyDetailsService: CompetencyDetailsService
    ) { }

  public ngOnInit(): void {
    this.ginNumber = this.userQuery.getValue().user.ginNumber;
    this.competencyElementName = this.data.competencyElementName;
    this.elementId = this.data.competencyElementId;
    this.pathway = this.data.pathway;
    this.pathwayType = this.data.pathwayType;
    this.pathwayCompleted = this.data?.pathwayCompleted;
    this.pl = this.data.pl;
    this.competencyDetailsService.getElementDetails(this.ginNumber,this.pl
      ,this.elementId,this.pathway,this.pathwayType).subscribe((data) => {
        if (data) {
          this.isLoading = false;
          this.competencyDetail = data;
        }
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

}
