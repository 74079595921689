import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable({ providedIn: 'root' })
export class PullToRefreshService {

  private _refreshRequested = new Subject<void>();
  private _finishLoading = new Subject<void>();
  public $refreshRequested: Observable<void>;
  public $finishLoading: Observable<void>;

  constructor(
    private loaderService: LoaderService
  ) {
    this.$refreshRequested = this._refreshRequested.asObservable();
    this.$finishLoading = this._finishLoading.asObservable();
  }

  public refreshRequested() {
    this.loaderService.disable();
    this._refreshRequested.next();
  }

  public finishLoading() {
    this._finishLoading.next();
    this.loaderService.enable();
  }
}
