<div class="display" [ngClass]="{ 'align-items-center': isRequirementTypePoints() }"
  *ngIf="pl?.id >= 80">
  <span *ngIf="pl?.id >= 80" class="label">{{pl?.name}}</span>
  <div *ngFor="let l of levels" class="item">
    <div class="element" *ngIf="l.keyEnum >= 81">
      <span>{{l.name[0]}}</span>
      <div class="value" *ngIf="!isRequirementTypePoints()" [ngClass]="{
        'bg-basic': pl.id >= l.keyEnum && pl.id == 81,
        'bg-intermediate': pl.id >= l.keyEnum && pl.id == 82,
        'bg-advanced': pl.id >= l.keyEnum && pl.id == 83,
        'bg-expert': pl.id >= l.keyEnum && pl.id == 84,
        'b-basic': plTarget.id >= l.keyEnum && pl.id == 81,
        'b-intermediate': plTarget.id >= l.keyEnum && pl.id == 82,
        'b-advanced': plTarget.id >= l.keyEnum && pl.id == 83,
        'b-expert': plTarget.id >= l.keyEnum && pl.id == 84,
        'bg-completed': (isRequirementTypeSpecific() || isRequirementTypeComplete()) && pl.id >= l.keyEnum && pl.id >= plTarget.id,
        'bg-incompleted': (isRequirementTypeSpecific() || isRequirementTypeComplete()) && pl.id >= l.keyEnum && pl.id < plTarget.id,
        'b-completed': (isRequirementTypeSpecific() || isRequirementTypeComplete()) && plTarget.id >= l.keyEnum && pl.id >= plTarget.id,
        'b-incompleted': (isRequirementTypeSpecific() || isRequirementTypeComplete()) && plTarget.id >= l.keyEnum && pl.id < plTarget.id
      }">
      </div>
      <div class="value" *ngIf="isRequirementTypePoints()" [ngClass]="{
        'bg-basic': pl.id >= l.keyEnum && pl.id == 81,
        'bg-intermediate': pl.id >= l.keyEnum && pl.id == 82,
        'bg-advanced': pl.id >= l.keyEnum && pl.id == 83,
        'bg-expert': pl.id >= l.keyEnum && pl.id == 84,
        'b-basic': pl.id >= l.keyEnum && pl.id == 81,
        'b-intermediate': pl.id >= l.keyEnum && pl.id == 82,
        'b-advanced': pl.id >= l.keyEnum && pl.id == 83,
        'b-expert': pl.id >= l.keyEnum && pl.id == 84
      }">
      </div>
      <span *ngIf="isRequirementTypePoints()"
      [ngClass]="{
        'color-basic': pl.id >= l.keyEnum && pl.id == 81,
        'color-intermediate': pl.id >= l.keyEnum && pl.id == 82,
        'color-advanced': pl.id >= l.keyEnum && pl.id == 83,
        'color-expert': pl.id >= l.keyEnum && pl.id == 84
      }">
        {{levelPointsLabel(l.keyEnum)}}
      </span>
    </div>
  </div>
</div>
