import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TokenStore, ITokenState } from './token.store';

@Injectable({ providedIn: 'root' })
export class TokenQuery extends QueryEntity<ITokenState> {

  public token$ = this.select((state) => state.token);
  public msalApiToken$ = this.select((state) => state.msalApiToken);
  public intouchToken$ = this.select((state) => state.intouchToken);
  public defaultlApiToken$ = this.select((state) => state.defaultlApiToken);
  public esmApiToken$ = this.select((state) => state.esmApiToken);

  constructor(protected store: TokenStore) {
    super(store);
  }
}
