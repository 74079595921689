<div class="container">
  <pcp-list [items]="competencyElements$ | async" [enableSearch]="true"
    searchPlaceholder="Search on Competency Elements" (searchChanged)="onSearchChanged($event)">
    <ng-template let-item>
      <pcp-list-item [showArrow]="true" (click)="onClick(item)">
        <ng-container itemTitle>{{ item.name }}</ng-container>
        <ng-container itemDescription>{{ competencyDescription(item) }}
        </ng-container>
      </pcp-list-item>
    </ng-template>
  </pcp-list>
</div>
