import { Trend } from '~/shared/models/trend.model';
import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface ITrendState extends EntityState<Trend> {
  trend: Trend
}

export function createInitialState(): ITrendState {
  return {
    trend: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'trend' })
export class TrendStore extends Store<ITrendState> {
  constructor() {
    super(createInitialState());
  }
}
