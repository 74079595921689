import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompetencyElement } from '~/shared/models/competency-element.model';

@Component({
  selector: 'pcp-promotion-points-ce',
  templateUrl: './promotion-points-ce.component.html',
  styleUrls: ['./promotion-points-ce.component.scss']
})
export class PromotionPointsCEComponent {

  @Input()
  public last = false;

  @Input()
  public competencyElement: CompetencyElement;

  @Output()
  public competencyElementClick = new EventEmitter<CompetencyElement>();

  public viewCompetencyElement(): void {
    if (!this.competencyElement.proficiencyLevel.isNullOrNone) {
      this.competencyElementClick.emit(this.competencyElement);
    }
  }

  get competencyDescription(): string {
    if (this.competencyElement) {
      return this.competencyElement.proficiencyLevel.name;
    }
    return '';
  }

  get pointsDescription(): string {
    if (this.competencyElement) {
      return `${this.competencyElement.promotionValue} points`;
    }
    return '';
  }
}
