<mat-accordion class="accordion-default" *ngIf="!isHome" multi>

  <div *ngFor="let requirement of requirements; let i = index" class="container-item">

    <pcp-requirement-separator [index]="i" [requirementType]="requirement.requirementType"></pcp-requirement-separator>

    <mat-expansion-panel [hideToggle]="requirement.competencyElements.length == 0" class="panel"
      (opened)="onDetail = undefined">

      <mat-expansion-panel-header class="panel-header">

        <mat-panel-title *ngIf="isRequirementTypeComplete(requirement)" class="content-target-title">
          <p class="title">{{ titleLabelComplete(requirement) }}</p>
          <div class="progress-bar-wrapper">
            <span> {{requirement.achievedCeCount}}/{{requirement.requiredCount}}</span>
            <mat-progress-bar [ngClass]="getClassProgressByValue(requirement)" class="progress-bar" mode="determinate"
              [value]="(requirement.achievedCeCount * 100)/requirement.requiredCount">
            </mat-progress-bar>
          </div>
        </mat-panel-title>

        <mat-panel-title *ngIf="isRequirementTypePoints(requirement)" class="content-target-title">
          <p class="title">{{ requirement.requiredPoints }} points required</p>
          <div class="progress-bar-wrapper">
            <span> {{requirement.pointsAchieved}}/{{ requirement.requiredPoints }}</span>
            <mat-progress-bar [ngClass]="getClassProgressByValue(requirement)" class="progress-bar"
              mode="determinate" [value]="pointAchievedPercentage(requirement)">
            </mat-progress-bar>
          </div>
        </mat-panel-title>

        <mat-panel-title *ngIf="isRequirementTypeSpecific(requirement)" class="content-target-title">
          <p class="title">{{ requirement.requirementType }}</p>
          <div class="progress-bar-wrapper">
            <span> {{numberToEqualOrHigherThanZero(requirement.achievedCeCount)}}/{{requirement.cecount}}</span>
            <mat-progress-bar [ngClass]="getClassProgressByValue(requirement)" class="progress-bar" mode="determinate"
              [value]="(requirement.achievedCeCount * 100)/requirement.cecount">
            </mat-progress-bar>
          </div>
        </mat-panel-title>

      </mat-expansion-panel-header>

      <div class="border-detail"></div>

      <div class="data-wrapper" *ngIf="requirement.competencyElements.length == 0">
        <p>No data</p>
      </div>

      <div *ngFor="let cE of requirement.competencyElements" class="container-detail rowSelectable"
        [class.rowOpacity]="onDetail && onDetail.name !== cE.name"
        [class.rowSelected]="onDetail && onDetail.name === cE.name">

        <pcp-card-item-list-points *ngIf="isRequirementTypePoints(requirement)"
          [competencyElement]="cE"
          [requirement]="requirement"
          (click)="showElementsDetail(cE, requirement)">
        </pcp-card-item-list-points>

        <pcp-card-item-list *ngIf="isRequirementTypeComplete(requirement)"
          [plTarget]="requirement.requiredLevel"
          [requirementType]="requirement.requirementType"
          [competencyElement]="cE"
          (click)="showElementsDetail(cE, requirement)">
        </pcp-card-item-list>

        <pcp-card-item-list *ngIf="isRequirementTypeSpecific(requirement)"
          [requirementType]="requirement.requirementType"
          [competencyElement]="cE"
          (click)="showElementsDetail(cE, requirement)">
        </pcp-card-item-list>

        <pcp-my-elements-detail *ngIf="isMobileSize && onDetail && onDetail.name === cE.name"
          [promotionCompetencyElement]="onDetail"
          [inputPlTarget]="requirement.requiredLevel"
          [isRequirementTypeSpecific]="isRequirementTypeSpecific(requirement)">
        </pcp-my-elements-detail>

      </div>

    </mat-expansion-panel>

    <div *ngIf="(i + 1) < requirements?.length" class="grouping-type-container">
      <div class="line-break"></div>
      <span>
        {{groupingType}}
      </span>
      <div class="line-break"></div>
    </div>

  </div>
</mat-accordion>
