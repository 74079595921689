import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { CompetencyReporting } from '~/shared/models/competency-repoting.model';
import { ICompetencyReportingState } from '../competency-reporting/competency-reporting.store';

export interface ICompetencyReportingFilterState extends EntityState<CompetencyReporting> { }

export function createInitialState(): ICompetencyReportingState {
  return null;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'competency-reporting-filter', idKey: 'gin' })
export class CompetencyReportingFilterStore extends EntityStore<ICompetencyReportingFilterState> {
  constructor() {
    super(createInitialState());
  }
}
