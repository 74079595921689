import { Component, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
  selector: 'pcp-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent {
  @ContentChild(TemplateRef, { read: TemplateRef }) public templateRef: any;
  @Input() public iconSrc: string;
  @Input() public iconAlt: string;
  @Input() public label: string;
  @Input() public value: string;
  // CR1497
  @Input() public hyperLinkForValue: string;

  /**
   * L - Large (class fontSize3)
   * M - Medium (class fontSize4)
   * S - Small (class fontSize5)
   */
  @Input() public size = 'L';
}
