<div class="card" (click)="outputCompetencyCompliance()">
  <div class="infos">
    <div class="info-titles">
      <div class="subtitle">
        {{competencyCompliance.competencyUnit}}
      </div>
      <div class="title">
        {{competencyCompliance.competencyElement}}
      </div>
    </div>
    <div class="info-item">
      <span>Jobs:</span> {{competencyCompliance.jobCount}}
    </div>
  </div>

  <div class="chart">
    <pcp-display-proficiency-level
      [competencyElement]="competencyElement"
      [requirementType]="requirementTypeComplete"
      [inputPlTarget]="proficiencyLevel.name">
    </pcp-display-proficiency-level>
  </div>
</div>
