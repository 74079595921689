import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PcpHistory } from '~/shared/models/pcp-history.model';
import { PcpHistoryService } from '~/shared/services/pcp-history.service';

@Component({
  selector: 'pcp-dialog-pcp-history',
  templateUrl: './dialog-pcp-history.component.html',
  styleUrls: ['./dialog-pcp-history.component.scss']
})
export class DialogPcpHistoryComponent implements OnInit {

  history: PcpHistory[]
  constructor(
    public dialogRef: MatDialogRef<DialogPcpHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      ceId: number
    },
    private historyService: PcpHistoryService,
  ) { }

  ngOnInit(): void {
    this.historyService.getPcpHistory(this.data.ceId).subscribe(result => {
      this.history = result
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
