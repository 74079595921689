import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pcp-view-more',
  templateUrl: './view-more.component.html',
  styleUrls: ['./view-more.component.scss']
})
export class ViewMoreComponent {

  @Output()
  public clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    //
  }

}
