import { Component } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';

@Component({
  selector: 'pcp-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent extends PageComponentBase {
  public constructor(
    pullToRefreshService: PullToRefreshService
  ) {
    super(pullToRefreshService);
  }
}
