export const allowedGins: number[] = [
  80012442,
  3536083,
  3573177,
  3648813,
  5395280,
  2498780,
  3097847,
  3009214,
  4959342,
  1779305,
  3020252,
  2131209,
  731935,
  1179811,
  5371000,
  3019759,
  1019421,
  5309109,
  3449576,
  805648,
  2281533,
  2372340,
  2511251,
  3150679,
  5476825,
  476168,
  3113800,
  6580534,
  5431275,
  1457746,
  349548,
  2496594,
  2646552,
  3458403,
  2991008,
  3129871,
  1918937,
  3131992,
  2419570,
  2316081,
  3310919,
  1878339,
  703769,
  2761120,
  3230661,
  1455492,
  1719475,
  1036037,
  3098134,
  1441336,
  1065184,
  1742170,
  4327490,
  3356813,
  1045426,
  3550449,
  5453626,
  3037967,
  1046812,
  4478111,
  2018547,
  3150679,
  5349493,
  2646552,
  3129871,
  1347574,
  5404884,
  5320106,
  2278570,
  6155881,
  4959342,
  5860945,
  6392815,
  2287498,
  5240767,
  6414825,
  6534200,
  6553887,
  6408561,
  6293195,
  6075832,
  6430995,
  5910070,
  5965207,
  3824620,
  6417174,
  481655,
  5965116,
  689547,
  6492680,
  2249357,
  6178958,
  6071328,
  6582191,
  5885025,
  1436385,
  562116,
  80016096,
  1014471,
  6609176,
  5787064,
  1062512,
  6648539,
  3568391,
  6240162,
  6648539,
  80026243,
  80030390,
  6479364,
  1056407,
  1002203,
  80038578,
  80046831,
  80050418
];
