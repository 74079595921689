import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompetencyElement, PromotionCompetencyElement } from '~/shared/models/competency-element.model';

@Component({
  selector: 'pcp-promotion-requirement-ce',
  templateUrl: './promotion-requirement-ce.component.html',
  styleUrls: ['./promotion-requirement-ce.component.scss']
})
export class PromotionRequirementCEComponent {

  @Input()
  public last = false;

  @Input()
  public competencyElement: PromotionCompetencyElement;

  @Output()
  public competencyElementClick = new EventEmitter<PromotionCompetencyElement>();

  public viewCompetencyElement(): void {
    if (!this.competencyElement.proficiencyLevel) {
      this.competencyElementClick.emit(this.competencyElement);
    }
  }

  get competencyDescription(): string {
    if (this.competencyElement) {
      //if (this.competencyElement.proficiencyLevel.isNullOrNone) {
      if (this.competencyElement.proficiencyLevel) {
        return '';
      }
      // if (this.competencyElement.daysRemaining > 0) {
      //   return `${this.competencyElement.proficiencyLevel}, ${this.competencyElement.daysRemaining} ${this.competencyElement.daysRemaining > 1 ? 'days' : 'day'} remaining`;
      // }
      return `${this.competencyElement.proficiencyLevel}`;
    }
    return '';
  }
}
