import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { Configuration } from '~/shared/models/configuration.model';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log("MSAL Angular: ", message);
  // console.log("MSAL Angular logLevel: ", logLevel);
}
export class MsalConfig {
  public static getMsalInstanceConfig(config: Configuration): PublicClientApplication {

    return new PublicClientApplication({
      auth: {
        clientId: config.azureClientId,
        authority: `https://login.microsoftonline.com/${config.azureTenantId}`,
        redirectUri: `${window.location.origin}/login`,
        postLogoutRedirectUri: `${window.location.origin}/#/login`,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
        secureCookies: true
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    })
  }

  public static getMsalConfig(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read', 'openid', 'profile', 'Mail.Send']
      },
      loginFailedRoute: '/login/auth-denied'
    } as MsalGuardConfiguration
  }

  public static getMsalInterceptorConfig(config: Configuration): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, string[]>();
    protectedResourceMap.set(config.graphApi, ['user.read', 'openid', 'profile']);

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    } as MsalInterceptorConfiguration
  }
}
