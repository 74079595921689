

  const config = {
    
    apiUrl: 'https://apigateway.evt.slb.com/risk-to-integrity/competency_reporting',
    newApiUrl: 'https://apigateway.evt.slb.com/personal-competency-profile/',
    apiUrlEsm: 'https://apigateway.evt.slb.com/competency-ticket-service/',
    graphApi: 'https://graph.microsoft.com/v1.0',
    apiKey: '', 
    apiSecret: '',
    apiAuthenticationTokenUrl: 'https://apigateway.evt.slb.com/oauth2',
    azureTenantId: '41ff26dc-250f-4b13-8981-739be8610c21',
    azureClientId: '9fe2d177-4b29-4f47-a1cc-deadfa3fc8cf',
    instrumentationKey: 'ee1b9fb5-9128-460c-8aa4-9cddcd2e95a7',
    feedbackEmail: '',
    intouchApiKey : "", 
    intouchApiSecret : "", 
    intouchApiUrl : "",
    intouchBaseUrl : "",
    ilearnUrlPrefix :"https://www.personnel.slb.com/tm/iLearn.aspx?GO=SearchCertificationCustom?slbPersonType=learner%26certi_name=",
    intouchFaqUrl :"https://wiki.slb.com/display/CMS/FAQ",
    version: "2.0.0",
    isUnderMaintenance: false,
    apiScope: "https://slb001.onmicrosoft.com/02730ccc-7bff-482f-b3bb-81632c362874/mypcp",
    newApiKey: "",
    esmAuthUrl: "",
    esmKey: "",
    esmSecret: "",
    esmUsername : "",
    esmPassword : "",
    apiUrlAdminTool : "https://apigateway.evt.slb.com/competency-pcpadmintool/",
    env : "qa",
    esmApiScope : "api://slb001.onmicrosoft.com/3bbd6522-c2cf-41f0-a092-3a05c7b33762/ticketservice",
    esmServiceOffering : "",
    linkEsmFeedback : ""

};
  
  export {config};