import { Component, OnInit } from '@angular/core';
import { User } from '~/shared/models/user.model';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'pcp-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent {

  public user: User;

  constructor(
    private userQuery: UserQuery,
  ) {
    userQuery.select('user')
      .subscribe((data) => {
        this.user = data
      })
  }

}
