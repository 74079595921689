<ng-container *ngIf="user$ | async as user">
  <section class="section-container" *ngIf="promotion$ | async as promotion">
    <section *ngIf="!isHome" [ngClass]="{'content': true, 'all-complete': promotion.allComplete}">

      <div class="header">
        <img src="assets/icons/arrow_white.svg" (click)="navigateToHome()" class="header-image" />
        <h1>My Odyssey</h1>
      </div>

      <header class="user-profile">
        <div class="basic-data">
          <h1 class="user-name">Tony Spark | GIN 12345678</h1>
        </div>
      </header>

      <section>
        <dl class="user-data">
          <div class="current-user-position">
            <dt class="fontSize6">Current</dt>
            <dt class="fontSize6">Position</dt>
            <dd class="fontSize3 value-grade">{{ getCurrentPosition() }}</dd>
          </div>

          <div class="user-data-icon">
            <img *ngIf="promotion.allComplete" src="assets/icons/promotion-complete.svg" alt="promotion completed">
            <img *ngIf="!promotion.allComplete" src="assets/icons/knight.svg" alt="promotion not completed">
          </div>

          <div class="next-user-position">
            <dt class="fontSize6">Next</dt>
            <dt class="fontSize6">Position</dt>
            <dd class="fontSize3 value-grade">{{ getNextPosition() }}</dd>
          </div>
        </dl>
      </section>
    </section>

    <section *ngIf="isHome" class="section-default">

      <figure class="promotion-icon">
        <img src="assets/icons/knight.svg" alt="promotion step">
      </figure>

      <div class="container-step-promotion">
        <h4>My Odyssey</h4>
        <div class="steps">
          <div class="next-user-position">
            <span class="label">To</span>
            <span class="value">{{ getNextPosition() }}</span>
          </div>
        </div>
      </div>

    </section>
  </section>
</ng-container>
