export class ElementAssessment {

  public type: string;
  public date: Date;
  public sourceSystem: string;
  public id: string;
  public pass: boolean;

  public constructor(
    assessmentType: string,
    assessmentDate: Date,
    sourceSystem: string,
    sourceAssessmentId: string,
    completionStatus: string) {

    this.type = assessmentType;
    this.date = assessmentDate;
    this.sourceSystem = sourceSystem;
    this.id = sourceAssessmentId;
    this.pass = completionStatus.trim().toLowerCase() === 'pass';
  }
}
