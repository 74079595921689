import { TargetStatus } from './../../shared/models/target-status.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface ITargetStatusState extends EntityState<TargetStatus>  {
  targetStatus: TargetStatus
}

export function createInitialState(): ITargetStatusState {
  return {
    targetStatus: {
      certificationCompilance: 0,
      objectiveStatus: 0
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'target-status' })
export class TargetStatusStore extends EntityStore<ITargetStatusState> {
  constructor() {
    super(createInitialState());
  }
}
