import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { LoaderService } from '~/core/services/loader.service';

@Injectable({ providedIn: 'root' })
export class HttpLoaderInterceptor implements HttpInterceptor {

  public constructor(
    private loaderService: LoaderService
  ) {
  }

  private onRequestStart(): void {
    this.loaderService.enqueue();
  }

  private onRequestEnd(): void {
    this.loaderService.dequeue();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.onRequestStart();

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.onRequestEnd();
      }
    }, () => {
      this.onRequestEnd();
    }));
  }
}
