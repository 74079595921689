import { Component } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';

@Component({
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent extends PageComponentBase {
  public constructor(
    pullToRefreshService: PullToRefreshService
  ) {
    super(pullToRefreshService);
  }

  public onClick(): void {
    if (navigator.onLine) {
      window.location.href = '/#/home';
      window.location.reload();
    }
  }
}
