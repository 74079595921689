import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedModule } from '~/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { CompetencyUnitsModule } from '../competency-units/competency-units.module';
import { NoDataFoundModule } from '../no-data-found/no-data-found.module';
import { YourTargetsComponent } from './pages/your-targets.component';
import { YourTargetsRoutingModule } from './your-targets-routing.module';
import { YourTargetsHeaderComponent } from './components/your-targets-header/your-targets-header.component';
import { TargetsElementListComponent } from './components/targets-element-list/targets-element-list.component';
import { CardElementListComponent } from './components/card-element-list/card-element-list.component';
import { ComplianceModule } from '../compliance/compliance.module';


const MODULE_COMPONENTS = [
  YourTargetsComponent,
  YourTargetsHeaderComponent,
  TargetsElementListComponent,
  CardElementListComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  YourTargetsRoutingModule,
  NgCircleProgressModule.forRoot(),
  MatExpansionModule,
  MatDividerModule,
  SharedModule,
  CompetencyUnitsModule,
  NoDataFoundModule,
  ComplianceModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS    
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class YourTargetsModule { }
