export interface IFeedbackType {
  description: string
  fields: Array<string>
  hasTitle: boolean
}

export interface IFeedbackField {
  id: string
  description: string
  multiple: boolean
  freetextline: boolean
  mandatory: boolean
  options: Array<string>
}

export interface IFeedbackData {
  types: Array<IFeedbackType>
  fields: Array<IFeedbackField>
}

export const feedbackData: IFeedbackData = {
  types: [
    {
      description: 'Feedback',
      fields: ['descriptionmandatory'],
      hasTitle: true
    },
    {
      description: 'Report a problem with data',
      fields: ['whatsyourproblem', 'whereisyourproblem', 'description'],
      hasTitle: false
    },
    {
      description: 'Report a problem with MyPCP App',
      fields: ['subtype', 'descriptionmandatory'],
      hasTitle: false
    }
  ],
  fields: [
    {
      id: 'whatsyourproblem',
      description: 'What\'s your problem?',
      mandatory: true,
      freetextline: false,
      multiple: false,
      options: [
        'Missing Assesment',
        'Missing experience',
        'Missing Training Certification',
        'Missing PLE Certification',
        'Promotion Calculation Wrong',
        'Links do not work',
        'Other'
      ]
    },
    {
      id: 'whereisyourproblem',
      description: 'Where is your problem?',
      mandatory: true,
      freetextline: true,
      multiple: false,
      options: []
    },
    {
      id: 'subtype',
      description: 'What\'s your problem?',
      mandatory: true,
      freetextline: false,
      multiple: false,
      options: ['Cannot find CE/CU', 'Button does not work', 'Other']
    },
    {
      id: 'description',
      description: 'Description',
      mandatory: false,
      freetextline: true,
      multiple: false,
      options: []
    },
    {
      id: 'descriptionmandatory',
      description: 'Description',
      mandatory: false,
      freetextline: true,
      multiple: false,
      options: []
    }
  ]
}
