import { Component, Input, OnInit } from '@angular/core';
import { RequirementType } from '~/shared/constants/requirement-type';

@Component({
  selector: 'pcp-requirement-separator',
  template: `
  <div *ngIf="index > 0 && separators.includes(requirementType)" class="separator">
    <div class="separator-line"></div>
    <span>{{requirementType}}</span>
    <div class="separator-line"></div>
  </div>
  `,
  styleUrls: ['./requirement-separator.component.scss']
})
export class RequirementSeparatorComponent {

  public separators = ['And', 'Or'];
  @Input() public requirementType: RequirementType;
  @Input() public index: number;

  constructor() {
    //
  }

}
