import { EnvironmentService } from './../../../../shared/services/environment.service';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ProficiencyLevel } from './../../../../shared/models/proficiency-level.model';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MyTrendsService } from '~/shared/services/my-trends.service';
import { Trend } from '~/shared/models/trend.model';
import { TrendQuery } from '~/store/trend/trend.query';
import { ComplianceUserTrendHeaderComponent } from '../../components/compliance-user-trend-header/compliance-user-trend-header.component';
import { CompetencyCompliance } from '~/shared/models/copetency-compliance.model';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { isMobileSize } from '~/shared/utils/navigator';
import { sleep } from '~/shared/utils/sleep';
import { TargetV2Query } from '~/store/targetV2/targetV2.query';
import { TargetV2 } from 'src/app/shared/models/targetV2.model';


const MILLISECONDS_LOAD_LIST = 500;
@Component({
  selector: 'pcp-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent extends PageComponentBase implements OnInit {

  @ViewChild(ComplianceUserTrendHeaderComponent)
  public complianceUserTrendHeaderComponent: ComplianceUserTrendHeaderComponent;

  @Input() public isHome = false;
  @Input() waitingComplianceTargetsResponse: boolean;
  public panelOpenState: boolean;
  public groupParam: string;
  public trend$: Trend;
  public showDetail: CompetencyCompliance;
  public onDetail: { id: number, competencyElement: string, competencyUnit: string, pl: number, targetPL: number };
  public onDetailPL: ProficiencyLevel;
  public filtersDefault: { key, value }[] = [];
  public competencyCompliances: Array<CompetencyCompliance> = [];
  public filteredCompetencyCompliances: Array<CompetencyCompliance> = [];
  public targetsV2: TargetV2;
  public index = 0;
  public itemsPage = 20;
  public isLoading = false;

  public useMobileSize = false;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private router: Router,
    private route: ActivatedRoute,
    private trendsService: MyTrendsService,
    private trendQuery: TrendQuery,
    private targetsV2Query: TargetV2Query,
    private elementDetailService: ElementDetailService,
    private environmentService: EnvironmentService
  ) {
    super(pullToRefreshService);
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    // Size taken from _settings.css in 10/19/22
    if (window.innerWidth < 767) {
      this.useMobileSize = true;
    } else {
      this.useMobileSize = false;
    }
  }

  private loadUserTrendData() {
    this.trendQuery.select().subscribe((state) => {
      this.trend$ = state.trend;
      if (this.trend$?.competencyCompliances) {
        this.competencyCompliances = this.trend$.competencyCompliances;
        this.filteredCompetencyCompliances = this.competencyCompliances;
      }
    });
  }

  private loadUserTargetsData() {
    this.targetsV2Query.select().subscribe((state) => {
      if (state && state.targetV2) {
          this.targetsV2 = state.targetV2
      }
    });
  }

  get isMobileSize() {
    return isMobileSize()
  }

  private detailObject(element: CompetencyCompliance): any {
    return {
      id: element.ceId,
      competencyElement: element.competencyElement,
      competencyUnit: element.competencyUnit,
      pl: element.currentPL,
      targetPL: element.reqPL,
      awardedOnDate: element.awardedOnDate
    };
  }

  private async paginator() {
    const data = this.filteredCompetencyCompliances.slice((this.index) * this.itemsPage, (this.index) * this.itemsPage + this.itemsPage);
    if (data.length > 0) {
      this.isLoading = true;
      this.index++;
      await sleep(MILLISECONDS_LOAD_LIST)
      this.isLoading = false;
    } else {
      await (this.isLoading = false);
    }
  }

  private resetList() {
    this.filteredCompetencyCompliances = [...this.competencyCompliances]
    this.index = 0;
    this.showDetail = null;
  }

  public applyFilter(filtered) {
    this.resetList();
    this.filteredCompetencyCompliances = [...filtered];
    this.paginator();
  }

  public navigateToHome() {
    this.complianceUserTrendHeaderComponent.setIsHome(true);
    this.isHome = true;
    this.router.navigate(['home']);
  }

  public ngOnInit(): void {
    this.onResize();
    this.loadUserTrendData();
    this.loadUserTargetsData();
  }

  public navigateToCompliance() {
    if(this.complianceUserTrendHeaderComponent)
      this.complianceUserTrendHeaderComponent.setIsHome(false);
    this.isHome = false;
    this.router.navigate(['compliance']);
  }

  public navigateToYourTargets() {
    if(this.complianceUserTrendHeaderComponent)
      this.complianceUserTrendHeaderComponent.setIsHome(false);
    this.isHome = false;
    this.router.navigate(['your-targets']);
  }

  public showElementsDetail(element: CompetencyCompliance) {
    if (this.showDetail?.competencyElement === element.competencyElement) {
      this.showDetail = null;
      this.elementDetailService.clear();
      return;
    }

    this.showDetail = element;
    this.onDetail = this.detailObject(element);

    if (!this.isMobileSize) {
      this.elementDetailService.clearDataTypePCE();
      this.elementDetailService.setValue(this.onDetail);
    }
  }

  isDev() {
    return this.environmentService.isDev;
  }
}
