<pcp-loader></pcp-loader>

<pcp-pull-to-refresh [disabled]="disablePullToRefresh">
</pcp-pull-to-refresh>

<pcp-header *ngIf="isHome">
</pcp-header>

<pcp-header *ngIf="!isHome" [pageTitle]="pageTitle"
  [showBackButton]="showBackButton" [showHomeButton]="showHomeButton" [allComplete]="allComplete">
</pcp-header>

<main #main (touchstart)="onTouchStart($event)">
  <router-outlet #outlet="outlet"></router-outlet>
</main>

<pcp-feedback-button></pcp-feedback-button>

<pcp-offline-notification *ngIf="!hideOfflineNotification"></pcp-offline-notification>

<pcp-ios-popup-add-to-homescreen></pcp-ios-popup-add-to-homescreen>
