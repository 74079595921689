import { Component } from '@angular/core';
import { User } from '~/shared/models/user.model';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'pcp-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

  public user: User;

  constructor(
    private userQuery: UserQuery,
  ) {
    userQuery.select('user')
      .subscribe((data) => {
        this.user = data
      })
  }

  public handleImageError(user): void {
    if (user) {
      user.image = null;
    }
  }
}
