<div class="item-container">

  <div class="container-chart">

    <div class="chart">
      <canvas id="myChart"></canvas>
      <div class="container-chart-count">
        <span class="sum">{{sum}}</span>
        <span class="label">elements</span>
      </div>
    </div>

    <div class="container-arrows">

      <div *ngFor="let item of proficiencyLevels let i = index" (click)="onClick(item.proficiencyLevel, item.count)"
        class="content-arrow">

        <div   class="mark" [ngStyle]="{ 'background-color': this.backgroundColors[i]  }">
        </div>

        <div class="content-legend">
          <span class="label"> {{ this.labels[i] }} </span>
          <span class="count">{{ item?.count }} Elements</span>
        </div>

      </div>

    </div>

  </div>

  <pcp-view-more (clicked)="navigateTo()"></pcp-view-more>

</div>
