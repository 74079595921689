import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { environment } from '@env';
import { ApplicationInsights, Snippet } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { Configuration } from '~/shared/models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  private appInsights: ApplicationInsights;

  constructor(
    config: Configuration,
    private router: Router) {

    const snippet: Snippet = {
      config: {
        instrumentationKey: config.instrumentationKey,
        disableFlushOnBeforeUnload: !environment.production,
        disableExceptionTracking: true,
        isCookieUseDisabled: true
      }
    }
    this.appInsights = new ApplicationInsights(snippet);
    this.appInsights.loadAppInsights();

    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);

        if (activatedComponent) {
          this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';

    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  private convertHttpErrorResponseToError(error: HttpErrorResponse): Error {
    const result = new Error('Error when calling an API');
    result.stack = JSON.stringify(error);
    return result;
  }

  private convertAnyToError(error: any): Error {
    return new Error(JSON.stringify(error));
  }

  public setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
    this.appInsights.context.user.id = userId;
  }

  public clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  public trackException(error: any): void {
    if (error instanceof Error) {
      this.appInsights.trackException({ exception: error });
    } else if (error instanceof HttpErrorResponse) {
      this.appInsights.trackException({ exception: this.convertHttpErrorResponseToError(error) });
    } else {
      this.appInsights.trackException({ exception: this.convertAnyToError(error) })
    }
  }
}
