import { ProficiencyLevel } from './../../models/proficiency-level.model';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { SIZE_MIN_MD_SCREEN } from '~/shared/utils/navigator';
import { CompetencyReportingQuery } from '~/store/competency-reporting/competency-reporting.query';
import { CompetencyReporting } from '~/shared/models/competency-repoting.model';

const COLORS_PL = {
  none: '#6d6f72',
  basic: '#DCE1E1',
  intermediate: '#6E8CC8',
  advanced: '#00D2DC',
  expert: '#0014DC',
}

@Component({
  selector: 'pcp-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  public proficiencyLevels: Array<{ proficiencyLevel: number, count: number }>;
  public dataset: any;
  public labels: any;
  public backgroundColors: any;
  public sum: any = 0;
  public competencyReporting: CompetencyReporting;
  public elementNoneCount = 0;

  constructor(
    private router: Router,
    private competencyReportingQuery: CompetencyReportingQuery
  ) { }

  public ngOnInit() {
    this.loadCompetencyReports();
  }

  public findProficencyLevel(level: number): ProficiencyLevel {
    const pl = new ProficiencyLevel({
      id: level
    })
    return pl
  }

  public onClick(proficiencyLevel: number, count: number): void {
    if (count === 0) {
      return;
    }

    const level = this.findProficencyLevel(proficiencyLevel)
    const levelName = level.name

    if (window.innerWidth < SIZE_MIN_MD_SCREEN) {
      this.router.navigate(['my-elements'], {
        state: { title: proficiencyLevel },
        queryParams: { levelName }
      })
    } else {
      this.router.navigate(['home'], {
        state: { title: proficiencyLevel },
        queryParams: { levelName }
      })
    }

  }

  public loadCompetencyReports() {
    this.competencyReportingQuery.select().subscribe((data) => {
      if (data?.dto?.elements) {
        this.competencyReporting = data?.dto;
        data?.dto?.elements.map((element) => {
          if (element.pl == 80) this.elementNoneCount += 1
        })
      }
      this.fillDataPage();
    })
  }

  public navigateTo() {
    this.router.navigate(['my-elements']);
  }

  public fillDataPage() {
    this.dataset = [
      this.competencyReporting?.elementBasicCount,
      this.competencyReporting?.elementIntermediateCount,
      this.competencyReporting?.elementAdvancedCount,
      this.competencyReporting?.elementExpertCount
    ];

    this.proficiencyLevels = [
      {
        proficiencyLevel: 81,
        count: this.competencyReporting?.elementBasicCount
      },
      {
        proficiencyLevel: 82,
        count: this.competencyReporting?.elementIntermediateCount
      },
      {
        proficiencyLevel: 83,
        count: this.competencyReporting?.elementAdvancedCount
      },
      {
        proficiencyLevel: 84,
        count: this.competencyReporting?.elementExpertCount
      },

    ];

    const labels = ['Basic', 'Intermediate', 'Advanced', 'Expert'];

    const backgroundColors = [
      COLORS_PL.basic,
      COLORS_PL.intermediate,
      COLORS_PL.advanced,
      COLORS_PL.expert,
      COLORS_PL.none
    ];

    this.labels = labels;
    this.backgroundColors = backgroundColors;

    const data = {
      datasets: [{
        data: this.dataset,
        backgroundColor: backgroundColors,
        borderWidth: 2,
      }],
      labels
    };

    if (this.dataset[0] != undefined) {
      this.sum = this.dataset.reduce((a, b) => a + b, 0);
    }

    if (this.sum < 10 && this.sum > 0) this.sum = '0' + this.sum;

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 65,
      animation: {
        animateScale: true,
        animateRotate: true
      },
      legend: {
        position: 'right',
        display: false
      },
      elements: {
        center: {
          text: this.sum,
          color: '#39414D',
          fontStyle: 'Slb Sans Light',
          sidePadding: 20
        }
      }
    };

    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    const ctxChart = canvas.getContext('2d');

    const myDoughnutChart = new Chart(ctxChart, {
      type: 'doughnut',
      data,
      options
    });
  }
}
