<header>
    <div class="unit-status" *ngIf="group">
        <dl class="unit-data">
            <dt class="fontSize6">Status:</dt>
            <dd class="fontSize4">{{ group.groupResult }}</dd>
        </dl>
    </div>
</header>
<div class="container" *ngIf="group">
    <h3>Requirements</h3>
    <div *ngFor="let requirement of group.requirements; last as lst;">
        <pcp-promotion-requirement [requirement]="requirement" [last]="lst" (requirementClick)="viewRequirement($event)">
        </pcp-promotion-requirement>
    </div>
</div>
