<section [ngClass]="{'container': true, 'last': last}" (click)="viewCompetencyElement()">
    <dl class="competency-element-data">
        <div class="col-3">
            <dd class="fontSize2">{{competencyElement.name}}</dd>
        </div>
        <div class="col-2">
            <dd class="fontSize5">{{competencyDescription}}</dd>
            <dd class="fontSize5 points">{{pointsDescription}}</dd>
        </div>
    </dl>
</section>
