<div class="leading">
  <ng-content select="[itemLeading]"></ng-content>
</div>

<div class="content">
  <p class="fontSize2">
    <ng-content select="[itemTitle]"></ng-content>
  </p>
  <small>
    <ng-content select="[itemDescription]"></ng-content>
  </small>
</div>

<div class="trailing">
  <svg *ngIf="showArrow" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.9" d="M0.8 0L0 0.888889L6.32002 8L0 15.1111L0.8 16L8 8L0.8 0Z" fill="#99A6B5" />
  </svg>
</div>
