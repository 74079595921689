import { Component } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';

@Component({
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends PageComponentBase {
  public constructor(
    pullToRefreshService: PullToRefreshService
  ) {
    super(pullToRefreshService);
  }
}
