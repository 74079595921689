export class Configuration {
  public apiUrl: string;
  public newApiUrl: string;
  public apiUrlEsm: string;
  public graphApi: string;
  public apiKey: string;
  public apiAuthenticationTokenUrl: string;
  public apiSecret: string;
  public intouchApiKey: string;
  public intouchApiSecret: string;
  public intouchApiUrl: string;
  public intouchBaseUrl: string;
  public azureTenantId: string;
  public azureClientId: string;
  public instrumentationKey: string;
  public feedbackEmail: string;
  public version: string;
  public apiScope: string;
  public isUnderMaintenance: boolean;
  public newApiKey: string;

  // CR1497
  public ilearnUrlPrefix: string;
  // PBI-KAIZEN:1776090
  public intouchFaqUrl: string;
  // Esm-Ticket
  public esmAuthUrl: string;
  public esmKey: string;
  public esmSecret: string;
  public esmUsername: string;
  public esmPassword: string;
  public esmApiScope: string;
  public esmServiceOffering: string;
  public linkEsmFeedback: string;

  public apiUrlAdminTool:string;
  public env: string;
}
