<mat-accordion *ngIf="pcpRequirements">
  <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="panel-expiration">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2 class="title">Needed for Next Level</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container>
      <div class="requirement-progress">
        <div class="requirement-progress-item" *ngFor="let element of pcpRequirements"
          (click)="detail(element)">

          <small class="name">{{ element.pathwayRequirement }}</small>

          <pcp-requirement-progress [total]="element.quantityRequired" [current]="element.quantityCompleted">
          </pcp-requirement-progress>

        </div>
      </div>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
