export enum ProficiencyLevelType {
  basic = 'Basic',
  intermediate = 'Intermediate',
  advanced = 'Advanced',
  expert = 'Expert'
}

export enum ProficiencyLevelTypeNumber {
  rejected = -1,
  null = 0,
  none = 80,
  basic = 81,
  intermediate = 82,
  advanced = 83,
  expert = 84
}
