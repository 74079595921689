<div class="content">
  <div class="logo">
    <img src="/assets/images/slb-logo-white.svg" alt="Logo">
    <h3>Competency System</h3>
  </div>
</div>

<div class="shape">
  <div class="left">
    <img src="/assets/images/login/left.svg" alt="Left background">
  </div>

  <div class="center">
  </div>

  <div class="right">
    <img src="/assets/images/login/right.svg" alt="Right background">
  </div>
</div>

<div class="button-container">
  <a class="button" (click)="login()">
    <span class="fontSize3">Login</span>
  </a>
</div>
