import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as sortBy from 'lodash/sortBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenQuery } from '~/store/token/token.query';
import { Configuration } from '../models/configuration.model';
import { ElementAssessment } from '../models/element-assessment.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { SourceAssessmentDto } from './dtos/source-assessment.dto';

@Injectable({ providedIn: 'root' })
export class SourceAssessmentService  extends CompetencyApiBaseService<SourceAssessmentDto> {

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
  ) {
    super(httpClient, config, token);
    this.endpoint = 'source-assessments';
  }

  public getByGinNumberAndCompetencyElementAndDateAndAssessmentTypeAndProficiencyLevel(
    ginNumber: number,
    competencyElementName: string,
    fromDate: string,
    assessmentType: string,
    targetProficiencyLevel: string): Observable<Array<ElementAssessment>> {

    return this.getCustom(`${this.config.apiUrl}/${this.endpoint}?gin=${ginNumber}&assesseeGin=${ginNumber}&competencyElement=${this.encode(competencyElementName)}&fromDate=${fromDate}&assessmentType=${this.encode(assessmentType)}&targetProficiencyLevel=${this.encode(targetProficiencyLevel)}`)
      .pipe(
        map((items) => items.map((i) => new ElementAssessment(
          i.assessmentType,
          i.assessmentDate,
          i.sourceSystem,
          i.sourceAssessmentId,
          i.completionStatus
        ))),
        map((items) => sortBy(items, 'pass desc')));
  }

  public getByGinNumberAndCompetencyElementAndDate(
    ginNumber: number,
    competencyElementName: string,
    fromDate: string): Observable<Array<ElementAssessment>> {

    return this.getCustom(`${this.config.apiUrl}/${this.endpoint}?gin=${ginNumber}&assessorGin=${ginNumber}&competencyElement=${this.encode(competencyElementName)}&fromDate=${fromDate}`)
      .pipe(
        map((items) => items.map((i) => new ElementAssessment(
          i.assessmentType,
          i.assessmentDate,
          i.sourceSystem,
          i.sourceAssessmentId,
          i.completionStatus
        ))),
        map((items) => sortBy(items, 'pass desc')));
  }
}
