import { Component, Input, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ElementAssessment } from '~/shared/models/element-assessment.model';
import { SourceAssessmentService } from '~/shared/services/source-assessment.service';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'pcp-element-assessment',
  templateUrl: './element-assessment.component.html',
  styleUrls: ['./element-assessment.component.scss']
})
export class ElementAssessmentComponent implements OnInit {

  private ginNumber: number;
  @Input() public competencyElementName: string;
  @Input() public fromDate: string;
  @Input() public assessmentType: string;
  @Input() public targetProficiencyLevel: string;
  public items: Array<ElementAssessment>;
  public isLoading = false;

  public constructor(
    private userQuery: UserQuery,
    private sourceAssessmentService: SourceAssessmentService,
  ) {}

  private loadData(): Observable<any> {
    this.isLoading = true;
    if (this.assessmentType) {
      return this.sourceAssessmentService
        .getByGinNumberAndCompetencyElementAndDateAndAssessmentTypeAndProficiencyLevel(
          this.ginNumber,
          this.competencyElementName,
          this.fromDate,
          this.assessmentType,
          this.targetProficiencyLevel)
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            return throwError(err)
          }),
          tap((items) => this.items = items));
    } else {
      return this.sourceAssessmentService
        .getByGinNumberAndCompetencyElementAndDate(this.ginNumber, this.competencyElementName, this.fromDate)
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            return throwError(err)
          }),
          tap((items) => this.items = items));
    }
  }

  public ngOnInit(): void {
    this.ginNumber = this.userQuery.getValue().user.ginNumber;

    this.loadData()
      .subscribe(() => this.isLoading = false);
  }

}
