import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { config } from './assets/config.js';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { MsalConfig } from './app/configs/msal-config';
import { Configuration } from './app/shared/models/configuration.model';

if (environment.production) {
  enableProdMode();
}

// fetch('/config.json')
//   .then((response) => response.json())
//   .then((config) => {
    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE,
        useValue: MsalConfig.getMsalInstanceConfig(config)
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: MsalConfig.getMsalConfig()
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: MsalConfig.getMsalInterceptorConfig(config)
      },
      {
        provide: Configuration,
        useValue: config
      }
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));

