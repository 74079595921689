import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pcp-assessor-detail',
  templateUrl: './assessor-detail.component.html',
  styleUrls: ['./assessor-detail.component.scss']
})
export class AssessorDetailComponent implements OnInit {

  @Input() public name:string;
  @Input() public unit:string;
  @Input() public job:string

  constructor() { }

  public ngOnInit(): void {
  }

}
