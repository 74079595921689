<ul class="list">

  <li class="search-container" [class.invisible]="!enableSearch">
    <input id="search-field" class="field" type="text" [placeholder]="searchPlaceholder" #inputSearch />

    <div class="icon-wrapper">
      <img src="/assets/icons/search.svg" />
    </div>
  </li>

  <li class="list-item" *ngFor="let item of items; index as i">

    <ng-container *ngIf="templateRef">
      <ng-template *ngTemplateOutlet="templateRef; context: { $implicit: item }"></ng-template>
    </ng-container>

    <ng-container *ngIf="!templateRef">
      {{ item.name }}
    </ng-container>

    <div class="item-separator" *ngIf="i < (items.length - 1)"></div>
  </li>

</ul>
