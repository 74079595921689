<div class="content-item">
  <div class="container-title">
    <div class="title">
      <span>{{competencyElement.competencyUnit}}</span>
      <span>{{competencyElement.competencyElement}}</span>
    </div>
  </div>
  <div class="points">

    <pcp-display-proficiency-level
      [competencyElement]="competencyElementPromotion"
      [requirementType]="requirementTypeComplete"
      [inputPlTarget]="proficiencyLevel.name">
    </pcp-display-proficiency-level>

  </div>
</div>
