import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Target } from '~/shared/models/target.model';

export interface ITargetState extends EntityState<Target>  {
  target: Target
}

export function createInitialState(): ITargetState {
  return {
    target: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'target' })
export class TargetStore extends EntityStore<ITargetState> {
  constructor() {
    super(createInitialState());
  }
}
