import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/internal/operators/map';

@Injectable({ providedIn: 'root' })
export class PageDataService {

  private customHistory: Array<string> = [];
  private historyMaxLength = 5;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.keepHistoryState();
  }

  private addToHistory(url: string): void {
    if (this.customHistory.length === this.historyMaxLength) {
      this.customHistory.splice(0, 1);
    }

    this.customHistory.push(url);
  }

  private keepHistoryState(): void {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      filter((e: NavigationStart) => e.navigationTrigger === 'popstate')
    )
      .subscribe((x: NavigationStart) => {
        this.router.getCurrentNavigation().extras.state = { ...x.restoredState, navigationId: x.id };
      });
  }

  public get(): Observable<Data> {
    return this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(({ urlAfterRedirects }: NavigationEnd) => {
          this.addToHistory(urlAfterRedirects);
          let route = this.activatedRoute;

          while (route.firstChild) {
            route = route.firstChild;
          }

          return Object.assign({}, route.snapshot.data, history.state);
        })
      );
  }

  public getPreviousUrl(): string {
    return this.customHistory[this.customHistory.length - 2];
  }
}
