<div *ngIf="trend$" class="compliance-wrapper" [class.page]="!isHome"
  [ngStyle]="{'flex-direction': isHome ? 'column' : 'row' }">

  <ng-container>
    <!-- <compliance-user-trend-header *ngIf="isHome" [isHome]="isHome"></compliance-user-trend-header> -->
    <div *ngIf="!isHome" class="container-user-compliance">
      <compliance-user-trend-header [isHome]="isHome"></compliance-user-trend-header>
      <compliance-user-trend-chart  class="desktop-trend" [mobile]="useMobileSize" [hidden]="useMobileSize"></compliance-user-trend-chart>
    </div>
  </ng-container>

  <div *ngIf="!isHome" [hidden]="!useMobileSize" class="container">
    <div class="compliance-trend-wrapper">
      <compliance-user-trend-chart class="mobile-trend" [mobile]="useMobileSize"></compliance-user-trend-chart>
    </div>
  </div>

  <section *ngIf="isHome" [ngClass]="{'width-change': !isHome}">
    <div class="container">
      <div class="button-container">
        <div *ngIf="waitingComplianceTargetsResponse; then complianceTargetsLoading else complianceTargetsTemplate"></div>
        <ng-template #complianceTargetsLoading>
          <pcp-loading-dots></pcp-loading-dots>
        </ng-template>

        <ng-template #complianceTargetsTemplate>

          <compliance-average-progress-bar *ngIf="competencyCompliances !== undefined && competencyCompliances.length > 0" (click)="navigateToCompliance()" ></compliance-average-progress-bar>

          <compliance-targets-average-progress-bar *ngIf="targetsV2 && targetsV2.disciplines && targetsV2.disciplines.length > 0" (click)="navigateToYourTargets()"></compliance-targets-average-progress-bar>

        </ng-template>
      </div>
    </div>
  </section>

  <div *ngIf="!isHome" class="container">

    <div *ngIf="filteredCompetencyCompliances" class="container-wrapper">

      <div *ngFor="let competency of filteredCompetencyCompliances" class="container-detail rowSelectable"
        [class.rowOpacity]="showDetail && showDetail.competencyElement !== competency.competencyElement"
        [class.rowSelected]="showDetail && showDetail.competencyElement === competency.competencyElement">

        <pcp-compliance-item-list [competencyCompliance]="competency"
          (onClickCard)="showElementsDetail($event)">
        </pcp-compliance-item-list>

        <pcp-my-elements-detail *ngIf="isMobileSize && showDetail && showDetail.competencyElement === competency.competencyElement"
          [competencyElement]="onDetail">
        </pcp-my-elements-detail>

      </div>
    </div>
  </div>

  <pcp-competency-detail *ngIf="!isHome"></pcp-competency-detail>
</div>

<div *ngIf="!isHome && !trend$">
  <pcp-no-data-found></pcp-no-data-found>
</div>
