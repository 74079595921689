<mat-accordion *ngIf="pcpRequirements">
  <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
    class="panel-expiration">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h2 class="title">Needed to Keep Current Level</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container>
      <div class="requirement-progress-item" *ngFor="let element of pcpRequirements" (click)="detail(element)">

        <span class="name">{{ element.pathwayRequirement }}</span>

        <pcp-requirement-progress class="graph" [total]="element.quantityRequired"
          [current]="element.quantityCompleted">
        </pcp-requirement-progress>

        <pcp-info-item label="Remaining" value="{{ element.expiresOn }} days" size="S" *ngIf="element.expiresOn">
          <ng-template>
            <ng-container>
              <pcp-icon-days-alert [showDefault]="true" [daysRemaining]="element.expiresOn">
              </pcp-icon-days-alert>
            </ng-container>
          </ng-template>
        </pcp-info-item>
      </div>

    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
