<div class="content">
  <h2 class="text first">Ooops...</h2>

  <img src="/assets/icons/no-idea.svg" alt="Confused man icon" />

  <h2 class="text second">Sorry, we’ve had a server error. <br />Please, try again.</h2>

  <a class="button" (click)="onClick()">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.1 2.89999C15.3 1.09999 12.7 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18.5C18.5 14.7 14.7 18.5 10 18.5C5.3 18.5 1.5 14.7 1.5 10C1.5 5.3 5.3 1.5 10 1.5C12.3 1.5 14.4 2.4 16 4L13 7H18.2H20V5.3V0L17.1 2.89999Z"
        fill="#003366" />
    </svg>
    <span class="fontSize3">Retry</span>
  </a>
</div>
