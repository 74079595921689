import { TokenStore } from './../../store/token/token.store';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from '~/shared/models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private httpClient: HttpClient,
    private tokenStore: TokenStore,
    private config: Configuration,
  ) {
  }

  public async getToken(): Promise<any> {
    const authorizationData = 'Basic ' + btoa(`${this.config.apiKey}:${this.config.apiSecret}`);
    const headers = new HttpHeaders({ Authorization: authorizationData });
    try {
      const token: any = await this.httpClient.post(
        `${this.config.apiAuthenticationTokenUrl}/token?grant_type=client_credentials`,
        {},
        { headers })
        .toPromise();
      this.tokenStore.update({ token: token.access_token });
      return token.access_token;
    } catch (error) {
      throw error
    }
  }

  public async getTokenEsm(): Promise<any> {
    const authorizationData = 'Basic ' + btoa(`${this.config.esmKey}:${this.config.esmSecret}`);
    const headers = new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      Authorization: authorizationData
    })
    const body: any = {
      grant_type : 'password',
      username : this.config.esmUsername,
      password : this.config.esmPassword
    }

    try {
      const token: any = await this.httpClient.post(
        this.config.esmAuthUrl,
        body,
        { headers })
        .toPromise();
      this.tokenStore.update({ token: token.esmToken });
      return token.esmToken;
    } catch (error) {
      throw error
    }
  }

  public async getTokenIntouch(): Promise<any> {
    const authorizationData = 'Basic ' + btoa(`${this.config.intouchApiKey}:${this.config.intouchApiSecret}`);
    const headers = new HttpHeaders({ Authorization: authorizationData });
    try {
      const token: any = await this.httpClient.post(
        `${this.config.apiAuthenticationTokenUrl}/token?grant_type=client_credentials`,
        {},
        { headers })
        .toPromise();
      this.tokenStore.update({ intouchToken: token.access_token });
      return token.access_token;
    } catch (error) {
      throw error
    }
  }
}
