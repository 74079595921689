import { PromotionDto } from '../services/dtos/promotion.dto';
import { PromotionGroup } from './promotion-group.model';

// export class Promotion {
//     public currentGrade: string;
//     public gin: number
//     public nextGrade: string;
//     public organization: string;
//     public passOverall: boolean;
//     public personName: string;
//     public promotionGroups: Array<PromotionGroup>;

//     constructor(data: PromotionDto) {
//         this.currentGrade = data.currentGrade;
//         this.nextGrade = data.nextGrade;
//         this.promotionGroups = data.promotionGroups.map((pgDto) => new PromotionGroup(pgDto));
//     }

//     get allComplete(): boolean {
//         return this.promotionGroups.every((g) => g.promoGroupPassed);
//     }
// }

export class Promotion {
  gin: string;
  ldap: string;
  name: string;
  jobCode: string;
  jobCodeDescription: string;
  jobDescription: string;
  requesterGin: string;
  trigger: string;
  geoCode: string;
  geounit: string;
  businessCode: string;
  businessLine: string;
  grade: string;
  promotionResult: string;
  stepId: number;
  stepName: string;
  promotionGroups: Array<PromotionGroup>;

  constructor(data?: Partial<Promotion>) {
    Object.assign(this, data);
  }
}
