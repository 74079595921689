import { RequirementType } from '../constants/requirement-type';
import { PromotionCompetencyElementDto } from '../services/dtos/promotion-competency-element-dto';
import { PromotionRequirementDto } from '../services/dtos/promotion-requirement-dto';
import { CompetencyElement, PromotionCompetencyElement } from './competency-element.model';
import { CompetencyException } from './competency-exception.model';
import { ProficiencyLevel } from './proficiency-level.model';

// export class PromotionRequirement {
//     public requirement: string;
//     public requirementType: RequirementType;
//     public achievedCount: number;
//     public neededCount: number;
//     public competencyElements: Array<CompetencyElement>;
//     public level: ProficiencyLevel;

//     constructor(data: PromotionRequirementDto) {
//         this.requirement = data.requirement;
//         this.requirementType = data.requirementType as RequirementType;
//         this.achievedCount = data.achievedCount;
//         this.neededCount = data.neededCount;
//         this.setCompetencyElements(data.competencyElements);
//         this.setLevel();
//     }

//     private setCompetencyElements(competencyElements: PromotionCompetencyElementDto[]) {
//         this.competencyElements = competencyElements.map((ceDto) => {
//             const ce = new CompetencyElement();
//             ce.awardedOnDate = ceDto.awardedOnDate;
//             ce.expirationDate = ceDto.expirationDate;
//             ce.id = ceDto.competencyElementId;
//             ce.name = ceDto.competencyElement;
//             ce.proficiencyLevel = new ProficiencyLevel();
//             ce.proficiencyLevel.setByName(ceDto.pcpProficiencyLevel);
//             ce.promoProficiencyLevel = new ProficiencyLevel();
//             ce.promoProficiencyLevel.setByName(ceDto.promoCalcProficiencyLevel);
//             ce.promotionValue = ceDto.promotionValue;

//             ce.exceptions = ceDto.exceptions.map((exDto) => {
//                 const ex = new CompetencyException();
//                 ex.competencyLevelUpException = exDto.competencyLevelUpException;
//                 ex.levelUpAcquiredCount = exDto.levelUpAcquiredCount;
//                 ex.levelUpNeededCount = exDto.levelUpNeededCount;
//                 return ex;
//             });
//             ce.levelPoints = ceDto.levelPoints;
//             return ce;
//         });
//     }

//     private setLevel(): void {
//         this.level = new ProficiencyLevel();
//         if (this.requirement.toLowerCase().includes('at basic')) {
//             this.level.setByName('Basic');
//         } else if (this.requirement.toLowerCase().includes('at intermediate')) {
//             this.level.setByName('Intermediate');
//         } else if (this.requirement.toLowerCase().includes('at advanced')) {
//             this.level.setByName('Advanced');
//         } else if (this.requirement.toLowerCase().includes('at expert')) {
//             this.level.setByName('Expert');
//         } else {
//             this.level.setByName('None');
//         }
//     }
// }

export class PromotionRequirement {
  promotionRequirementId: number;
  requirement: string;
  isReqrCompleted: boolean;
  requirementResult: string;
  requirementType: string;
  requiredCount?: number;
  requiredMaximum?: number;
  pointsAchieved: number;
  cecount: number;
  requiredLevel: string;
  competencyElements: Array<PromotionCompetencyElement>;
  requiredPoints?: number;
  achievedCeCount: number;

  constructor(data?: Partial<PromotionRequirement>) {
    Object.assign(this, data);
  }
}
