import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedModule } from '~/shared/shared.module';
import { PromotionGroupComponent } from './components/promotion-group/promotion-group.component';
import { PromotionPointsCEComponent } from './components/promotion-points-ce/promotion-points-ce.component';
import { PromotionRequirementCEComponent } from './components/promotion-requirement-ce/promotion-requirement-ce.component';
import { PromotionRequirementComponent }
  from './components/promotion-requirement/promotion-requirement.component';
import { PromotionUserHeaderComponent } from './components/promotion-user-header/promotion-user-header.component';
import { PromotionPointsAchievedComponent } from './pages/promotion-points-achieved/promotion-points-achieved.component';
import { PromotionRequirementsCEsComponent } from './pages/promotion-requirements-ces/promotion-requirements-ces.component';
import { PromotionRequirementsComponent }
  from './pages/promotion-requirements/promotion-requirements.component';
import { PromotionComponent } from './pages/promotion/promotion.component';
import { PromotionRoutingModule } from './promotion-routing.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { PromotionItemListComponent } from './components/promotion-item-list/promotion-item-list.component';
import {MatDividerModule} from '@angular/material/divider';
import { CompetencyUnitsModule } from '../competency-units/competency-units.module';
import { RequirementSeparatorComponent } from './components/requirement-separator/requirement-separator.component';
import { NoDataFoundModule } from '../no-data-found/no-data-found.module';

const MODULE_COMPONENTS = [
  PromotionUserHeaderComponent,
  PromotionComponent,
  PromotionGroupComponent,
  PromotionRequirementsComponent,
  PromotionRequirementComponent,
  PromotionRequirementsCEsComponent,
  PromotionRequirementCEComponent,
  PromotionPointsAchievedComponent,
  PromotionPointsCEComponent,
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  PromotionRoutingModule,
  NgCircleProgressModule.forRoot(),
  MatExpansionModule,
  MatDividerModule,
  SharedModule,
  CompetencyUnitsModule,
  NoDataFoundModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS,
    PromotionItemListComponent,
    RequirementSeparatorComponent,

  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class PromotionModule { }
