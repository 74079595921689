import { TargetStatus } from '~/shared/models/target-status.model';
import { TargetStatusQuery } from './../../../store/target-status/target-status.query';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TargetService } from '~/shared/services/target.service';
import { getClassProgress } from '~/shared/utils/class-progress';
import { isLocalhost } from '~/shared/utils/navigator';
import { MatDialog } from '@angular/material/dialog';
import { DialogAssessorsComponent } from '../assessors/dialog-assessors/dialog-assessors.component';

@Component({
  selector: 'pcp-target-status',
  templateUrl: './target-status.component.html',
  styleUrls: ['./target-status.component.scss']
})
export class TargetStatusComponent implements OnInit {

  @Input() public viewMore = true;
  @Input() public back = false;

  public targetDefault: TargetStatus = {
    objectiveStatus: 0,
    certificationCompilance: 0
  };
  public target$: TargetStatus = this.targetDefault;

  constructor(
    private router: Router,
    private targetService: TargetService,
    private targetStatusQuery: TargetStatusQuery,
    public dialog:MatDialog
  ) {
    //
  }

  get showTarget(): boolean {
    return this.target$ && this.target$.certificationCompilance > 0 && this.target$.objectiveStatus > 0
  }

  public ngOnInit(): void {
    this.loadData()
  }

  public loadData(): void {
    this.targetStatusQuery.select('targetStatus').subscribe((data) => {
      this.target$ = data;
    });
  }

  public navigateToTargets() {
    const dialogRef = this.dialog.open(DialogAssessorsComponent,{
      width: '600px'
    }
    )
    // this.router.navigate(['targets']);
  }

  public navigateToHome() {
    this.router.navigate(['home']);
  }

  public getClassProgressByValue(value: number): string {
    return getClassProgress(value)
  }

}
