<div [class.hidden]="keys.length === 0" class="select-filter-container">

  <mat-select placeholder="Filter by" *ngIf="!hasSelectedKey" [contentEditable]="true" [(value)]="selectedKey"
    spellcheck="false">
    <mat-option *ngFor="let i of keys" [value]="i">
      {{getLabel(i)}}
    </mat-option>
  </mat-select>

  <div *ngIf="hasSelectedKey" class="container-value">
    <span class="selected-key" *ngIf="hasSelectedKey">{{getLabel(selectedKey)}}:</span>

    <mat-form-field floatLabel="never" class="mat-form-dynamic-filter">
      <input type="text" placeholder="Value" matInput [formControl]="selectedValue" [matAutocomplete]="auto">
      <mat-autocomplete (optionSelected)="valueSelected()" #auto="matAutocomplete">
        <mat-option *ngFor="let i of filteredOptions | async" [value]="i">
          {{i}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </div>

  <mat-icon aria-hidden="false" aria-label="Clear" (click)="clear();">close</mat-icon>

</div>
