import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenQuery } from '~/store/token/token.query';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { Configuration } from '../models/configuration.model';

@Injectable({ providedIn: 'root' })
export class AssessorService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
  ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/qualified-assessors';
  }

  public getAssessors(): Observable<any> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?GinNumber=1&CompetencyElementId=9494`)
      .pipe(tap((dto: any) => {
        if (dto) {
          return dto;
        }
        return null;
      }));
  }
}
