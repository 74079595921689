<ng-container *ngIf="user$">
	<section *ngIf="trend$">
		<section *ngIf="isHome" class="section-default">
			<figure class="compliance-home-icon">
				<mat-icon>line_axis</mat-icon>
			</figure>
			<div class="container-compliance-home-header">
				<h4>Competency Trends</h4>
			</div>
		</section>
		<section *ngIf="!isHome">
			<section *ngIf="!isHome" class="section-blue content">
				<div class="header">
					<img src="assets/icons/arrow_white.svg" (click)="navigateToHome()" class="header-image" />
					<h1>Trend</h1>
				</div>
			</section>
		</section>
	</section>

	<section *ngIf="!trend$">
		No Compliance Data Found
	</section>
</ng-container>
