import { Component, Input, OnInit,ViewEncapsulation  } from '@angular/core';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { CompetencyElement, DAYS_NONE, PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { Competency } from '~/shared/models/competency-repoting.model';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';

@Component({
  selector: 'pcp-card-item-list',
  templateUrl: './card-item-list.component.html',
  styleUrls: ['./card-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardItemListComponent implements OnInit {

  public daysNone = DAYS_NONE;
  @Input() public proficiencyLevel: ProficiencyLevel;
  @Input() public requirementLevel: ProficiencyLevel = null;
  @Input() public hasIconStatus = false;
  @Input() public status = false;
  @Input() public daysRemaining:Date;
  @Input() public showDetail: CompetencyElement

  @Input() public requirementType: string;
  @Input() public competencyElement: PromotionCompetencyElement;
  @Input() public plTarget: string;
  @Input() public competency: Competency;
  @Input() public competencyTarget: ProficiencyLevel;


  public allCardsClosed: boolean
  public requirementStatus = 0;
  @Input() public competencyUnits = new Array<CompetencyElement>();
  @Input() public selected = false
  public today = new Date()
  public resultDays:number

  constructor() {
    //
  }

  public ngOnInit() {


    if (!this.requirementType && this.competency) {

      this.status = this.proficiencyLevel?.id >= this?.competencyTarget?.id;
      this.competencyElement = new PromotionCompetencyElement();
      this.competencyElement.name = this.competency.competencyElement;
      this.competencyElement.competencyUnit = this.competency.competencyUnit;

      if(this.daysRemaining){
        const daysRemainingDate = new Date(this.daysRemaining)
        const diff = (daysRemainingDate?.getTime() - this.today?.getTime());
        this.resultDays = Math.floor(diff/(1000*60*60*24));
      }
    }
  }

  get tooltip(): string {
    return `${this.resultDays} days remaining`;
  }

}
