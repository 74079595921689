import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsOnlineGuard } from '~/core/guards/is-online.guard';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { IsAuthenticatedGuard } from '~/core/guards/is-authenticated.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: FeedbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackRoutingModule { }
