import { Query } from '@datorama/akita';
import { ITrendState, TrendStore } from './trend.store';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TrendQuery extends Query<ITrendState> {
  public trend$ = this.select((state) => state.trend);

  constructor(protected store: TrendStore) {
    super(store);
  }
}
