<div class="container">
  <header>
    <h2 mat-dialog-title>Pcp History Timeline</h2>
    <figure (click)="close()">
      <img src="assets/icons/close-dialog.svg" alt="close">
    </figure>
  </header>

  <mat-divider></mat-divider>

  <div *ngIf="this.history" class="timeline-container">
    <pcp-history-timeline [history]="this.history"></pcp-history-timeline>
  </div>
</div>
