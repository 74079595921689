import { ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { allowedGins } from './configs/dev-allowed-gins-config';
import { AppInsightsService } from './core/services/app-insights.service';
import { AuthService } from './core/services/auth.service';
import { LoaderService } from './core/services/loader.service';
import { PageDataService } from './core/services/page-data.service';
import { TokenService } from './core/services/token.service';
import { PullToRefreshComponent } from './shared/components/pull-to-refresh/pull-to-refresh.component';
import { Configuration } from './shared/models/configuration.model';
import { CompetencyReportingService } from './shared/services/competency-reporting.service';
import { PromotionService } from './shared/services/promotion.service';
import { UserGraphService } from './shared/services/user-graph.service';
import { EnvironmentService } from './shared/services/environment.service';
import { UserQuery } from '~/store/user/user.query';
import { MyTrendsService } from './shared/services/my-trends.service';
import { TargetService } from './shared/services/target.service';

@Component({
  selector: 'pcp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public pageTitle: string;
  public isHome = false;
  public showBackButton;
  public showHomeButton;
  public showFooterMenu;
  public showHeader;
  public disablePullToRefresh;
  public hideOfflineNotification;
  public finishedFirstLoading = false;
  public allComplete = false;

  @ViewChild('pullToRefresh') public pullToRefresh: PullToRefreshComponent;
  @ViewChild('main') public main: ElementRef;

  constructor(
    private pageDataService: PageDataService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private injector: Injector,
    private broadcastService: MsalBroadcastService,
    private promotionService: PromotionService,
    private userGraphService: UserGraphService,
    private competencyReportingService: CompetencyReportingService,
    private tokenService: TokenService,
    private configuration: Configuration,
    private environmentService: EnvironmentService,
    private trendsService: MyTrendsService,
    private targetsService: TargetService,
    private userQuery: UserQuery
  ) {
    //
  }

  private msalObservable() {
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None && this.authService.msalInstance.getAllAccounts().length > 0
          ),
        takeUntil(this._destroying$),
        catchError((err) => null)
      )
      .subscribe((data) => {
        this.authService.checkAndSetActiveAccount();
        this.authService.registerRedirectCallback()
          .subscribe(() => this.firstLoad());
      });
  }

  private setIsHome() {
    this.changeDetector.detectChanges();
  }

  private userCanceledAADLogin(): boolean {
    return window.location.href.indexOf('error=access_denied') >= 0;
  }

  private firstLoad(): void {
    const isFirstLoad = this.authService.isAuthenticated() && window.location.href.indexOf('access_token') < 0;

    if (!isFirstLoad) {
      return;
    }

    this.loaderService.enqueue();

    const appInsights = this.injector.get(AppInsightsService);
    appInsights.setUserId(this.authService.getEmail());

    /* TODO: Remove after tests */
    // const isDev = (location.href.indexOf('dev') >= 0 || location.href.indexOf('localhost') >= 0);
    const isDev = true;

    if (isDev) {
      const gins = [1014471, 6479364, 1056407, 1002203];
      const randomGin = gins[Math.floor(Math.random() * gins.length)];
      const loggedGin = randomGin;

      if (!loggedGin) {
        this.authService.setGinNumber(loggedGin);
      }

      if (allowedGins.includes(loggedGin)) {
        const gin = loggedGin.toString();
        if (gin) {
          this.authService.setGinNumber(Number(gin));
          this.loadPCP(Number(gin), isDev);
        } else {
          this.loadPCP(Number(gin), isDev);
        }
      } else {
        this.loadPCP(Number(loggedGin), isDev);
      }
    }
  }

  private logoutAndGoToLogin() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  private async loadPCP(gin?: number, isDev?: boolean): Promise<void> {
    this.trendsService.getTrends().subscribe();
    this.targetsService.getTargetsV2()
    this.environmentService.setEnvironment = isDev;
    this.competencyReportingService.getReporting(Number(gin), isDev)
      .pipe(catchError((error) => {
        this.finishedFirstLoading = true;
        this.loaderService.dequeue();

        throw throwError(error);
      })).subscribe((pcps) => {
        if (pcps?.elements?.length === 0) {
          this.router.navigateByUrl('/no-data-found');
        } else {
          this.promotionService.getByGinNumber(Number(gin)).subscribe((promo) => {
            if (promo) {
              this.allComplete = promo.promotionResult === 'success'; //TODO
            }
            this.finishedFirstLoading = true;
            this.loaderService.dequeue();
            this.router.navigateByUrl('/home');
          });
      }
      });
}

  private bindMsalEvents(): void {
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
  }

  public onTouchStart(event: any) {
    if (this.main.nativeElement.scrollTop === 0) {
      event.preparePullToRefresh = true;
    }
  }

  public async ngOnInit() {
    await this.authService.msalInstance.handleRedirectPromise();
    if (this.configuration.isUnderMaintenance === true) {
      this.router.navigateByUrl('under-maintenance');
      return;
    }
    this.msalObservable();
    this.bindMsalEvents();

    this.pageDataService.get().subscribe((
      {
        title,
        showBackButton,
        showHomeButton,
        showFooterMenu,
        showHeader,
        disablePullToRefresh,
        hideOfflineNotification
      }) => {
      this.pageTitle = title;
      this.setIsHome();
      this.showBackButton = showBackButton === undefined ? false : showBackButton;
      this.showHomeButton = showHomeButton === undefined ? false : showHomeButton;
      this.showFooterMenu = showFooterMenu === undefined ? true : showFooterMenu;
      this.showHeader = showHeader === undefined ? true : showHeader;
      this.disablePullToRefresh = disablePullToRefresh === undefined ? false : disablePullToRefresh;
      this.hideOfflineNotification = hideOfflineNotification === undefined ? false : hideOfflineNotification;
    });

    if (this.userCanceledAADLogin()) {
      this.router.navigateByUrl('/login');
      return;
    }
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  redirectErrorAuth(): void {
    this.router.navigateByUrl('/login/auth-denied');
  }
}
