<div class="first-group">
  <h1 class="no-data">No data found</h1>
  <img src="/assets/icons/folder.svg" alt="Empty folder icon" />
  <h1 class="nothing-to-show">We have nothing to show you here</h1>
</div>

<div class="second-group">
  <h3>It seems like you don’t have any data to access in “My PCP”.<br />
    Please, contact your supervisor for more details.</h3>
</div>
