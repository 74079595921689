import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../models/configuration.model';
import { TokenQuery } from '~/store/token/token.query';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { Observable } from 'rxjs';
import { CompetencyDetails, ElementDetails } from '../models/competency-details.model';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompetencyDetailsService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,

  ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/pcp-details'
  }

  public getDetails(competencyId: number): Observable<CompetencyDetails> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?CompetencyElementId=${competencyId}`)
      .pipe(tap((dto: CompetencyDetails) => {
        if (dto) {
          return dto;
        }
        return null;
      }));
  }

  public getElementDetails(
    ginNumber: number, levelId: string, elementId: number, pathway: string, pathwayType: string
  ): Observable<ElementDetails> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?GinNumber=${ginNumber}&ProficiencyLevelId=${levelId}&CompetencyElementId=${elementId}&Pathway=${pathway}&PathwayType=${pathwayType}`)
      .pipe(tap((dto: ElementDetails) => {
        if (dto) {
          return dto;
        }
        return null;
      }));
  }
}
