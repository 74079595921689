<div class="content-item" [ngStyle]="{'opacity': allCardsClosed  ? '0.25' : '1'}">
  <div class="container-title">

    <div class="title">
      <span>{{competencyElement.competencyUnit}}</span>
      <span>{{competencyElement.name}}</span>
    </div>

    <div  [matTooltip]="tooltip" matTooltipClass="custom-tooltip" matTooltipPosition="above"
      class="container-expiration">
      <span *ngIf="resultDays > 0" [class.d-none]="resultDays < daysNone" class="alert-days">
        {{resultDays}} Days
      </span>

      <pcp-icon-days-alert [daysRemaining]="resultDays"></pcp-icon-days-alert>
    </div>

  </div>

  <pcp-display-proficiency-level *ngIf="!competency"
    [competencyElement]="competencyElement"
    [requirementType]="requirementType"
    [inputPlTarget]="plTarget">
  </pcp-display-proficiency-level>

  <pcp-display-proficiency-level *ngIf="competency"
    [competencyElement]="competencyElement"
    [plTarget]="competencyTarget"
    [currentProficiencyLevel]="proficiencyLevel">
  </pcp-display-proficiency-level>

</div>
