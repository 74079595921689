<section class="container" (click)="viewRequirement()">
    <dl class="requirement-data">
        <div class="col-3">
            <dt class="fontSize2">
                <circle-progress class="circle-progress" [radius]="37" [space]="-10" [maxPercent]="100" [outerStrokeGradient]="true" [outerStrokeWidth]="9"
                outerStrokeColor="#003366" outerStrokeGradientStopColor="#003366" innerStrokeColor="#e8ecf2" [innerStrokeWidth]="9"
                titleFontSize="12px" titleFontWeight="900" [animateTitle]="false" [animationDuration]="300" [showSubtitle]="false"
                [showUnits]="false" [showBackground]="false" [startFromZero]="false" [percent]="currentPercentage"
                [title]="isPoints ? (pointsAchieved + '/' + requirement.requiredCount) : (currentPercentage + '%')">
              </circle-progress>
            </dt>
            <div class="description">
                <div>
                    <dd class="fontSize5">{{ requirementDescription }}</dd>
                    <div class="points" *ngIf="isRequirementTypePoints()">
                        <dd class="fontSize6"><img class="icon" src="/assets/icons/todo-list.svg" alt="Completed CEs icon" /> Completed CEs: {{completedCEs}}</dd>
                        <dd class="fontSize6"><img class="icon" src="/assets/icons/clock.svg" alt="Achieved Points icon" /> Achieved Points: {{ pointsAchieved }}</dd>
                    </div>
                </div>
            </div>
        </div>
    </dl>
</section>
<section class="operator-container" *ngIf="operator">
    <span class="line"></span>{{operator}}<span class="line"></span>
</section>
