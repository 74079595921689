import { ProficiencyLevel } from './proficiency-level.model';
import { ElementLevelInfo } from './element-level-info.model';
import { ElementExpiration } from './element-expiration.model';
import { CompetencyException } from './competency-exception.model';
import * as moment from 'moment-mini-ts';
import { ProficiencyLevelType } from '../enums/proficiency-level-type.enum';

export const DAYS_NONE = 99999999999;

export class CompetencyElement {
  public id: number;
  public name: string;
  public proficiencyLevel: ProficiencyLevel;
  public promoProficiencyLevel?: ProficiencyLevel;
  public competencyUnitName: string;
  public levelInfos: Array<ElementLevelInfo> = [];
  public expirations: Array<ElementExpiration> = [];
  public awardedOnDate: Date;
  public expirationDate?: Date;
  public exceptions: Array<CompetencyException>;
  public promotionValue: number;
  public showDetail: boolean;
  public proficiencyLevelName?: string;
  public levelPoints: {
    basic: number,
    intermediate: number,
    advanced: number,
    expert: number
  };

  constructor(data?: Partial<CompetencyElement>) {
    Object.assign(this, data);
  }

  get daysRemaining(): number {
    if (this.expirationDate) {
      const time = (this.expirationDate.getTime() - new Date().getTime());
      const days = Math.round(time / (1000 * 60 * 60 * 24));
      return days;
    }
    return DAYS_NONE;
  }

  get dateFormatted(): string {
    if(this.expirationDate){
      const dateFormatted =  moment(this.expirationDate).format('LL')
      return dateFormatted;
    }
    return ''
  }
}

export class PromotionCompetencyElement {
  ceId: number;
  name: string;
  completed: boolean;
  employeeProficiencyLevel: string;
  competencyUnitId: number;
  competencyUnit: string;
  points: Array<Point>;
  requiredProficiencyLevel: string;
  proficiencyLevel: string;
  userproficiencylevel?: number;
  ceResult: string;
  awardedOnDate: string;

  constructor(data?: Partial<PromotionCompetencyElement>) {
    Object.assign(this, data);
  }
}

export class Point {
  proficiencyLevelType: ProficiencyLevelType;
  cePoints: number;

  constructor(data?: Partial<Point>) {
    Object.assign(this, data);
  }
}
