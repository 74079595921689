import { CompetencyUnit } from '~/shared/models/competency-unit.model';
import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface ICompetencyUnitState {
  competencyUnits: Array<CompetencyUnit>
}

export function createInitialState(): ICompetencyUnitState {
  return {
    competencyUnits: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'competencyUnit' })
export class CompetencyUnitStore extends Store<ICompetencyUnitState> {
  constructor() {
    super(createInitialState());
  }
}
