import { RequirementType } from './../../../../shared/enums/requirement-type.enum';
import { Component, Input, Output, OnInit, EventEmitter, AfterContentInit } from '@angular/core';
import { PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { CompetencyCompliance } from '~/shared/models/copetency-compliance.model';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { proficiencyLevels } from '~/shared/constants/select-levels';

@Component({
  selector: 'pcp-compliance-item-list',
  templateUrl: './compliance-item-list.component.html',
  styleUrls: ['./compliance-item-list.component.scss']
})
export class ComplianceItemListComponent implements OnInit, AfterContentInit {
  @Input() public competencyCompliance: CompetencyCompliance;
  @Output() onClickCard = new EventEmitter<CompetencyCompliance>();
  public competencyElement = new PromotionCompetencyElement();
  public proficiencyLevel: ProficiencyLevel;
  public showChart = false;
  get requirementTypeComplete() {
    return RequirementType.complete.valueOf();
  }
  constructor() { }

  ngOnInit(): void {
    if (!this.competencyCompliance) {
      return;
    }

    this.competencyElement.ceId = this.competencyCompliance.ceId;
    this.competencyElement.name = this.competencyCompliance.competencyElement;
    this.competencyElement.competencyUnit = this.competencyCompliance.competencyUnit;
    this.competencyElement.employeeProficiencyLevel = this.takeEmployeeProficiencyLevel(this.competencyCompliance);
    this.setCompetencyCompliancePL(this.competencyCompliance);
  }

  ngAfterContentInit(): void {
    this.showChart = true;
  }

  outputCompetencyCompliance() {
    this.onClickCard.emit(this.competencyCompliance);
  }

  private detailObject(element: CompetencyCompliance): PromotionCompetencyElement {
    return new PromotionCompetencyElement ({
      ceId: element.ceId,
      name: element.competencyElement,
      competencyUnit: element.competencyUnit
    });
  }

  private setCompetencyCompliancePL(element: CompetencyCompliance) {
    this.proficiencyLevel = new ProficiencyLevel();
    this.proficiencyLevel.id = element.reqPL;
    this.proficiencyLevel.setNameById(element.reqPL);
  }

  private takeEmployeeProficiencyLevel(element: CompetencyCompliance): string {
    const noneLevel = proficiencyLevels.find(x => x.value === 'None');
    if (element.currentPL === noneLevel.keyEnum) {
      return noneLevel.value;
    }

    let proficiencyLevel = new ProficiencyLevel();
    proficiencyLevel.setNameById(element.currentPL);

    return proficiencyLevel.name;
  }
}
