export class CompetencyTicket {
  serviceOffering: string;
  description: string;
  shortDescription: string;

  constructor(data?: Partial<CompetencyTicket>) {
    Object.assign(this, data);
  }
}

export class CompetencyTicketResponse {
  msg: string;
  error: string;

  constructor(data?: Partial<CompetencyTicketResponse>) {
    Object.assign(this, data);
  }
}
