
<section class="container section-assessor">
    <header>
        <h2 mat-dialog-title>Title</h2>
        <figure (click)="close()">
            <img src="assets/icons/close-dialog.svg" alt="close">
        </figure>
    </header>
    <mat-divider></mat-divider>
    <section>
        <mat-dialog-content>
          <div *ngFor = "let item of items">
            <pcp-assessor-detail [name] = item.person.personName></pcp-assessor-detail>
          </div>
        </mat-dialog-content>
    </section>
    <pcp-loading-dots [class.d-none]="!isLoading"></pcp-loading-dots>
</section>
