import { Component, OnInit } from '@angular/core';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { PromotionGroup } from '~/shared/models/promotion-group.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionQuery } from '~/store/promotion/promotion.query';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';
import { RequirementType } from '~/shared/enums/requirement-type.enum';

@Component({
  templateUrl: './promotion-requirements.component.html',
  styleUrls: ['./promotion-requirements.component.scss']
})
export class PromotionRequirementsComponent extends PageComponentBase implements OnInit {

  public group: PromotionGroup;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private route: ActivatedRoute,
    private promotionQuery: PromotionQuery,
    private router: Router
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    this.route.queryParamMap.subscribe((param) => {
      const groupName = param.get('group');
      this.promotionQuery.findGroupByName(groupName).subscribe((group) => {
        this.group = group;
        this.finishLoading();
      });
    });
  }

  public viewRequirement(requirement: PromotionRequirement): void {
    if (requirement.requirementType === RequirementType.points) {
      this.router.navigate(['promotion', 'points-achieved'],
        {
          state: { title: 'Competency Elements' },
          queryParams: { group: this.group.name, requirement: requirement.requirement }
        });
    } else {
      this.router.navigate(['promotion', 'requirements-ces'],
        {
          state: { title: 'Competency Elements' },
          queryParams: { group: this.group.name, requirement: requirement.requirement }
        });
    }
  }

  public refreshRequested() {
    this.finishLoading();
  }
}
