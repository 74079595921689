import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { CompetencyElement } from '~/shared/models/competency-element.model';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';

@Component({
  templateUrl: './competency-elements.component.html',
  styleUrls: ['./competency-elements.component.scss']
})
export class CompetencyElementsComponent extends PageComponentBase implements OnInit {

  public competencyElements$: Observable<Array<CompetencyElement>>;
  public proficiencyLevelId = 0;
  public competencyUnitName: string;
  public terms: Array<string> = [];

  constructor(
    pullToRefreshService: PullToRefreshService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    if (this.route.snapshot.params['proficiency-level']) {
      this.proficiencyLevelId = parseInt(this.route.snapshot.params['proficiency-level'], null);
    }

    this.competencyUnitName = this.route.snapshot.params['competency-unit'];
  }

  public onClick(competencyElement: CompetencyElement): void {
    this.router.navigate(
      ['competency-units', this.route.snapshot.params['competency-unit'], 'elements', competencyElement.name, 'detail'],
      { state: { title: competencyElement.name } });
  }

  public refreshRequested() {
    this.finishLoading();
  }

  public onSearchChanged(term: string): void {
    term = term.trim();
    this.terms = term.length > 0 ? term.split(' ') : [];
    // this.loadData();
  }

  public competencyDescription(competencyElement: CompetencyElement): string {
    if (competencyElement) {
      if (competencyElement.proficiencyLevel) {
        return '';
      }
      if (competencyElement) {
        return '';//`${competencyElement.proficiencyLevel}, ${competencyElement} ${competencyElement.daysRemaining > 1 ? 'days' : 'day'} remaining`;
      }
      return `${competencyElement.proficiencyLevel}`;
    }
    return '';
  }
}
