import { Component, Input } from '@angular/core';
const DAYS_TO_DANGER = 30;
const DAYS_TO_WARNING = 90;

@Component({
  selector: 'pcp-icon-days-alert',
  templateUrl: './icon-days-alert.component.html',
  styleUrls: ['./icon-days-alert.component.scss']
})
export class IconDaysAlertComponent {

  @Input() public daysRemaining = 0;
  @Input() public showDefault = false;

  get showAlert(): boolean {
    return this.daysRemaining && this.daysRemaining <= DAYS_TO_WARNING && this.daysRemaining > DAYS_TO_DANGER;
  }

  get showDanger(): boolean {
    return this.daysRemaining >= 0 && this.daysRemaining <= DAYS_TO_DANGER;
  }
}
