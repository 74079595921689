import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedModule } from '~/shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { CompetencyUnitsModule } from '../competency-units/competency-units.module';
import { NoDataFoundModule } from '../no-data-found/no-data-found.module';
import { ComplianceComponent } from './pages/compliance/compliance.component';
import { ComplianceRoutingModule } from './compliance-routing.module';
import { ComplianceUserTrendHeaderComponent } from './components/compliance-user-trend-header/compliance-user-trend-header.component';
import { ComplianceItemListComponent } from './components/compliance-item-list/compliance-item-list.component';
import { ComplianceUserTrendChartComponent } from './components/compliance-user-trend-chart/compliance-user-trend-chart.component';
import { ComplianceAverageProgressBarComponent } from './components/compliance-average-progress-bar/compliance-average-progress-bar.component';
import { ComplianceTargetsAverageProgressBarComponent } from './components/compliance-targets-average-progress-bar/compliance-targets-average-progress-bar.component';

const MODULE_COMPONENTS = [
  ComplianceUserTrendHeaderComponent,
  ComplianceComponent,
  ComplianceItemListComponent,
  ComplianceUserTrendChartComponent,
  ComplianceAverageProgressBarComponent,
  ComplianceTargetsAverageProgressBarComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  ComplianceRoutingModule,
  NgCircleProgressModule.forRoot(),
  MatExpansionModule,
  MatDividerModule,
  SharedModule,
  CompetencyUnitsModule,
  NoDataFoundModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class ComplianceModule { }
