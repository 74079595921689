import { Component, OnInit } from '@angular/core';
import { MyTrendsService } from '~/shared/services/my-trends.service';
import { TrendQuery } from '~/store/trend/trend.query';

@Component({
  selector: 'compliance-average-progress-bar',
  templateUrl: './compliance-average-progress-bar.component.html',
  styleUrls: ['./compliance-average-progress-bar.component.scss']
})
export class ComplianceAverageProgressBarComponent implements OnInit {

  public lastSixMonthsAverage = 0;
  public firstEighteenMonthsAverage = 0;
  public hasTrendData = false;

  constructor(
    private trendsService: MyTrendsService,
    private trendQuery: TrendQuery
  ) {
    //
  }

  ngOnInit(): void {
    this.hasTrendData = false;

    this.trendQuery.select().subscribe((state) => {
      const loadedDataIsNotEmpty = state.trend?.monthlyCompliance.length > 0;
      if (loadedDataIsNotEmpty) {
        this.hasTrendData = true;
      } else {
        this.hasTrendData = false;
      }
      this.lastSixMonthsAverage = this.trendsService.lastSixMonthsAverage;
      this.firstEighteenMonthsAverage = this.trendsService.firstEighteenMonthsAverage;
    });
  }

}
