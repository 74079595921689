import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { CompetencyElement } from '~/shared/models/competency-element.model';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { User } from '~/shared/models/user.model';
import { CompetencyUnitQuery } from '~/store/competency-unit/competency-unit.query';
import { UserQuery } from '~/store/user/user.query';
import { isMobileSize } from '~/shared/utils/navigator';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { FilterMyElementsService } from '~/core/services/filter-my-elements.service';
import { sleep } from '~/shared/utils/sleep';
import { CompetencyReportingQuery } from '~/store/competency-reporting/competency-reporting.query';
import { Competency } from '~/shared/models/competency-repoting.model';
import { CompetencyReportingStore } from '~/store/competency-reporting/competency-reporting.store';

const MILLISECONDS_LOAD_LIST = 500;
@Component({
  selector: 'pcp-my-elements',
  templateUrl: './my-elements.component.html',
  styleUrls: ['./my-elements.component.scss']
})
export class MyElementsComponent extends PageComponentBase implements OnInit {

  get isMobileSize() {
    return isMobileSize()
  }

  public index = 0;
  public itemsPage = 20;
  public competencyList = new Array<Competency>();
  public competencyListData = new Array<Competency>();
  public competencyUnits = new Array<Competency>();
  public competencyUnitsData = new Array<Competency>();
  public user: User;
  public competencyElement: Competency;
  public competencyElementName: string;
  public showDetail: Competency;
  public awardedOnDate: Date;
  public nextExpirationDate: Date;
  public cardsClosed: boolean;
  public filtersDefault: { key, value }[] = [];
  public isloading = false;
  public competencyNoneCount = 0

  constructor(
    pullToRefreshService: PullToRefreshService,
    private router: Router,
    private route: ActivatedRoute,
    private userQuery: UserQuery,
    private elementDetailService: ElementDetailService,
    private filterMyElementsService: FilterMyElementsService,
    private competencyReportingQuery: CompetencyReportingQuery
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    this.userQuery.select('user')
      .subscribe((data) => {
        if (!data) return;
        this.user = data;
      })
      this.competencyReportingQuery.select()
      .subscribe(((data) => {
        let sortedData;
        if(data?.dto?.elements) {
          sortedData = this.orderDaysRemaining(data?.dto?.elements);
        }

        sortedData?.map((element) =>{
          element.plName = this.findProficencyLevel(element.pl).name;
          if(!element.plName) {
            element.plName = "None";
          }

          this.competencyList.push(element);
          this.competencyListData.push(element);
        })
      }))
    this.verifyParams();
    this.observeFilterMyElement();
  }

  private async paginator() {
    const data = this.competencyUnits.slice((this.index) * this.itemsPage, (this.index) * this.itemsPage + this.itemsPage);
    if (data.length > 0) {
      this.isloading = true;
      this.index++;
      await sleep(MILLISECONDS_LOAD_LIST)
      this.isloading = false;
    } else {
      await (this.isloading = false);
    }
  }

  private resetList() {
    this.competencyList = [...this.competencyListData]
    this.index = 0;
    this.showDetail = null;
  }

  /* Old Elements
  public getElements() {
    this.competencyUnitQuery.competencyUnits$
      .subscribe((competencyUnit) => {
        if (!competencyUnit) return;

        competencyUnit.forEach((x) => {
          x.competencyElements
            // .filter((z) => z.expirationDate)
            .filter((z) => !z.proficiencyLevel.isNullOrNone)
            .forEach((y) => {
              y.proficiencyLevelName = y.proficiencyLevel.name
              this.competencyUnits.push(y)
            })
        })

        this.competencyUnits = this.competencyUnits
          .sort((a, b) => (a.daysRemaining > b.daysRemaining) ? 1 : ((b.daysRemaining > a.daysRemaining || a.daysRemaining) ? -1 : 0))
        // this.competencyUnits = this.competencyUnits.filter((x) => x.daysRemaining < dataLimit)

        this.competencyUnitsData = [...this.competencyUnits]
        this.paginator();
        this.verifyParams();
      });
  } */

  private orderDaysRemaining(data) {
    data.sort((a, b) => {
      const daysRemainingA = a?.expirationDate;
      const daysRemainingB = b?.expirationDate;

      if (daysRemainingA == undefined && daysRemainingB != undefined) return 1;
      if (daysRemainingB == undefined && daysRemainingA != undefined) return -1;
      if (daysRemainingA > daysRemainingB) return 1;
      if (daysRemainingA < daysRemainingB) return -1;

      return 0;
    });
    return data;
  }

  public observeFilterMyElement() {
    this.filterMyElementsService.onChange()
      .subscribe((data) => {
        if (data) {
          this.filtersDefault = [{ key: 'plName', value: data.value }];
        }
      })
  }

  public async verifyParams() {
    await sleep(MILLISECONDS_LOAD_LIST)
    this.route.queryParams.forEach((i) => {
      if (i.levelName) this.filterMyElementsService.setValue(i.levelName);
    })
  }

  public applyFilter(filtered) {
    this.resetList();
    this.competencyList = [...filtered];
    this.paginator();
  }

  public findProficencyLevel(level: number): ProficiencyLevel {
    const pl = new ProficiencyLevel();
    pl.id = level;
    pl.setNameById(level);

    return pl;
  }

  public navigateToHome() {
    this.router.navigate(['home']);
  }

  public showElementsDetail(element: Competency) {
    if (this.showDetail?.competencyElement === element.competencyElement) {
      this.showDetail = null;
      this.elementDetailService.clear();
      return;
    }
    this.showDetail = element;
    if (!this.isMobileSize) {
      this.elementDetailService.clearDataTypePCE();
      this.elementDetailService.setValue(element);
    }
  }

  public onScroll() {
    this.paginator()
  }
}
