import { TokenQuery } from './../../../store/token/token.query';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Configuration } from '~/shared/models/configuration.model';

const helper = new JwtHelperService();

@Injectable()
export abstract class CompetencyApiBaseService<T> {
  protected endpoint = '';
  public token: string;
  public headers: HttpHeaders;
  public headersNew: HttpHeaders;
  public headersEsm: HttpHeaders;

  constructor(
    protected httpClient: HttpClient,
    protected config: Configuration,
    protected tokenQuery: TokenQuery
  ) {
    this.tokenQuery.token$.subscribe((token) => {
      this.token = token;
      this.headers = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`);
        // .set('x-apikey', this.config.apiKey);
        if(this.config.env === 'dev' || this.config.env === 'qa')
        {
          this.headers = this.headers.set('x-apikey', 'hypXQrf9SWANRvI4sk214mBeuV4hnCmy');
        }      
        else if(this.config.env === 'prod')
        {
          this.headers = this.headers.set('x-apikey', '2Pk6TVQxMPl9aHjQAkxyvXR1u7lGnLv1');
        }
    });

    this.tokenQuery.msalApiToken$.subscribe((token) => {
      this.token = token;
      this.headersNew = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`);
        // .set('x-apikey', this.config.newApiKey);
      if(this.config.env === 'dev' || this.config.env === 'qa')
      {
        this.headersNew = this.headersNew.set('x-apikey', 'vTCr5enomk3JpXYogiPGezCIZa3ylbwx');
      }      
      else if(this.config.env === 'prod')
      {
        this.headersNew = this.headersNew.set('x-apikey', 'uw9e8pMkQAkADHYF2tE84fCPxsuwvA6Q');
      }
    });

    this.tokenQuery.esmApiToken$.subscribe((token) => {
      this.token = token;
      this.headersEsm = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`);
        // .set('x-apikey', this.config.newApiKey);
      if(this.config.env === 'dev' || this.config.env === 'qa')
      {
        this.headersEsm = this.headersEsm.set('x-apikey', 'vTCr5enomk3JpXYogiPGezCIZa3ylbwx');
      }      
      else if(this.config.env === 'prod')
      {
        this.headersEsm = this.headersEsm.set('x-apikey', 'uw9e8pMkQAkADHYF2tE84fCPxsuwvA6Q');
      }
    });
  }

  protected encode(value: string): string {
    return encodeURIComponent(value);
  }

  protected get(url: string): Observable<Array<T>> {
    return this.internalGet(url);
  }

  protected getCustom(url: string): Observable<Array<T>> {
    return this.internalGetCustom(url);
  }

  protected getSingle(url: string): Observable<T> {
    return this.internalGetSingle(url);
  }

  protected getSingleCustom(url: string): Observable<T> {
    return this.internalGetSingleCustom(url);
  }

  protected getPatSingleCustom(url:string, envHeader?: boolean):Observable<T>{
    return this.internalGetPat(url, envHeader);
  }

  protected postSingleEsmApi(url: string, body: any, envHeader?: boolean): Observable<T> {
    return this.internalPostEsmApi(url, body, envHeader);
  }

  private internalGetPat(url: string, envHeader?: boolean): Observable<T>{
    if (this.config.env === 'dev') {
      this.headersNew = this.headersNew.set('x-slb-env', this.config.env);
    }

    return  this.httpClient.get<T>(url, { headers: this.headersNew });
  }

  private internalGet(url: string): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(url, { headers: this.getDefaultHeaders() });
  }

  private internalGetSingle(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.getDefaultHeaders() });
  }

  private internalGetSingleCustom(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headers });
  }

  private internalGetCustom(url: string): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(url, { headers: this.headers });
  }

  private internalPostEsmApi(url: string, body: any, envHeader?: boolean): Observable<T> {
    if (envHeader === true && this.config.env === 'dev') {
      this.headersEsm = this.headersEsm.set('x-slb-env', this.config.env);
    }

    return this.httpClient.post<T>(url, body, { headers: this.headersEsm });
  }

  private getDefaultHeaders(): any {
    // return {
    //   'x-apikey': this.config.apiKey
    // }
    if (this.config.env === 'dev' || this.config.env === 'qa') {
      return {
        'x-apikey': 'hypXQrf9SWANRvI4sk214mBeuV4hnCmy'
      };
    } else if (this.config.env === 'prod') {
      return {
        'x-apikey': '2Pk6TVQxMPl9aHjQAkxyvXR1u7lGnLv1'
      };
    } else {
      return {};
    }
  }
}
