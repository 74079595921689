<header>
    <div class="unit-status">
        <dl class="unit-data">
            <dt class="fontSize6">Points achieved</dt>
        </dl>
    </div>
</header>

<div class="container">
    <div *ngFor="let point of pointsCEs; last as lst;">
        <pcp-promotion-points-ce [competencyElement]="point" [last]="lst" (competencyElementClick)="viewCE($event)"></pcp-promotion-points-ce>
    </div>
</div>
