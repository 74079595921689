import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment-mini-ts';
import { ReasonCategory } from '../../../../shared/constants/reason-category';
import { ReasonCode } from '../../../../shared/constants/reason-code';
import { CompetencyElement } from '../../../../shared/models/competency-element.model';
import { ElementLevelInfo as ElementDetailBase } from '../../../../shared/models/element-level-info.model';
import { ProficiencyLevel } from '../../../../shared/models/proficiency-level.model';
import { isMobileSize, MIN_WIDTH_DIALOG_LG, MIN_WIDTH_DIALOG_MOBILE, SIZE_MIN_LG_SCREEN } from '../../../../shared/utils/navigator';
import { CompetencyElementQuery } from '../../../../store/competency-element/competency-element.query';
import { Message } from '../../../../shared/constants/message';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { PcpRequirements } from '../../../../shared/models/pcp-requirements.model';

@Component({
  selector: 'pcp-level-information',
  templateUrl: './level-information.component.html',
  styleUrls: ['./level-information.component.scss']
})
export class LevelInformationComponent {
  private awardedOnDate: Date;
  private competencyUnit: string;
  private nextProficiencyLevel: string;

  public panelOpenState = isMobileSize() ? false : true;
  @Input() public competencyElementName: string;
  @Input() public pcpRequirements:PcpRequirements;
  public messages: Array<string> = [];
  public levelInfosReasonFour: Array<ElementDetailBase> = [];
  public hasReasonOneAndThree = false;
  public hasReasonOne = false;
  public hasReasonTwo = false;
  public hasReasonThree = false;
  public hasReasonFour = false;

  constructor(
    private competencyElementQuery: CompetencyElementQuery,
    public dialog: MatDialog,
  ) { }

  private verifyLevelInfos(competencyElement: CompetencyElement): void {
    if (!competencyElement) {
      return;
    }

    this.messages = [];
    this.hasReasonOneAndThree = false;
    this.hasReasonOne = false;
    this.hasReasonTwo = false;
    this.hasReasonThree = false;
    this.hasReasonFour = false;

    if (competencyElement.proficiencyLevel.id === ProficiencyLevel.ExpertID) {
      this.hasReasonTwo = true;
      this.messages.push(Message.ALL_REQUIREMENTS_MET);
      return;
    }

    if (competencyElement.levelInfos.length === 0) {
      return;
    }

    const reasonCodes = competencyElement.levelInfos.map((li) => li.reasonCode);

    if (reasonCodes.includes(ReasonCode.NoLongerAttemptingAttainOrMaintain) && reasonCodes.includes(ReasonCode.NoPlrRequirementsFound)) {
      this.hasReasonOneAndThree = true;
      this.messages.push(Message.NO_LONGER_ATTEMPTING_ATTAIN_OR_MAINTAIN);
      this.messages.push(Message.NO_PLR_REQUIREMENTS_FOUND);
      return;
    }

    if (reasonCodes.includes(ReasonCode.NoLongerAttemptingAttainOrMaintain)) {
      this.hasReasonOne = true;
      this.messages.push(Message.NO_LONGER_ATTEMPTING_ATTAIN_OR_MAINTAIN);
      return;
    }

    if (reasonCodes.includes(ReasonCode.AllRequirementsMet)) {
      this.hasReasonTwo = true;
      this.messages.push(Message.ALL_REQUIREMENTS_MET);
      return;
    }

    if (reasonCodes.includes(ReasonCode.NoPlrRequirementsFound)) {
      this.hasReasonThree = true;
      this.messages.push(Message.NO_PLR_REQUIREMENTS_FOUND);
      return;
    }

    if (reasonCodes.includes(ReasonCode.InsufficientRequirements)) {
      this.hasReasonFour = true;
      this.levelInfosReasonFour = competencyElement.levelInfos.filter((li) => li.reasonCode === ReasonCode.InsufficientRequirements);
      return;
    }
  }

  public openDialog(data: any): void {
    const dataDialog = {
      competencyElementName: data.pathwayRequirement,
      competencyElementId: data.ceId,
      typeData: 'training',
      pathway: data.pathway,
      pathwayType: data.pathwayType,
      pl: data.attemptedProficiency,
      fromDate: moment(this.awardedOnDate).format('YYYY-MM-DD'),
      origin: 'level-information',
      ...data
    }
    const sizeDialog: MatDialogConfig = {
      minWidth: MIN_WIDTH_DIALOG_MOBILE,
      data: dataDialog
    };

    if (window.innerWidth <= SIZE_MIN_LG_SCREEN)
      sizeDialog.minWidth = MIN_WIDTH_DIALOG_LG;

    this.dialog.open(DialogDetailComponent, sizeDialog);
  }

  public detail(elementDetail: ElementDetailBase): void {

    this.openDialog(elementDetail);

  }
}
