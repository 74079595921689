import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '~/shared/models/configuration.model';
import { Observable } from 'rxjs/internal/Observable';
import { IntouchHelpdeskDto } from './dtos/intouch-helpdesk.dto';
import { AuthService } from '~/core/services/auth.service';

import { IntouchApiBaseService } from './base/intouch-api-base.service';
import { TokenQuery } from '~/store/token/token.query';

@Injectable({ providedIn: 'root' })
export class IntouchHelpdeskService extends IntouchApiBaseService<IntouchHelpdeskDto> {

    constructor(
        httpClient: HttpClient,
        config: Configuration,
        tokenQuery: TokenQuery
    ) {
        super(httpClient, config, tokenQuery);
    }

    public getIntouchHelpdesks(ldap: string, businessLineId: string): Observable<IntouchHelpdeskDto> {
        return this.getSingleCustom(`${this.config.intouchApiUrl}/segmentrelatedto?sSubfor_alias=${ldap}&sSegmentID=${businessLineId}`);
    }

}
