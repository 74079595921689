import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackComponent } from '~/modules/feedback/pages/feedback/feedback.component';
import { isMobileSize, MIN_WIDTH_DIALOG_LG, MIN_WIDTH_DIALOG_MOBILE } from '~/shared/utils/navigator';

@Component({
  selector: 'pcp-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent {

  constructor(public dialog: MatDialog) {
    //
  }

  public onClickButton() {
    const sizeDialog: MatDialogConfig = {
      minWidth: MIN_WIDTH_DIALOG_MOBILE,
    };

    if (isMobileSize())
      sizeDialog.minWidth = MIN_WIDTH_DIALOG_LG

    this.dialog.open(FeedbackComponent, sizeDialog)
  }

}
