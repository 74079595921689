import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssessorService } from '~/shared/services/assessors.service';

@Component({
  selector: 'pcp-dialog-assessors',
  templateUrl: './dialog-assessors.component.html',
  styleUrls: ['./dialog-assessors.component.scss']
})
export class DialogAssessorsComponent implements OnInit {
  public isLoading = true;
  public items:any = [];

  constructor(
    public dialogRef: MatDialogRef<DialogAssessorsComponent>,
    private assessorService:AssessorService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public ngOnInit(): void {
    this.getData()
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getData(){
    this.assessorService.getAssessors().subscribe((data) => {
      this.items = data.items
      this.isLoading = false
    })
  }
}
