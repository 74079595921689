import { CompetencyCompliance } from './copetency-compliance.model';
import { JobCompliance } from './job-compliance.model';
import { MonthlyCompliance } from './monthly-compliance.model';

export class Trend {
    public ginNumber: number;
    public jobCompliances: JobCompliance[];
    public competencyCompliances: CompetencyCompliance[];
    public monthlyCompliance: MonthlyCompliance[];
    public complianceTotal: number;
    public noComplianceTotal: number;
    public noCompliancePercent: string;
    public yesComplianceTotal: number;
    public yesCompliancePercent: string;

    constructor(data?: Partial<Trend>) {
        Object.assign(this, data);
    }
}