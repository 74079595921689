import { Component, Input, OnInit } from '@angular/core';
import { RequirementType } from 'src/app/shared/enums/requirement-type.enum';
import { ProficiencyLevel } from 'src/app/shared/models/proficiency-level.model';
import { PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { proficiencyLevels } from '~/shared/constants/select-levels';


@Component({
  selector: 'pcp-card-element-list',
  templateUrl: './card-element-list.component.html',
  styleUrls: ['./card-element-list.component.scss']
})
export class CardElementListComponent {

  @Input() public requirement: any;
  @Input() public competencyElement: any;
  public proficiencyLevel: ProficiencyLevel;
  public competencyElementPromotion = new PromotionCompetencyElement();

  get requirementTypeComplete() {
    return RequirementType.complete.valueOf();
  }

  ngOnInit(): void {    
    this.setCompetencyCompliancePL(this.competencyElement);

    this.competencyElementPromotion.ceId = this.competencyElement.ceId;
    this.competencyElementPromotion.name = this.competencyElement.competencyElement;
    this.competencyElementPromotion.competencyUnit = this.competencyElement.competencyUnit;
    this.competencyElementPromotion.employeeProficiencyLevel = this.takeEmployeeProficiencyLevel(this.competencyElement);

  }

  private setCompetencyCompliancePL(element: any) {
    this.proficiencyLevel = new ProficiencyLevel();
    this.proficiencyLevel.id = element.targetPL;
    this.proficiencyLevel.setNameById(element.targetPL);
  }

  private takeEmployeeProficiencyLevel(element: any): string {
    const noneLevel = proficiencyLevels.find(x => x.value === 'None');
    if (element.pl === noneLevel.keyEnum) {
      return noneLevel.value;
    }

    const proficiencyLvl = new ProficiencyLevel();
    proficiencyLvl.setNameById(element.pl);

    return proficiencyLvl.name;
  }

}
