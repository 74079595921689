import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenQuery } from 'src/app/store/token/token.query';
import { CompetencyReportingFilterStore } from '~/store/competency-reporting-filter/competency-reporting-filter.store';
import { CompetencyReportingStore } from '~/store/competency-reporting/competency-reporting.store';
import { CompetencyReporting } from '../models/competency-repoting.model';
import { Configuration } from '../models/configuration.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { UserGraphService } from './user-graph.service';

@Injectable({ providedIn: 'root' })
export class CompetencyReportingService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private competencyReportingStore:CompetencyReportingStore,
    private competencyReportingFilterStore:CompetencyReportingFilterStore,
    private userGraphService: UserGraphService
  ) {
    super(httpClient, config, token);
    this.endpoint = '/competency_reporting';
  }

  public getReporting(ginNumber: number, isDev?: boolean): Observable<CompetencyReporting> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}${this.endpoint}/pcps?GinNumber=${ginNumber}`)
      .pipe(tap((dto: CompetencyReporting) => {
        if (dto) {
          this.competencyReportingStore.setLoading(false)
          this.competencyReportingStore.update({dto});
          this.userGraphService.getUser(ginNumber, isDev);
          return dto;
        }
        return null;
      }));
  }

  public getFilterReporting(ginNumber:number, filter:string){
    return this.getPatSingleCustom(`${this.config.newApiUrl}${this.endpoint}/pcps-filter?GinNumber=${ginNumber}&Filter=${filter}`)
    .pipe(tap((dto: CompetencyReporting) => {
      if (dto) {
        this.competencyReportingFilterStore.setLoading(false)
        this.competencyReportingFilterStore.update({dto});
        return dto;
      }
      return null;
    }));
  }

}
