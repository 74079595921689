import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '~/core/guards/is-authenticated.guard';
import { IsOnlineGuard } from '~/core/guards/is-online.guard';
import { ComplianceComponent } from './pages/compliance/compliance.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: ComplianceComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComplianceRoutingModule { }
