import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { CompetencyReporting } from '~/shared/models/competency-repoting.model';

export interface ICompetencyReportingState extends EntityState<CompetencyReporting> { }

export function createInitialState(): ICompetencyReportingState {
  return null;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'competency-reporting', idKey: 'gin' })
export class CompetencyReportingStore extends EntityStore<ICompetencyReportingState> {
  constructor() {
    super(createInitialState());
  }
}
