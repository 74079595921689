<div class="assessor-section">
  <div>
    <h2 class="name">{{ name }}</h2>
    <div class="assessor-info">
      <p>{{ job }}</p>
      <p>{{ unit }}</p>
    </div>
  </div>
  <!-- <button>
            <img src="../../../../../../../assets/icons/scheduler.svg" alt=" Calendar Logo" />
            <p class="button-text">SCHEDULE INTERVIEW CALL</p>
        </button> -->
</div>
