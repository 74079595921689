import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { Configuration } from '~/shared/models/configuration.model';
import { TokenStore } from '~/store/token/token.store';
import { LocalStorageKeys } from '../../shared/constants/local-storage-keys';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public msalInstance: IPublicClientApplication;

  constructor(
    private msalService: MsalService,
    private injector: Injector,
    private router: Router,
    private config:Configuration,
    private tokenStore:TokenStore
  ) {
    this.msalInstance = msalService.instance;
  }

  /**
   * Register a callback that is called before the login redirect.
   * The Msal Angular lib throws an error if the callback is not registered.
   */

  private errorRegisterRedirectCallback(error) {
    this.injector.get(AppInsightsService).trackException(error);
  }

  public checkAndSetActiveAccount() {
    const activeAccount = this.msalInstance.getActiveAccount();

    if (!activeAccount && this.msalInstance.getAllAccounts().length > 0) {
      const accounts = this.msalInstance.getAllAccounts();
      this.msalInstance.setActiveAccount(accounts[0]);
    }
  }

  public async login() {
    const res = await this.msalInstance.handleRedirectPromise();
    const accounts = this.msalInstance.getAllAccounts();

    if (accounts.length === 0)
      this.msalInstance.loginRedirect();
    else {
      this.checkAndSetActiveAccount();
      this.router.navigate(['home']);
    }
  }

  public isAuthenticated(): boolean {
    return this.msalInstance.getActiveAccount() ? true : false;
  }

  public logout(): void {
    for (const key of Object.keys(LocalStorageKeys)) {
      localStorage.removeItem(LocalStorageKeys[key]);
    }

    const appInsights = this.injector.get(AppInsightsService);
    appInsights.clearUserId();
    this.msalService.logout();
  }

  public getEmail(): string {
    if (this.isAuthenticated()) {
      return this.msalInstance.getActiveAccount().username;
    } else {
      throw new Error('User has to be authenticated.');
    }
  }

  public getGinNumber(): number {
    return localStorage.getItem(LocalStorageKeys.GinNumber) ?
      parseInt(localStorage.getItem(LocalStorageKeys.GinNumber), null) : 0;
  }

  public setGinNumber(ginNumber: number): void {
    localStorage.setItem(LocalStorageKeys.GinNumber, ginNumber.toString());
  }

  public registerRedirectCallback(): Subject<void> {

    const subject = new Subject<void>();

    const graphScopes = {
      scopes: [this.config.apiScope]
    };

    const esmApiScope = {
      scopes: [this.config.esmApiScope]
    };

    this.msalInstance.handleRedirectPromise()
      .then((data) => {

        this.msalService.acquireTokenSilent(graphScopes).toPromise().then((data) => {
            this.tokenStore.update({ msalApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              this.msalService.acquireTokenPopup(graphScopes)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ msalApiToken: data.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));
            } else this.errorRegisterRedirectCallback(error)
          });

        this.msalService.acquireTokenSilent(esmApiScope).toPromise().then(data => {
            this.tokenStore.update({ esmApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {

              this.msalService.acquireTokenPopup(esmApiScope)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ esmApiToken: data?.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));

            } else this.errorRegisterRedirectCallback(error)
          });
      }).catch((error) => this.errorRegisterRedirectCallback(error));

    return subject;
  }
}
