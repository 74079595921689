import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsOnlineGuard implements CanActivate {
  constructor(private router: Router) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (navigator.onLine) {
      return true;
    }

    this.router.navigateByUrl('/offline');
    return false;
  }
}
