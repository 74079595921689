<div class="timeline-item" *ngFor="let item of history; let i=index">
  <div class="container-timeline-item" [ngClass]="{'container-timeline-item-last': history?.length === i + 1 }">
    <div>
      <span class="item-point" [ngClass]="{'item-point-last': history?.length === i + 1 }"></span>
      <span class="line" [ngClass]="{'line': history?.length > i + 1 }"></span>
    </div>
    <div class="content-timeline-item">
      <span class="content-span">
        {{ item.startDate | date:'MM-dd-yyyy' }}
      </span>

      <span class="content-span level-growth">{{ selectLevel(item.pl) }}
        <img *ngIf="item.growth != 'Initial' && item.growth != 'No Change'"
          [src]="selectGrowth(item.growth)" class="icon-grow" alt="icon of grow">
      </span>

      <span class="content-span">Reason: {{ item.correctionFlag }}</span>
    </div>
  </div>
</div>
