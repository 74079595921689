import { CompetencyElement, PromotionCompetencyElement } from './../../../../shared/models/competency-element.model';
import { Component, Input, OnInit } from '@angular/core';
import { getDefaultClassProgress } from '~/shared/utils/class-progress';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { isMobileSize } from '~/shared/utils/navigator';
import { RequirementType } from '~/shared/enums/requirement-type.enum'
import { ProficiencyLevelTypeNumber } from '~/shared/enums/proficiency-level-type.enum';

@Component({
  selector: 'pcp-promotion-item-list',
  templateUrl: './promotion-item-list.component.html',
  styleUrls: ['./promotion-item-list.component.scss']
})
export class PromotionItemListComponent implements OnInit {
  @Input() public requirements: Array<PromotionRequirement>;
  @Input() public isHome = false
  @Input() public expanded: boolean;
  @Input() public groupingType: string;

  public onDetail: PromotionCompetencyElement;
  public panelOpenState: boolean;
  public isRequirementTypePoints(requirement: PromotionRequirement) {
    return requirement.requirementType == RequirementType.points.valueOf();
  }
  public isRequirementTypeComplete(requirement: PromotionRequirement) {
    return requirement.requirementType == RequirementType.complete.valueOf();
  }
  public isRequirementTypeSpecific(requirement: PromotionRequirement) {
    return requirement.requirementType == RequirementType.specific.valueOf();
  }

  constructor(private elementDetailService: ElementDetailService) {
    //
  }

  ngOnInit(): void {
    if (this.requirements !== undefined) {
      this.requirements.forEach(req => {
        if (req.competencyElements?.length > 0) {
          req.competencyElements.sort(function (a, b) {
            const first = ProficiencyLevelTypeNumber[a.employeeProficiencyLevel === null || a.employeeProficiencyLevel === undefined  ? 'null' : a.employeeProficiencyLevel.toLowerCase()];
            const second = ProficiencyLevelTypeNumber[b.employeeProficiencyLevel === null || b.employeeProficiencyLevel === undefined  ? 'null' : b.employeeProficiencyLevel.toLowerCase()];
            if (first > second) {
              return -1;
            }
            if (first < second) {
              return 1;
            }

            return 0;
          });
        }
      });
    }
  }

  public getClassProgressByValue(requirement: PromotionRequirement): string {
    const percentage = this.pointAchievedPercentage(requirement);
    return getDefaultClassProgress(percentage)
  }

  get isMobileSize() {
    return isMobileSize()
  }

  public showElementsDetail(element: PromotionCompetencyElement, requirement: PromotionRequirement) {
    if (this.onDetail?.ceId === element.ceId) {
      this.onDetail = undefined;
      this.elementDetailService.clear();
      return;
    }

    this.onDetail = element;

    if (!this.isMobileSize) {
      this.elementDetailService.setDataTypePCE = {
        requirementType: requirement.requirementType,
        plTarget: requirement.requiredLevel
      };
      this.elementDetailService.setValue(element);
    }
  }

  numberToEqualOrHigherThanZero(numberToVerify: number): number {
    let result = 0;

    if (numberToVerify > 0) {
      result = numberToVerify;
    }

    return result;
  }

  pointAchievedPercentage(requirement: PromotionRequirement): number {
    const pointsAchieved = requirement?.pointsAchieved ?? 0;

    const percentage = (pointsAchieved * 100) / requirement.requiredPoints
    return percentage;
  }

  titleLabelComplete(requirement: PromotionRequirement): string {
    return `${requirement.requiredCount} Ces At ${requirement.requiredLevel}`;
  }
}
