<!-- danger icon -->
<svg *ngIf="showDanger" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="18" height="18" rx="9" fill="#FFEFEF" />
  <path
    d="M8.72718 11.3999C9.05446 11.3999 9.32717 11.6726 9.32717 11.9454C9.32717 12.2726 9.05446 12.4908 8.72718 12.4908C8.39991 12.4908 8.1272 12.2181 8.1272 11.9454C8.1272 11.6726 8.39991 11.3999 8.72718 11.3999Z"
    fill="#FF5A5A" />
  <path d="M9.21817 7.90894H8.23633V10.8544H9.21817V7.90894Z" fill="#FF5A5A" />
  <path
    d="M14.1815 14.3454H3.27246L8.72701 3.43628L14.1815 14.3454ZM4.58156 13.5272H12.8179L8.67247 5.29084L4.58156 13.5272Z"
    fill="#FF5A5A" />
</svg>

<!-- alert icon -->
<svg *ngIf="showAlert" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="18" height="18" rx="9" fill="#FFF5E6" />
  <path
    d="M8.72718 11.3999C9.05446 11.3999 9.32717 11.6726 9.32717 11.9454C9.32717 12.2726 9.05446 12.4908 8.72718 12.4908C8.39991 12.4908 8.1272 12.2181 8.1272 11.9454C8.1272 11.6726 8.39991 11.3999 8.72718 11.3999Z"
    fill="#FF9800" />
  <path d="M9.21817 7.90894H8.23633V10.8544H9.21817V7.90894Z" fill="#FF9800" />
  <path
    d="M14.1815 14.3454H3.27246L8.72701 3.43628L14.1815 14.3454ZM4.58156 13.5272H12.8179L8.67247 5.29084L4.58156 13.5272Z"
    fill="#FF9800" />
</svg>

<svg *ngIf="showDefault && !showDanger && !showAlert" width="15" height="15" viewBox="0 0 15 16" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M15 15.0371H0V0.787109H2.25V1.91211H1.125V13.9121H13.875V1.91211H12.75V0.787109H15V15.0371Z"
    fill="#C7D0D8" />
  <path d="M5.25 0.0371094H4.125V2.36211H5.25V0.0371094Z" fill="#C7D0D8" />
  <path d="M8.10004 0.0371094H6.97504V2.36211H8.10004V0.0371094Z" fill="#C7D0D8" />
  <path d="M10.875 0.0371094H9.75V2.36211H10.875V0.0371094Z" fill="#C7D0D8" />
  <path
    d="M9.67493 5.28711L6.74993 8.9621L5.17493 7.68712L4.42493 8.5121L5.99993 9.78711L6.89993 10.5371L7.64993 9.6371H7.57494L10.5749 6.03711L9.67493 5.28711Z"
    fill="#C7D0D8" />
</svg>
