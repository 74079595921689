import { NgModule } from '@angular/core';
import { AuthModule } from './auth/auth.module';
import { CompetencyUnitsModule } from './competency-units/competency-units.module';
import { ErrorModule } from './error/error.module';
import { HomeModule } from './home/home.module';
import { OfflineModule } from './offline/offline.module';
import { NoDataFoundModule } from './no-data-found/no-data-found.module';
import { FeedbackModule } from './feedback/feedback.module';
import { PromotionModule } from './promotion/promotion.module';
import { ComplianceModule } from './compliance/compliance.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MaintenanceModule } from './maintenance/maintenance.module';
import { YourTargetsModule } from './your-targets/your-targets.module';

const MODULE_MODULES = [
  AuthModule,
  HomeModule,
  CompetencyUnitsModule,
  ErrorModule,
  OfflineModule,
  NoDataFoundModule,
  FeedbackModule,
  PromotionModule,
  ComplianceModule,
  MatDialogModule,
  MaintenanceModule,
  YourTargetsModule
];

@NgModule({
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_MODULES
  ],
  declarations: []
})
export class ModulesModule { }
