import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'pcp-ios-popup-add-to-homescreen',
  templateUrl: './ios-popup-add-to-homescreen.component.html',
  styleUrls: ['./ios-popup-add-to-homescreen.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({ bottom: '0px', opacity: 1 })),
      state('hide', style({ bottom: '-161px', opacity: 0 })),
      transition('show <=> hide', animate('300ms'))
    ])
  ]
})
export class IosPopupAddToHomescreenComponent implements OnInit {

  public visible = false;
  public domain: string;

  private isInStandaloneMode(): boolean {
    return ('standalone' in window.navigator) && ((window.navigator as any).standalone);
  }

  public isIos(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  public close(): void {
    this.visible = false;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.visible = !this.isInStandaloneMode();
      this.domain = window.location.hostname;
    }, 500);
  }
}
