import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITargetStatusState, TargetStatusStore } from './target-status.store';

@Injectable({ providedIn: 'root' })
export class TargetStatusQuery extends QueryEntity<ITargetStatusState> {

  constructor(protected store: TargetStatusStore) {
    super(store);
  }
}
