import { ServiceOfferingType } from '~/shared/enums/service-offering-type.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../models/configuration.model';
import { TokenQuery } from '~/store/token/token.query';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompetencyTicket, CompetencyTicketResponse } from '../models/competency-ticket.model';

@Injectable({ providedIn: 'root' })
export class CompetencyTicketService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
  ) {
    super(httpClient, config, token);
  }

  public createCompetencyTicket(competencyTicket: CompetencyTicket): Observable<CompetencyTicketResponse> {
    const result = new Subject<CompetencyTicketResponse>();

    const useEnvHeader = this.config.env === 'dev';

    this.postSingleEsmApi(`${this.config.apiUrlEsm}/createticket`, competencyTicket, useEnvHeader)
      .pipe(tap((dto: CompetencyTicketResponse) => {
        if (dto) {
          result.next(dto);
        }
      })).subscribe();

    return result;
  }

  public get getserviceOffering() {
    let esmServiceOffering = this.config.esmServiceOffering;

    if (!esmServiceOffering || esmServiceOffering === '') {
      esmServiceOffering = ServiceOfferingType.myPcp;
    }

    return esmServiceOffering;
  }

  public get getLinkEsmFeedback() {
    let linkEsmFeedback = this.config.linkEsmFeedback;

    if (!linkEsmFeedback) {
      linkEsmFeedback = ''
    }

    return linkEsmFeedback;
  }
}
