import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '~/core/guards/is-authenticated.guard';
import { IsOnlineGuard } from '~/core/guards/is-online.guard';
import { PromotionPointsAchievedComponent } from './pages/promotion-points-achieved/promotion-points-achieved.component';
import { PromotionRequirementsCEsComponent } from './pages/promotion-requirements-ces/promotion-requirements-ces.component';
import { PromotionRequirementsComponent } from './pages/promotion-requirements/promotion-requirements.component';
import { PromotionComponent } from './pages/promotion/promotion.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: PromotionComponent
  },
  {
    path: 'requirements',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: PromotionRequirementsComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    },
  },
  {
    path: 'requirements-ces',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: PromotionRequirementsCEsComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    },
  },
  {
    path: 'points-achieved',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: PromotionPointsAchievedComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromotionRoutingModule { }
