import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import * as cloneDeep from 'lodash/cloneDeep';
import * as sortBy from 'lodash/sortBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { CompetencyElement } from '~/shared/models/competency-element.model';
import { CompetencyUnit } from '~/shared/models/competency-unit.model';
import { CompetencyUnitStore, ICompetencyUnitState } from './competency-unit.store';

@Injectable({ providedIn: 'root' })
export class CompetencyUnitQuery extends Query<ICompetencyUnitState> {

  public competencyUnits$ = this.select((state) => state.competencyUnits);

  constructor(protected store: CompetencyUnitStore) {
    super(store);
  }

  private filterByName(competencyUnit: CompetencyUnit, nameParts: Array<string>): boolean {
    if (nameParts.length === 0) {
      return true;
    }

    const unitName = competencyUnit.name.toLowerCase();
    return nameParts.filter((part) => unitName.toLowerCase().indexOf(part.toLowerCase()) >= 0).length > 0;
  }

  public findByProficiencyLevelIdAndName(proficiencyLevelId: number, nameParts: Array<string>): Observable<Array<CompetencyUnit>> {
    return this.select(
      (state) => state.competencyUnits.filter(
        (unit) => this.filterByName(unit, nameParts) && unit.competencyElements.filter(
          (element) => element.proficiencyLevel.id === proficiencyLevelId
        ).length > 0
      )
    )
      .pipe(
        map((competencyUnits) => {
          const competencyUnitsCopy = cloneDeep(competencyUnits);

          for (const competencyUnit of competencyUnitsCopy) {
            competencyUnit.competencyElements = competencyUnit.competencyElements.filter(
              (e: CompetencyElement) => e.proficiencyLevel.id === proficiencyLevelId);
          }

          return sortBy(competencyUnitsCopy, ['name']);
        }),
      );
  }

  public findByName(nameParts: Array<string>): Observable<Array<CompetencyUnit>> {
    return this.select(
      (state) => state.competencyUnits.filter((unit) => this.filterByName(unit, nameParts))
    )
      .pipe(
        map((competencyUnits) => {
          return sortBy(competencyUnits, ['name']);
        }),
      );
  }
}
