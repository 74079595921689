<div class="container">
  <pcp-list [items]="competencyUnits" [enableSearch]="true" searchPlaceholder="Search on Competency Units"
    (searchChanged)="onSearchChanged($event)">
    <ng-template let-item>
      <pcp-list-item [showArrow]="true" (click)="onClick(item)">
        <ng-container itemTitle>{{ item.name }}</ng-container>
        <ng-container itemDescription>{{ item.competencyElements.length }} Elements</ng-container>
      </pcp-list-item>
    </ng-template>
  </pcp-list>
</div>
