import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';

const MODULE_COMPONENTS = [
  MaintenanceComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  MaintenanceRoutingModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class MaintenanceModule { }
