import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pcp-selected-filter',
  templateUrl: './selected-filter.component.html',
  styleUrls: ['./selected-filter.component.scss']
})
export class SelectedFilterComponent {

  @Input()
  public labels: string[] = [];

  @Input()
  public filter: { key, value };

  @Output()
  public removeClicked = new EventEmitter<{ key: any; value: any; }>();

  public remove(): void {
    this.removeClicked.emit(this.filter);
  }

  public getLabel(key): any {
    const lbl = this.labels.find((l) => Object.keys(l)[0] === key);
    if (lbl) {
      return Object.values(lbl)[0];
    }
    return key;
  }
}
