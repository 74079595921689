import { stagger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TargetService } from 'src/app/shared/services/target.service';
import { TargetV2Query } from 'src/app/store/targetV2/targetV2.query';

@Component({
  selector: 'compliance-targets-average-progress-bar',
  templateUrl: './compliance-targets-average-progress-bar.component.html',
  styleUrls: ['./compliance-targets-average-progress-bar.component.scss']
})
export class ComplianceTargetsAverageProgressBarComponent implements OnInit {
  public completedPercentValue = 0;

  constructor(
    private targetsService: TargetService,
    private targetV2Query: TargetV2Query
  ) {
    //
  }

  public ngOnInit(): void {

    this.targetV2Query.select().subscribe((state) => {
      if (state && state.targetV2) {
        state.targetV2?.disciplines.forEach((disciplne) => {
          this.completedPercentValue += parseInt(disciplne.completedPercent, 10);
        });

        if(this.completedPercentValue > 0){
          this.completedPercentValue = this.completedPercentValue / state.targetV2.disciplines.length;
        }
      }
    });
  }

}
