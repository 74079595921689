import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { NoDataFoundRoutingModule } from './no-data-found-routing.module';
import { NoDataFoundComponent } from './pages/no-data-found/no-data-found.component';

const MODULE_COMPONENTS = [
  NoDataFoundComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  NoDataFoundRoutingModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class NoDataFoundModule { }
