import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pcp-offline-notification',
  templateUrl: './offline-notification.component.html',
  styleUrls: ['./offline-notification.component.scss'],
  animations: [
    trigger('showHide', [
      state('show', style({ bottom: '81px', opacity: 1 })),
      state('hide', style({ bottom: '-57px', opacity: 0 })),
      transition('show <=> hide', animate('300ms'))
    ])
  ]
})
export class OfflineNotificationComponent implements OnInit {
  public isOnline = true;

  public ngOnInit(): void {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
    .pipe(map(() => navigator.onLine))
    .subscribe((isOnline) => this.isOnline = isOnline)
  }
}
