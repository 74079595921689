import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Promotion } from '~/shared/models/promotion.model';

export interface IPromotionState extends EntityState<Promotion>  {
  promotion: Promotion
}

export function createInitialState(): IPromotionState {
  return {
    promotion: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'promotion' })
export class PromotionStore extends EntityStore<IPromotionState> {
  constructor() {
    super(createInitialState());
  }
}
