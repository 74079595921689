/*Change var $lg-responsive in src\styles\settings\_settings.scss too*/
export const SIZE_MIN_LG_SCREEN = 1200;
export const SIZE_MIN_MD_SCREEN = 768;

export const MIN_WIDTH_DIALOG_MOBILE = '60vw';
export const MIN_WIDTH_DIALOG_LG = '90vw';

export function isMobileSize() {
  return window.innerWidth <= SIZE_MIN_LG_SCREEN;
}

export function isLocalhost(): boolean {
  return location.href.indexOf('localhost') >= 0;
}
