import { isMobileSize } from 'src/app/shared/utils/navigator';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ProficiencyLevel } from 'src/app/shared//models/proficiency-level.model';
import { Router } from '@angular/router';
import { CompetencyCompliance } from 'src/app/shared//models/copetency-compliance.model';

import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { PageComponentBase } from 'src/app/shared/components/page-component-base';
import { TargetV2Query } from 'src/app/store/targetV2/targetV2.query';
import { TargetV2 } from 'src/app/shared/models/targetV2.model';
import { getDefaultClassProgress } from 'src/app/shared/utils/class-progress';
import { Discipline } from 'src/app/shared/models/disciplines.model';



@Component({
  selector: 'pcp-your-targets',
  templateUrl: './your-targets.component.html',
  styleUrls: ['./your-targets.component.scss']
})
export class YourTargetsComponent extends PageComponentBase implements OnInit {

  @Input() public isHome = false;
  @Input() public userGinNumber: string;
  public panelOpenState: boolean;
  public groupParam: string;
  public targets: TargetV2;
  public showDetail: CompetencyCompliance;
  public onDetail: { id: number, competencyElement: string, competencyUnit: string, pl: number, targetPL: number };
  public onDetailPL: ProficiencyLevel;
  public filtersDefault: { key, value }[] = [];
  public competencyCompliances: Array<CompetencyCompliance> = [];
  public filteredCompetencyCompliances: Array<CompetencyCompliance> = [];
  public index = 0;
  public itemsPage = 20;
  public isLoading = false;
  public useMobileSize = false;
  public elementSelected: Discipline

  constructor(
    pullToRefreshService: PullToRefreshService,
    private router: Router,
    private targetV2Query: TargetV2Query,
    private elementDetailService: ElementDetailService
  ) {
    super(pullToRefreshService);
  }

  @HostListener('window:resize', ['$event'])
  private onResize() {
    // Size taken from _settings.css in 10/19/22
    if (window.innerWidth < 767) {
      this.useMobileSize = true;
    } else {
      this.useMobileSize = false;
    }
  }

  public ngOnInit(): void {
    this.onResize();
    this.loadData();
  }

  private loadData() {
    this.isLoading = true;

    this.targetV2Query.select().subscribe((state) => {
      if (state && state.targetV2) {
        this.targets = state.targetV2;
      }
      this.isLoading = false;
    });
  }

  get isMobileSize() {
    return isMobileSize();
  }

  private detailObject(element: CompetencyCompliance): any {
    return {
      id: element.ceId,
      competencyElement: element.competencyElement,
      competencyUnit: element.competencyUnit,
      pl: element.currentPL,
      targetPL: element.reqPL
    };
  }

  public navigateToHome() {
    this.isHome = true;
    this.router.navigate(['home']);
  }

  public showElementsDetail(element: CompetencyCompliance) {
    if (this.showDetail?.competencyElement === element.competencyElement) {
      this.showDetail = null;
      this.elementDetailService.clear();
      return;
    }

    this.showDetail = element;
    this.onDetail = this.detailObject(element);

    if (!this.isMobileSize) {
      this.elementDetailService.clearDataTypePCE();
      this.elementDetailService.setValue(this.onDetail);
    }
  }

  public completedPercent(percent: string): number{
    return parseInt(percent, 10);
  }

  public getClassProgressByValue(element: any): string {
    const percentage = this.completedPercent(element.completedPercent)
    return getDefaultClassProgress(percentage);
  }

  public selectElement(element:Discipline) {
    this.elementSelected = element
  }
}
