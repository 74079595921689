<div class="header-container">
    <section class="tab-container">
        <div class="tab" (click)="onTabClicked(1)">
            <div class="tab-content">
                <img class="icon" src="/assets/icons/todo-list.svg" alt="Level information icon" />
                <small [ngClass]="{'name': true, 'disabled': (availableElements.length === 0)}" [class.selected]="selectedTabIndex === 1">Available</small>
            </div>
        </div>

        <div class="tab" (click)="onTabClicked(2)">
            <div class="tab-content">
                <img class="icon" src="/assets/icons/clock.svg" alt="Expiration details icon" />
                <small class="name" [class.selected]="selectedTabIndex === 2">Complete</small>
            </div>
        </div>

        <div class="tab-highlight" [ngClass]="
        {
          'selected-1': selectedTabIndex === 1,
          'selected-2': selectedTabIndex === 2
        }"></div>
    </section>
</div>

<div class="container tab-content-container">
    <section *ngIf="selectedTabIndex === 1">
        <div *ngFor="let available of availableElements; last as lst;">
            <pcp-promotion-requirement-ce [competencyElement]="available" [last]="lst"
                (competencyElementClick)="viewCE($event)"></pcp-promotion-requirement-ce>
        </div>
    </section>

    <section *ngIf="selectedTabIndex === 2">
        <div *ngFor="let complete of completeElements; last as lst;">
            <pcp-promotion-requirement-ce [competencyElement]="complete" [last]="lst"
                (competencyElementClick)="viewCE($event)"></pcp-promotion-requirement-ce>
        </div>
    </section>
</div>
