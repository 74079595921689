// CR1497--added input
import { Component, Input, OnInit } from '@angular/core';
import { Configuration } from '~/shared/models/configuration.model';

@Component({
  selector: 'pcp-element-training',
  templateUrl: './element-training.component.html',
  styleUrls: ['./element-training.component.scss']
})
export class ElementTrainingComponent implements OnInit {
  @Input() public competencyDetail: any;
  @Input() public pathwayType: string;
  @Input() public pathwayCompleted: boolean;
  public isLoading = true;
  public ilearnUrlPrefix: string;
  public certs: Array<any>;
  public sources: Array<any>;
  public noTrainingData = false;
  public needMoreExp = false;
  public assessors: Array<any>;
  public needAcessors = false;

  public constructor(
    private config: Configuration
  ) {
  }

  public ngOnInit(): void {
    this.ilearnUrlPrefix = this.config.ilearnUrlPrefix;
    this.isLoading = false;
    this.sources = this.competencyDetail?.sources;
    this.certs = this.competencyDetail?.certs;
    this.assessors = this.competencyDetail?.assessors;
    this.verifyPathwayType(this.pathwayType);
  }

  public openLinkCerts(element: any) {
    window.open(element.certLink,'_blank');
  }

  public verifyPathwayType(element: string) {
    if (element != null || element != undefined || element != '') {
      if (element === 'JE' || element === 'RJE' || element === 'ME' || element === 'RME' || element == 'AC') this.needMoreExp = true;
      else if (element === 'T') this.noTrainingData = true;
      else if (element === 'CPA' || element === 'CTI') this.needAcessors = true;
    }
  }
}
