import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';

@Component({
  selector: 'pcp-promotion-requirement',
  templateUrl: './promotion-requirement.component.html',
  styleUrls: ['./promotion-requirement.component.scss']
})
export class PromotionRequirementComponent {
  @Input()
  public last = false;

  @Input()
  public requirement: PromotionRequirement;

  @Output()
  public requirementClick = new EventEmitter<PromotionRequirement>();

  public isRequirementTypePoints() {
    return this.requirement?.requirementType == RequirementType.points.valueOf();
  }
  public isRequirementTypeComplete() {
    return this.requirement?.requirementType == RequirementType.complete.valueOf();
  }
  public isRequirementTypeSpecific() {
    return this.requirement?.requirementType == RequirementType.specific.valueOf();
  }

  public viewRequirement(): void {
    this.requirementClick.emit(this.requirement);
  }

  get completedCEs(): number {
    if (this.requirement) {
      return 1; //this.requirement.competencyElements.filter((ce) => ce.proficiencyLevel.id >= this.requirement.level.id).length;
    }
    return -1;
  }

  get requirementDescription(): string {
    if (this.requirement) {
      if (this.requirement.requirementType === RequirementType.specific) {
        return `${this.requirement.requiredCount} specific CEs at ${this.requirement.requiredLevel}`;
      }
      if (this.requirement.requirementType === RequirementType.points) {
        return `${this.requirement.requiredCount} points required`;
      }

      return `${this.requirement.requiredCount} CEs at ${this.requirement.requiredLevel}`;
    }
    return '';
  }

  get operator(): string {
    // if (this.requirement && !this.last) {
    //   if (this.requirement.requirementType === RequirementType.And) {
    //     return 'AND';
    //   } else if (this.requirement.requirementType === RequirementType.Or) {
    //     return 'OR';
    //   } else {
    //     return null;
    //   }
    // }
    return 'AND';
  }

  get isPoints(): boolean {
    return (this.requirement && this.requirement.requirementType === RequirementType.points);//(this.requirement && this.requirement.requirementType === RequirementType.Points);
  }

  get currentPercentage(): number {
    if (this.requirement) {
      let total = ((this.pointsAchieved * 100) / this.requirement.requiredCount);
      if (total > 100) {
        total = 100;
      }
      return Math.floor(total);
    }
    return 0;
  }

  get pointsAchieved() {
    return this.requirement.pointsAchieved ?? 0;
  }
}
