import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { OfflineRoutingModule } from './offline-routing.module';
import { OfflineComponent } from './pages/offline/offline.component';
import { OfflineNotificationComponent } from './components/offline-notification/offline-notification.component';

const MODULE_COMPONENTS = [
  OfflineComponent,
  OfflineNotificationComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  OfflineRoutingModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class OfflineModule { }
