import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '~/shared/shared.module';
import { CompetencyUnitsModule } from '../competency-units/competency-units.module';
import { ComplianceModule } from '../compliance/compliance.module';
import { PromotionModule } from '../promotion/promotion.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home/home.component';

const MODULE_COMPONENTS = [
  HomeComponent
];

const MODULE_MODULES = [
  CommonModule,
  SharedModule,
  HomeRoutingModule,
  PromotionModule,
  ComplianceModule,
  CompetencyUnitsModule
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_COMPONENTS
  ]
})
export class HomeModule { }
