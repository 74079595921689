<div class="warning-card" (click)="onClickButton()">
  <div class="div-wrapper">
    <img src="/assets/icons/warning-orange-big.svg" alt="Calendar" class="warning-icon" />
    <div class="container-text">
      <small>Have an issue with the data?</small>
      <h2 class="title-warning">Report a problem</h2>
    </div>
  </div>
  <img src="/assets/icons/right-arrow-thin.svg" alt="Bar Chart" class="arrow" />
</div>
