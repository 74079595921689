<section *ngIf="isHome" [class.promotion-section]="isPromotion" (click)="viewGroup()">
  <dl class="group-data">
    <div class="container-text">
        <div>{{ group.name }}</div>
        <div>{{ percentageValue }}%</div>
    </div>
    <div class="requirement-link">
      <mat-progress-bar [ngClass]="getDefaultClassProgressComponent()" class="progress-bar" mode="determinate"
        [value]="percentageValue">
      </mat-progress-bar>
    </div>
  </dl>
</section>
